import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { partition } from 'ramda'
import { SearchOptions } from 'instantsearch.js'
import { SearchClient } from 'algoliasearch'

import { Inject, Injectable } from '@angular/core'
import { FormControl } from '@angular/forms'

import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { SnackbarService } from '@app-domains/ui/services/snackbar/snackbar.service'
import { ShoppingCartLineInput } from '@app-graphql/schema'
import { cannotBeAddedToCart } from '../../helpers/cannot-be-added-to-cart/cannot-be-added-to-cart'
import { TranslationKey } from '@app-pipes/typed-translate/typed-translate.pipe'
import { ALGOLIA_SEARCH_CLIENT } from '@app-domains/algolia/algolia.module'
import { getIndexName } from '@app-lib/algolia.lib'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import {
    AssortmentService,
    CheckoutService,
    DownloadService,
    ProductService,
    WishlistService,
} from '@app-services'

type Hits = { hits: { ean: string }[] }

@Injectable()
export class ProductSelectionService {

    public isInSelectMode$ = new BehaviorSubject(false)

    public EANCodes = new Set<string>()

    public selectedAction = new FormControl('')

    public handleInProgress: boolean = false

    constructor(
        @Inject(ALGOLIA_SEARCH_CLIENT)
        private readonly searchClient: SearchClient,
        private readonly localization: LocalizationService,
        private readonly wishlistService: WishlistService,
        private readonly snackbarService: SnackbarService,
        private readonly assortmentService: AssortmentService,
        private readonly checkoutService: CheckoutService,
        private readonly downloadService: DownloadService,
        private readonly productService: ProductService,
    ) {
    }

    public addSelectedProduct(ean: string): void {
        this.EANCodes.add(ean)
    }

    public removeSelectedProduct(ean: string): void {
        this.EANCodes.delete(ean)
    }

    public selectAllProducts(products: Product[]): void {
        this.deselectAllProducts()

        products.forEach((product) => this.EANCodes.add(product.ean))
    }

    public async selectAllMatchingAlgoliaQuery(
        search: SearchOptions,
        maxSelectedProductsCount?: number,
    ): Promise<void> {
        const { results } = await this.searchClient.search([
            {
                indexName: getIndexName({
                    locale: this.localization.getCurrentLocale(),
                    sorting: null,
                }),
                params: {
                    ...search,
                    attributesToRetrieve: ['ean'],
                    hitsPerPage: maxSelectedProductsCount ?? 1000,
                },
            },
        ])

        const hits = (results[0] as unknown as Hits).hits

        hits.forEach(({ ean }) => this.addSelectedProduct(ean))
    }

    public deselectAllProducts(): void {
        this.EANCodes.clear()
    }

    public closeFooter(): void {
        this.isInSelectMode$.next(false)
        this.deselectAllProducts()
    }

    public async triggerDownload(): Promise<void> {
        await this.downloadService.openProductDownloadConfigModal(this.getSelectedEans())
    }

    public async handleSelectionAction(): Promise<void> {
        this.handleInProgress = true

        if (! this.selectedAction.value || ! this.EANCodes.size) {
            return
        }

        switch (this.selectedAction.value) {
            case 'add-cart': {
                await this.addSelectionToShoppingCart()
                break
            }
            case 'add-wishlist': {
                await this.addSelectionToWishlist()
                break
            }
            case 'remove-wishlist': {
                await this.removeSelectionFromWishlist()
                break
            }
            case 'add-assortment': {
                await this.addSelectionToAssortment()
                break
            }
            case 'select-download': {
                await this.triggerDownload()
                break
            }
        }

        this.handleInProgress = false
    }

    private async addSelectionToShoppingCart(): Promise<void> {
        this.createSnackbar('common.may-take-some-time')

        const products = await firstValueFrom(this.productService.getProducts(
            { eans: [...this.EANCodes] },
        ))

        const partitionedProducts = partition(
            (x) => cannotBeAddedToCart(x.suggestedStandardRetailPrice, x.status), products.data.productsByEan,
        )

        const outOfStockProducts: Product[] = []

        partitionedProducts[0]
            .forEach((product) => outOfStockProducts.push(
                this.productService.transformProductFragmentToProduct(product, this.localization.getCurrentLocale())),
            )

        this.checkoutService.outOfStockProductsPopup$.next(outOfStockProducts)

        const eans = partitionedProducts[1].map((item) => item.ean)

        const lines: ShoppingCartLineInput[] = eans.map((x) => ({
            ean: x,
            quantity: 1,
        }))

        await this.checkoutService.addToShoppingCart(lines)

        this.closeFooter()
    }

    private async addSelectionToAssortment(): Promise<void> {
        const eans = this.getSelectedEans()
        const result = await firstValueFrom(this.assortmentService.addToAssortment(eans))

        if (result.errors) {
            this.createSnackbar('common.error')
            return
        }

        this.createSnackbar('products.added-to-assortment')
        this.closeFooter()
        return
    }

    private async addSelectionToWishlist(): Promise<void> {
        this.createSnackbar('common.may-take-some-time')

        const eans = this.getSelectedEans()

        const result = await firstValueFrom(this.wishlistService.addToWishlist(eans))

        if (result.errors) {
            this.createSnackbar('common.error')
            return
        }

        this.createSnackbar('products.added-to-wishlist')
        this.closeFooter()
        await this.wishlistService.refreshWishlist()
        // TODO: Add a filling/shaking animation to the wishlist icon (BONUS)
        return
    }

    private async removeSelectionFromWishlist(): Promise<void> {
        const eans = this.getSelectedEans()
        const result = await firstValueFrom(this.wishlistService.removeFromWishlist(eans))

        if (result.errors) {
            this.createSnackbar('common.error')
            return
        }

        this.createSnackbar('wishlist.removed-from-wishlist')

        this.closeFooter()

        await this.wishlistService.refreshWishlist()

        return
    }

    private getSelectedEans(): string[] {
        return [...this.EANCodes]
    }

    private createSnackbar(translation: TranslationKey): void {
        this.snackbarService.create({
            content: translation,
            autoHideDelay: 3000,
        }, 'bottom')
    }

}
