<app-grid>
    <app-cell mobile="0:12"
              tablet="0:8"
              laptop="0:4"
              desktop="0:4"
    >
        <div class="forgot-password">
            <app-button theme="link"
                        icon="chevron-left"
                        layout="icon-label"
                        size="normal"
                        (click)="goToLogin()"
            >
                {{ 'auth.back-to-login' | translate }}
            </app-button>

            <div class="text">
                <h1>
                    {{ 'auth.forgot-password' | translate }}
                </h1>
                <label class="dark-gray">
                    {{ 'auth.enter-mail' | translate }}
                </label>
            </div>

            <app-input placeholder="{{ 'order-dropshipment.enter-email' | translate }}"
                       [control]="email"
                       [errorMessages]="emailErrorMessages"
            >
                {{ 'register.email' | translate }}
            </app-input>

            <div class="button">
                <app-button size="normal"
                            theme="dark"
                            layout="label-only"
                            [disabled]="email.invalid"
                            (click)="submit()"
                >
                    {{ 'common.submit' | translate }}
                </app-button>
            </div>
        </div>
    </app-cell>
</app-grid>
