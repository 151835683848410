// noinspection JSUnusedGlobalSymbols

import { gql } from 'apollo-angular'
import { Injectable } from '@angular/core'
import * as Apollo from 'apollo-angular'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
    ID: { input: string; output: string }
    String: { input: string; output: string }
    Boolean: { input: boolean; output: boolean }
    Int: { input: number; output: number }
    Float: { input: number; output: number }
    /** A bigint. */
    BigInt: { input: number; output: number }
    /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
    Date: { input: string; output: string }
    /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
    DateTime: { input: string; output: string }
    /** A date string with format `HH:MM`, e.g. `01:23`. */
    ShortTimeNotation: { input: string; output: string }
    /** A date string with format `U` e.g. 1660338149 */
    TimeStamp: { input: string; output: string }
    /** A datetime string with format `W-Y`, e.g. `2-2022`. */
    WeekYear: { input: string; output: string }
}

export interface Accordion {
    accordionItems: Array<AccordionItem>
    button?: Maybe<Button>
    icon?: Maybe<IconEnum>
    title: Scalars['String']['output']
}

export interface AccordionItem {
    description: Scalars['String']['output']
    title: Scalars['String']['output']
}

export interface AccountManager {
    show: Scalars['Boolean']['output']
}

export interface Address {
    city: Scalars['String']['output']
    country: Scalars['String']['output']
    name: Scalars['String']['output']
    number: Scalars['String']['output']
    phone?: Maybe<Scalars['String']['output']>
    street: Scalars['String']['output']
    zipcode: Scalars['String']['output']
}

export interface AddressInput {
    city: Scalars['String']['input']
    country: Scalars['String']['input']
    name: Scalars['String']['input']
    number: Scalars['String']['input']
    phone?: InputMaybe<Scalars['String']['input']>
    street: Scalars['String']['input']
    zipcode: Scalars['String']['input']
}

export interface AllowedBrands {
    code: Scalars['String']['output']
    description: Scalars['String']['output']
}

export interface AllowedExclusives {
    code: Scalars['String']['output']
    description: Scalars['String']['output']
}

export interface AllowedModules {
    datafeed: Scalars['Boolean']['output']
    dontShowNewProducts: Scalars['Boolean']['output']
    dropShipment: Scalars['Boolean']['output']
    showNewFromDate?: Maybe<Scalars['TimeStamp']['output']>
}

export interface AllowedProductGroups {
    code: Scalars['String']['output']
    description: Scalars['String']['output']
}

export interface ApproveDebtorInput {
    debtorId?: InputMaybe<Scalars['String']['input']>
    email: Scalars['String']['input']
    name: Scalars['String']['input']
    password?: InputMaybe<Scalars['String']['input']>
    phone?: InputMaybe<Scalars['String']['input']>
    preferredLanguage: Scalars['String']['input']
    /** account|new_debtor|already_debtor */
    type: Scalars['String']['input']
}

export interface Asset {
    image?: Maybe<Image>
    media?: Maybe<Media>
    title: Scalars['String']['output']
}

export interface Assets {
    assets: Array<Asset>
}

export interface AssignDebtorIdToUser {
    debtorId?: InputMaybe<Scalars['String']['input']>
    email: Scalars['String']['input']
}

export interface Assortment {
    createdAt: Scalars['DateTime']['output']
    ean: Scalars['String']['output']
    id: Scalars['String']['output']
    inStock: Scalars['Boolean']['output']
    product?: Maybe<Product>
}

export interface AssortmentFilterByInput {
    outOfStock?: InputMaybe<Scalars['Boolean']['input']>
}

export interface AssortmentOrderByInput {
    productName?: InputMaybe<Sort>
}

/** A paginated list of Assortment items. */
export interface AssortmentPaginator {
    /** A list of Assortment items. */
    data: Array<Assortment>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo
}

export interface AuthPayload {
    accessToken?: Maybe<Scalars['String']['output']>
    expiresIn?: Maybe<Scalars['Int']['output']>
    refreshToken?: Maybe<Scalars['String']['output']>
    tokenType?: Maybe<Scalars['String']['output']>
    user?: Maybe<User>
}

export interface BackofficeUser {
    countryCode?: Maybe<Scalars['String']['output']>
    createdAt?: Maybe<Scalars['DateTime']['output']>
    debtorCode?: Maybe<Scalars['String']['output']>
    email: Scalars['String']['output']
    id: Scalars['String']['output']
    isActive: Scalars['Boolean']['output']
    lastLoggedIn?: Maybe<Scalars['DateTime']['output']>
    name: Scalars['String']['output']
    preferredLanguage?: Maybe<Scalars['String']['output']>
    updatedAt?: Maybe<Scalars['DateTime']['output']>
}

/** A paginated list of BackofficeUser items. */
export interface BackofficeUserPaginator {
    /** A list of BackofficeUser items. */
    data: Array<BackofficeUser>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo
}

export interface Brand {
    brandCode: Scalars['String']['output']
    highlightedImage: Scalars['String']['output']
    highlightedProducts: Array<Product>
    id: Scalars['Int']['output']
    isVisibleInMenu: Scalars['Boolean']['output']
    logo: Scalars['String']['output']
    name: Scalars['String']['output']
    order: Scalars['Int']['output']
    slug: Scalars['String']['output']
    translations: BrandTranslationsMap
}

export interface BrandTranslation {
    components: Array<Component>
    description: Scalars['String']['output']
    seo?: Maybe<Seo>
}

export interface BrandTranslationsMap {
    de: BrandTranslation
    en: BrandTranslation
    fr: BrandTranslation
    nl: BrandTranslation
}

export interface BusinessHours {
    closing: Scalars['ShortTimeNotation']['input']
    opening: Scalars['ShortTimeNotation']['input']
}

export interface Button {
    icon?: Maybe<IconEnum>
    link: Link
    type: ButtonThemeEnum
}

/** All available button types. */
export enum ButtonThemeEnum {
    Dark = 'DARK',
    Grey = 'GREY',
    Line = 'LINE',
    Link = 'LINK',
}

export interface Carousel {
    showHeader: Scalars['Boolean']['output']
    showScrollBar: Scalars['Boolean']['output']
    slides: Array<CarouselSlide>
    subtitle?: Maybe<Scalars['String']['output']>
    theme: CarouselThemeEnum
    title: Scalars['String']['output']
}

export interface CarouselSlide {
    backgroundImage?: Maybe<ImageComponent>
    link?: Maybe<Link>
    mainImage?: Maybe<ImageComponent>
    subtitle: Scalars['String']['output']
    text?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

/** Give the theme for a brand slider. */
export enum CarouselThemeEnum {
    Dark = 'DARK',
    Light = 'LIGHT',
}

export interface Categories {
    categories: Array<Category>
    new: ImageComponent
    sale: ImageComponent
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface Category {
    children: Array<Maybe<Category>>
    highlightedProducts: Array<Maybe<Product>>
    id: Scalars['String']['output']
    isVisibleInMenu: Scalars['Boolean']['output']
    katanaId?: Maybe<Scalars['Int']['output']>
    parentId?: Maybe<Scalars['String']['output']>
    productImage?: Maybe<Image>
    published: Scalars['Boolean']['output']
    translations: CategoryTranslationsMap
}

export interface CategorySitemapEntry {
    slugs: SitemapSlugs
    updatedAt: Scalars['DateTime']['output']
}

export interface CategoryTranslation {
    components: Array<Component>
    description?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    seo?: Maybe<Seo>
    slug: Scalars['String']['output']
}

export interface CategoryTranslationsMap {
    de: CategoryTranslation
    en: CategoryTranslation
    fr: CategoryTranslation
    nl: CategoryTranslation
}

export interface ChildInput {
    alias: Scalars['String']['input']
}

export interface Color {
    presentation: Scalars['String']['output']
    translations: ColorTranslationMap
}

export interface ColorTranslationMap {
    de: Scalars['String']['output']
    en: Scalars['String']['output']
    fr: Scalars['String']['output']
    nl: Scalars['String']['output']
}

export type Component = Accordion | AccountManager | Assets | Carousel | Categories | CtaLink | Form | ImageContentLink | ImageHeader | ImageMosaic | ImageText | ImageWithText | ImageWithTextHeader | Map | NewsLetter | PageLinks | Paragraphs | ParagraphsCarousel | ProjectShowCase | Quotes | Statistics | SubFooterLink | TextHeader | UniqueSellingPoints | VideoHeader | VideoText | VideoWithText

export interface ContactForm {
    show: Scalars['Boolean']['output']
}

export interface ContactFormInput {
    companyName: Scalars['String']['input']
    email: Scalars['String']['input']
    isNotARobot: Scalars['String']['input']
    locale: Scalars['String']['input']
    message: Scalars['String']['input']
    name: Scalars['String']['input']
    phoneNumber: Scalars['String']['input']
}

export interface ContactPerson {
    commercial: Scalars['Boolean']['input']
    delivery: Scalars['Boolean']['input']
    email: Scalars['String']['input']
    financial: Scalars['Boolean']['input']
    name: Scalars['String']['input']
    order: Scalars['Boolean']['input']
    phone: Scalars['String']['input']
}

export interface CtaLink {
    button?: Maybe<Button>
    image: ImageComponent
    subtitleCta: Scalars['String']['output']
    textCta: Scalars['String']['output']
    title: Scalars['String']['output']
}

export interface CustomOption {
    alias: Scalars['String']['output']
    key: Scalars['String']['output']
    selected: Scalars['Boolean']['output']
}

export interface CustomOptionInput {
    alias: Scalars['String']['input']
    child?: InputMaybe<ChildInput>
    key: Scalars['String']['input']
    selected: Scalars['Boolean']['input']
}

export interface DataRequestInput {
    addition?: InputMaybe<Scalars['String']['input']>
    city: Scalars['String']['input']
    country: Scalars['String']['input']
    email: Scalars['String']['input']
    firstName: Scalars['String']['input']
    houseNumber: Scalars['String']['input']
    isNotARobot: Scalars['String']['input']
    lastName: Scalars['String']['input']
    locale: Scalars['String']['input']
    phoneNumber: Scalars['String']['input']
    salutation: Scalars['String']['input']
    street: Scalars['String']['input']
    zipcode: Scalars['String']['input']
}

export interface Debtor {
    accountManager: DebtorAccountManager
    debtorAddresses: Array<Address>
    debtorCode: Scalars['String']['output']
    debtorRights: DebtorRights
    name: Scalars['String']['output']
    paymentCondition: PaymentCondition
    website: Scalars['String']['output']
}

export interface DebtorAccountManager {
    email: Scalars['String']['output']
    jobTitle: Scalars['String']['output']
    name: Scalars['String']['output']
    phone: Scalars['String']['output']
    photoUrl: Scalars['String']['output']
}

export interface DebtorRights {
    allowedBrands: Array<AllowedBrands>
    allowedCountry: Scalars['String']['output']
    allowedExclusives: Array<AllowedExclusives>
    allowedModules: AllowedModules
    allowedProductGroups: Array<AllowedProductGroups>
}

export interface DefaultResponse {
    message: Scalars['String']['output']
    reason?: Maybe<Scalars['String']['output']>
    status: ResponseStatuses
}

export interface DeliveryInfo {
    deliverableInWeeks?: Maybe<Scalars['Int']['output']>
    weekNumber: Scalars['Int']['output']
    weekYear: Scalars['String']['output']
}

export interface DeliveryTimeInfoTranslationMap {
    de: Scalars['String']['output']
    en: Scalars['String']['output']
    fr: Scalars['String']['output']
    nl: Scalars['String']['output']
}

export interface Dimension {
    dimension: Scalars['Float']['output']
    label: Scalars['String']['output']
}

/** Fetch download file type. */
export enum DownloadEnum {
    CareGuide = 'CARE_GUIDE',
    Manual = 'MANUAL',
    Unknown = 'UNKNOWN',
}

export interface DownloadInput {
    fields: Scalars['String']['input']
    identifiers: Array<Scalars['String']['input']>
    language: Scalars['String']['input']
    options: Scalars['String']['input']
    type: Array<Scalars['String']['input']>
}

export interface DownloadProcess {
    percentage: Scalars['Int']['output']
    url?: Maybe<Scalars['String']['output']>
}

export interface DownloadProgress {
    percentage: Scalars['Int']['output']
    url?: Maybe<Scalars['String']['output']>
}

export interface DownloadProgressInput {
    debtorId: Scalars['String']['input']
    downloadId: Scalars['String']['input']
}

export interface EnhanceDebtorInput {
    contactPersons: Array<ContactPerson>
    deliveryAddress?: InputMaybe<AddressInput>
    deliveryTimeWindow: TimeWindow
    extra: Extra
    kvk?: InputMaybe<Scalars['String']['input']>
    postAddress?: InputMaybe<AddressInput>
    purchasingAssociation?: InputMaybe<PurchasingAssociation>
    showOnBePureHome: Scalars['Boolean']['input']
    showOnWooodnl: Scalars['Boolean']['input']
    vat: Scalars['String']['input']
    visitAddress: AddressInput
    visitTimeWindow: TimeWindow
}

export interface ErrorType {
    error: Scalars['String']['output']
}

export interface Event {
    category?: Maybe<EventsCategoryEnum>
    components: Array<Component>
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['Int']['output']
    image?: Maybe<Image>
    locale: Scalars['String']['output']
    seo: Seo
    slug: Scalars['String']['output']
    title: Scalars['String']['output']
    translations: Array<Localization>
}

/** A paginated list of Event items. */
export interface EventPaginator {
    /** A list of Event items. */
    data: Array<Event>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo
}

export interface EventSitemapEntry {
    slugs: SitemapSlugs
    updatedAt: Scalars['DateTime']['output']
}

/** Give the category for a event item. */
export enum EventsCategoryEnum {
    All = 'ALL',
    Events = 'EVENTS',
    News = 'NEWS',
    Products = 'PRODUCTS',
    Projects = 'PROJECTS',
}

export interface Extra {
    extraRemarks?: InputMaybe<Scalars['String']['input']>
    forkLiftAvailable?: InputMaybe<Scalars['Boolean']['input']>
    standardTrailer?: InputMaybe<Scalars['Boolean']['input']>
}

export interface Feed {
    fields: Array<Field>
    options: Array<Maybe<CustomOption>>
    translated: FieldTranslationMap
}

export interface FeedOptions {
    fields: Array<InputMaybe<FieldInput>>
    options: Array<InputMaybe<CustomOptionInput>>
}

export interface Feeds {
    products: Feed
    stocks: Feed
}

export interface Field {
    child?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
}

export interface FieldInput {
    child?: InputMaybe<Scalars['String']['input']>
    name: Scalars['String']['input']
}

export interface FieldTranslation {
    key: Scalars['String']['output']
    label: Scalars['String']['output']
}

export interface FieldTranslationMap {
    de: Array<FieldTranslation>
    en: Array<FieldTranslation>
    fr: Array<FieldTranslation>
    nl: Array<FieldTranslation>
}

export interface File {
    alt?: Maybe<Scalars['String']['output']>
    caption?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    previewUrl: Scalars['String']['output']
    provider: Scalars['String']['output']
    type?: Maybe<Scalars['String']['output']>
    url: Scalars['String']['output']
}

export interface ForgotPasswordInput {
    email: Scalars['String']['input']
}

export interface ForgotPasswordResponse {
    message: Scalars['String']['output']
    status: Scalars['String']['output']
}

export interface Form {
    type: FormTypeEnum
}

/** Available form types. */
export enum FormTypeEnum {
    Contact = 'CONTACT',
    DataRequest = 'DATA_REQUEST',
}

/** Layout of a image header. */
export enum HeaderLayoutEnum {
    Compact = 'COMPACT',
    FullSize = 'FULL_SIZE',
}

/** All available svg icons. */
export enum IconEnum {
    AddToCart = 'ADD_TO_CART',
    Apple = 'APPLE',
    Ar = 'AR',
    ArrowFatLeft = 'ARROW_FAT_LEFT',
    ArrowFatRight = 'ARROW_FAT_RIGHT',
    ArrowLeft = 'ARROW_LEFT',
    ArrowRight = 'ARROW_RIGHT',
    Calendar = 'CALENDAR',
    Cart = 'CART',
    Chat = 'CHAT',
    Checkmark = 'CHECKMARK',
    CheckCircle = 'CHECK_CIRCLE',
    ChevronDown = 'CHEVRON_DOWN',
    ChevronLeft = 'CHEVRON_LEFT',
    ChevronRight = 'CHEVRON_RIGHT',
    ChevronUp = 'CHEVRON_UP',
    Close = 'CLOSE',
    CloseFat = 'CLOSE_FAT',
    Color = 'COLOR',
    Copy = 'COPY',
    CurrentLocation = 'CURRENT_LOCATION',
    Dealer = 'DEALER',
    DealerShop = 'DEALER_SHOP',
    Delete = 'DELETE',
    Download = 'DOWNLOAD',
    DownloadQuestion = 'DOWNLOAD_QUESTION',
    Dropshipment = 'DROPSHIPMENT',
    ErrorCircle = 'ERROR_CIRCLE',
    Events = 'EVENTS',
    ExperienceCenter = 'EXPERIENCE_CENTER',
    Facebook = 'FACEBOOK',
    Filter = 'FILTER',
    GooglePlay = 'GOOGLE_PLAY',
    Hamburger = 'HAMBURGER',
    Heart = 'HEART',
    HeartFilled = 'HEART_FILLED',
    Info = 'INFO',
    Instagram = 'INSTAGRAM',
    Linkedin = 'LINKEDIN',
    Location = 'LOCATION',
    Logo = 'LOGO',
    Mail = 'MAIL',
    News = 'NEWS',
    Pause = 'PAUSE',
    Phone = 'PHONE',
    Play = 'PLAY',
    Plus = 'PLUS',
    Products = 'PRODUCTS',
    Projects = 'PROJECTS',
    Registration = 'REGISTRATION',
    Reset = 'RESET',
    Rotate = 'ROTATE',
    Scissors = 'SCISSORS',
    Search = 'SEARCH',
    SearchFat = 'SEARCH_FAT',
    Selection = 'SELECTION',
    Settings = 'SETTINGS',
    Show = 'SHOW',
    Sliders = 'SLIDERS',
    Sofa = 'SOFA',
    ThinChevronDown = 'THIN_CHEVRON_DOWN',
    ThinChevronLeft = 'THIN_CHEVRON_LEFT',
    ThinChevronRight = 'THIN_CHEVRON_RIGHT',
    ThinChevronUp = 'THIN_CHEVRON_UP',
    Truck = 'TRUCK',
    User = 'USER',
    WarningCircle = 'WARNING_CIRCLE',
    Webshop = 'WEBSHOP',
}

export interface IconParagraphs {
    icon?: Maybe<IconEnum>
    text?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface Image {
    alt?: Maybe<Scalars['String']['output']>
    caption?: Maybe<Scalars['String']['output']>
    height: Scalars['Int']['output']
    name: Scalars['String']['output']
    previewUrl: Scalars['String']['output']
    provider: Scalars['String']['output']
    url: Scalars['String']['output']
    width: Scalars['Int']['output']
}

export interface ImageComponent {
    desktop: PositionedImage
    laptop: PositionedImage
    mobile: PositionedImage
    tablet: PositionedImage
}

export interface ImageContentLink {
    images?: Maybe<Array<ImageComponent>>
    mainImage?: Maybe<ImageComponent>
    pageLinks: Array<Link>
    showGrayBack: Scalars['Boolean']['output']
    subtitle?: Maybe<Scalars['String']['output']>
    text?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface ImageHeader {
    headerLayout: HeaderLayoutEnum
    images: Array<ImageComponent>
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface ImageMosaic {
    landscape1: ImageComponent
    landscape2: ImageComponent
    portrait: ImageComponent
}

/** Layout positions for a Image. */
export enum ImagePositionEnum {
    Bottom = 'BOTTOM',
    Center = 'CENTER',
    Left = 'LEFT',
    Right = 'RIGHT',
    Top = 'TOP',
}

export interface ImageText {
    image: ImageComponent
    title: Scalars['String']['output']
}

export interface ImageWithText {
    buttons: Array<Button>
    content: Scalars['String']['output']
    image: ImageComponent
    imageLayout: ImagePositionEnum
    showGrayBack: Scalars['Boolean']['output']
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface ImageWithTextHeader {
    description?: Maybe<Scalars['String']['output']>
    mainImage?: Maybe<ImageComponent>
    smallImage?: Maybe<Image>
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface ImpersonationInput {
    userId: Scalars['String']['input']
}

export interface Link {
    label: Scalars['String']['output']
    link: Scalars['String']['output']
    target: LinkTargetEnum
}

/** Get the types target of a link. */
export enum LinkTargetEnum {
    Blank = 'BLANK',
    Parent = 'PARENT',
    Self = 'SELF',
    Top = 'TOP',
}

/** Available locales. */
export enum LocalesEnum {
    /** de */
    De = 'de',
    /** en */
    En = 'en',
    /** fr */
    Fr = 'fr',
    /** nl */
    Nl = 'nl',
}

export interface Localization {
    locale: Scalars['String']['output']
    slug: Scalars['String']['output']
    title: Scalars['String']['output']
}

export interface LoginInput {
    clientId: Scalars['String']['input']
    clientSecret: Scalars['String']['input']
    password: Scalars['String']['input']
    username: Scalars['String']['input']
}

export interface LogoutResponse {
    message: Scalars['String']['output']
    status: Scalars['String']['output']
}

export interface Map {
    markers: Array<Marker>
}

export interface Marker {
    address?: Maybe<Address>
    lat?: Maybe<Scalars['Float']['output']>
    lng?: Maybe<Scalars['Float']['output']>
    text?: Maybe<Scalars['String']['output']>
    website?: Maybe<Scalars['String']['output']>
}

export interface MaterialTranslationMap {
    de: Scalars['String']['output']
    en: Scalars['String']['output']
    fr: Scalars['String']['output']
    nl: Scalars['String']['output']
}

export type Media = File | Image | Video

export interface Mutation {
    CreateDropshipmentOrder: DefaultResponse
    addNewLoginAccount: DefaultResponse
    addToAssortment: DefaultResponse
    addToShoppingCart: ShoppingCart
    addToWishlist: DefaultResponse
    approveDebtor: DefaultResponse
    assignDebtorIdToUser: DefaultResponse
    createOrder: DefaultResponse
    deleteAccount: DefaultResponse
    deleteFromShoppingCart: ShoppingCart
    deleteShoppingCart: DefaultResponse
    downloadRequested: Scalars['String']['output']
    editLoginAccount: DefaultResponse
    enhanceDebtor: DefaultResponse
    forgotPassword: ForgotPasswordResponse
    impersonation: AuthPayload
    login: AuthPayload
    logout: LogoutResponse
    refreshToken: RefreshTokenPayload
    registerNewDebtor: DefaultResponse
    registerNewsLetterSubscription: DefaultResponse
    rejectDebtor: DefaultResponse
    removeFromAssortment: DefaultResponse
    removeFromWishlist: DefaultResponse
    sendContactForm: DefaultResponse
    sendDataRequestForm: DefaultResponse
    updateFeedOptions: DefaultResponse
    updateForgottenPassword: ForgotPasswordResponse
    updatePassword: UpdatePasswordResponse
    updateProfile: DefaultResponse
    updateShoppingCart: ShoppingCart
}


export interface MutationCreateDropshipmentOrderArgs {
    input: OrderDropshipmentInput
}


export interface MutationAddNewLoginAccountArgs {
    user: UserInput
}


export interface MutationAddToAssortmentArgs {
    eanCodes: Array<Scalars['String']['input']>
}


export interface MutationAddToShoppingCartArgs {
    input: ShoppingCartInput
}


export interface MutationAddToWishlistArgs {
    eanCodes: Array<Scalars['String']['input']>
}


export interface MutationApproveDebtorArgs {
    input: ApproveDebtorInput
}


export interface MutationAssignDebtorIdToUserArgs {
    input: AssignDebtorIdToUser
}


export interface MutationCreateOrderArgs {
    input: OrderInput
}


export interface MutationDeleteFromShoppingCartArgs {
    eans: Array<Scalars['String']['input']>
    name: Scalars['String']['input']
}


export interface MutationDeleteShoppingCartArgs {
    name: Scalars['String']['input']
}


export interface MutationDownloadRequestedArgs {
    input?: InputMaybe<DownloadInput>
}


export interface MutationEditLoginAccountArgs {
    user: UserEditInput
}


export interface MutationEnhanceDebtorArgs {
    input: EnhanceDebtorInput
}


export interface MutationForgotPasswordArgs {
    input: ForgotPasswordInput
}


export interface MutationImpersonationArgs {
    input?: InputMaybe<ImpersonationInput>
}


export interface MutationLoginArgs {
    input?: InputMaybe<LoginInput>
}


export interface MutationRefreshTokenArgs {
    input?: InputMaybe<RefreshTokenInput>
}


export interface MutationRegisterNewDebtorArgs {
    input: RegisterNewDebtorInput
}


export interface MutationRegisterNewsLetterSubscriptionArgs {
    input: RegisterNewsLetterSubscriptionInput
}


export interface MutationRejectDebtorArgs {
    input: RejectDebtorInput
}


export interface MutationRemoveFromAssortmentArgs {
    eanCodes: Array<Scalars['String']['input']>
}


export interface MutationRemoveFromWishlistArgs {
    eanCodes: Array<Scalars['String']['input']>
}


export interface MutationSendContactFormArgs {
    input: ContactFormInput
}


export interface MutationSendDataRequestFormArgs {
    input: DataRequestInput
}


export interface MutationUpdateFeedOptionsArgs {
    products: FeedOptions
    stocks: FeedOptions
}


export interface MutationUpdateForgottenPasswordArgs {
    input?: InputMaybe<NewPasswordWithCodeInput>
}


export interface MutationUpdatePasswordArgs {
    input: UpdatePassword
}


export interface MutationUpdateProfileArgs {
    hidePurchasePrice: Scalars['Boolean']['input']
    preferredLanguage: Scalars['String']['input']
    splitOrderByDefault: Scalars['Boolean']['input']
}


export interface MutationUpdateShoppingCartArgs {
    input: ShoppingCartInput
}

/** Get the rights necessary to see the block. */
export enum NecessaryRightsEnum {
    All = 'ALL',
    Dropshipment = 'DROPSHIPMENT',
}

export interface NewPasswordWithCodeInput {
    email: Scalars['String']['input']
    password: Scalars['String']['input']
    password_confirmation: Scalars['String']['input']
    token: Scalars['String']['input']
}

export interface News {
    category?: Maybe<NewsCategoryEnum>
    components: Array<Component>
    description?: Maybe<Scalars['String']['output']>
    id: Scalars['Int']['output']
    image?: Maybe<Image>
    locale: Scalars['String']['output']
    seo: Seo
    slug: Scalars['String']['output']
    title: Scalars['String']['output']
    translations: Array<Localization>
}

/** Give the category for a news item. */
export enum NewsCategoryEnum {
    All = 'ALL',
    Events = 'EVENTS',
    News = 'NEWS',
    Products = 'PRODUCTS',
    Projects = 'PROJECTS',
}

export interface NewsLetter {
    category: NewsLetterTypeEnum
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

/** Available newsletters */
export enum NewsLetterTypeEnum {
    ExperienceCenter = 'EXPERIENCE_CENTER',
    General = 'GENERAL',
}

/** A paginated list of News items. */
export interface NewsPaginator {
    /** A list of News items. */
    data: Array<News>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo
}

export interface NewsSitemapEntry {
    slugs: SitemapSlugs
    updatedAt: Scalars['DateTime']['output']
}

export interface Order {
    address?: Maybe<Address>
    completed: Scalars['Boolean']['output']
    debtorId: Scalars['String']['output']
    dropShipment: Scalars['Boolean']['output']
    nextDeliveryDate?: Maybe<Scalars['DateTime']['output']>
    orderDate: Scalars['DateTime']['output']
    orderId: Scalars['String']['output']
    orderLines: Array<OrderLine>
    orderNumber?: Maybe<Scalars['String']['output']>
    /** @deprecated Use 'status' field instead. */
    orderStatus: Scalars['String']['output']
    reference: Scalars['String']['output']
    service: Scalars['Boolean']['output']
    status: OrderStateEnum
    totalExclVat: Scalars['Int']['output']
    totalIncVat: Scalars['Int']['output']
}

export interface OrderAddition {
    codeLocal: Scalars['String']['output']
    description?: Maybe<Scalars['String']['output']>
    img?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
}

/** Allows ordering a list of records. */
export interface OrderByClause {
    /** The column that is used for ordering. */
    column: Scalars['String']['input']
    /** The direction that is used for ordering. */
    order: SortOrder
}

/** Aggregate functions when ordering by a relation without specifying a column. */
export enum OrderByRelationAggregateFunction {
    /** Amount of items. */
    Count = 'COUNT',
}

/** Aggregate functions when ordering by a relation that may specify a column. */
export enum OrderByRelationWithColumnAggregateFunction {
    /** Average. */
    Avg = 'AVG',
    /** Amount of items. */
    Count = 'COUNT',
    /** Maximum. */
    Max = 'MAX',
    /** Minimum. */
    Min = 'MIN',
    /** Sum. */
    Sum = 'SUM',
}

export interface OrderDropshipmentInput {
    address: AddressInput
    description?: InputMaybe<Scalars['String']['input']>
    email?: InputMaybe<Scalars['String']['input']>
    orderLines?: InputMaybe<Array<InputMaybe<OrderLineInput>>>
    reference?: InputMaybe<Scalars['String']['input']>
    timezone?: InputMaybe<Scalars['String']['input']>
}

export interface OrderInput {
    email?: InputMaybe<Scalars['String']['input']>
    orderLines?: InputMaybe<Array<InputMaybe<OrderLineInput>>>
    reference?: InputMaybe<Scalars['String']['input']>
    timezone?: InputMaybe<Scalars['String']['input']>
}

export interface OrderLine {
    delivered: Scalars['Boolean']['output']
    deliveryDate: Scalars['DateTime']['output']
    item?: Maybe<OrderLineProduct>
    priceExclVat: Scalars['Int']['output']
    priceIncVat: Scalars['Int']['output']
    quantity: Scalars['Int']['output']
    saleable: Scalars['Boolean']['output']
}

export interface OrderLineInput {
    delivery?: InputMaybe<Scalars['WeekYear']['input']>
    ean?: InputMaybe<Scalars['String']['input']>
    quantity?: InputMaybe<Scalars['Int']['input']>
}

export type OrderLineProduct = ErrorType | OrderAddition | Product

/** A paginated list of Order items. */
export interface OrderPaginator {
    /** A list of Order items. */
    data: Array<Order>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo
}

export interface OrderProperties {
    deliveryInfo: DeliveryInfo
    productOrderStepDropshipment: Scalars['Int']['output']
    productOrderSteps: Scalars['Int']['output']
    salesUnit: Scalars['String']['output']
}

/** Give the type of order we want to get */
export enum OrderStateEnum {
    All = 'ALL',
    Closed = 'CLOSED',
    NotSend = 'NOT_SEND',
    Open = 'OPEN',
    Processing = 'PROCESSING',
    Send = 'SEND',
}

/** Give the type of order we want to get */
export enum OrderTypeEnum {
    All = 'ALL',
    Dropshipment = 'DROPSHIPMENT',
    Regular = 'REGULAR',
}

export interface Package {
    depthInMm: Scalars['Float']['output']
    ean: Scalars['String']['output']
    lengthMm: Scalars['Float']['output']
    productCode: Scalars['String']['output']
    quantity: Scalars['Int']['output']
    translations: PackageTranslationMap
    volM3: Scalars['Float']['output']
    weightInKg: Scalars['Float']['output']
    widthMm: Scalars['Float']['output']
}

export interface PackageTranslation {
    locale: Scalars['String']['output']
    name: Scalars['String']['output']
}

export interface PackageTranslationMap {
    de: PackageTranslation
    en: PackageTranslation
    fr: PackageTranslation
    nl: PackageTranslation
}

export interface Page {
    components: Array<Component>
    id: Scalars['Int']['output']
    locale: Scalars['String']['output']
    localizations: Array<Localization>
    seo?: Maybe<Seo>
    slug: Scalars['String']['output']
    title: Scalars['String']['output']
}

/** Layout types you can use for the page block. */
export enum PageLayoutEnum {
    BigButtons = 'BIG_BUTTONS',
    None = 'NONE',
}

export interface PageLink {
    icon?: Maybe<IconEnum>
    localizations: Array<Localization>
}

export interface PageLinks {
    pages: Array<PageLink>
    pagesLayout: PageLayoutEnum
    title: Scalars['String']['output']
}

export interface PageSitemapEntry {
    slugs: SitemapSlugs
    updatedAt: Scalars['DateTime']['output']
}

/** Information about pagination using a fully featured paginator. */
export interface PaginatorInfo {
    /** Number of items in the current page. */
    count: Scalars['Int']['output']
    /** Index of the current page. */
    currentPage: Scalars['Int']['output']
    /** Index of the first item in the current page. */
    firstItem?: Maybe<Scalars['Int']['output']>
    /** Are there more pages after this one? */
    hasMorePages: Scalars['Boolean']['output']
    /** Index of the last item in the current page. */
    lastItem?: Maybe<Scalars['Int']['output']>
    /** Index of the last available page. */
    lastPage: Scalars['Int']['output']
    /** Number of items per page. */
    perPage: Scalars['Int']['output']
    /** Number of total available items. */
    total: Scalars['Int']['output']
}

export interface Paragraph {
    buttons: Array<Button>
    paragraph: Scalars['String']['output']
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface ParagraphSlide {
    paragraph: Scalars['String']['output']
    title: Scalars['String']['output']
}

export interface Paragraphs {
    paragraphs: Array<Paragraph>
    paragraphsLayout: ParagraphsLayoutEnum
    showGrayBack: Scalars['Boolean']['output']
}

export interface ParagraphsCarousel {
    paragraphs: Array<ParagraphSlide>
}

/** Give the layout for a paragraphs block */
export enum ParagraphsLayoutEnum {
    DoubleColumn = 'DOUBLE_COLUMN',
    None = 'NONE',
    TripleColumn = 'TRIPLE_COLUMN',
}

export interface PaymentCondition {
    btwNumber: Scalars['String']['output']
    dropShipmentShipmentPreferredCountryCode: Scalars['String']['output']
    dropShipmentShipmentScales: Array<DropShipmentShipmentScales>
    francoLimit: Scalars['String']['output']
    invoiceDebtorCode: Scalars['String']['output']
    minimumOrderLimit: Scalars['String']['output']
    orderSupplement: Scalars['String']['output']
    orderSurcharge: Scalars['String']['output']
    overrideLimits: Scalars['String']['output']
    paymentCondition: Scalars['String']['output']
    paymentConditionDescription: Scalars['String']['output']
}

export interface PositionedImage {
    image: Image
    imagePosition: ImagePositionEnum
}

/** All available positioned pages. */
export enum PositionedPageEnum {
    AboutUs = 'ABOUT_US',
    Brochures = 'BROCHURES',
    Contact = 'CONTACT',
    Disclaimer = 'DISCLAIMER',
    ExperienceCenterBusiness = 'EXPERIENCE_CENTER_BUSINESS',
    ExperienceCenterConsumer = 'EXPERIENCE_CENTER_CONSUMER',
    Home = 'HOME',
    PrivacyPolicy = 'PRIVACY_POLICY',
    Projects = 'PROJECTS',
    ProjectDetail = 'PROJECT_DETAIL',
    RegistrationStepOne = 'REGISTRATION_STEP_ONE',
    RegistrationStepTwo = 'REGISTRATION_STEP_TWO',
    Service = 'SERVICE',
    TermsAndConditions = 'TERMS_AND_CONDITIONS',
}

export interface PositionedPages {
    identifier: PositionedPageEnum
    localizations: Array<Localization>
}

export interface PriceList {
    productPrices: Array<ProductPrice>
    updatedAt: Scalars['DateTime']['output']
}

export interface Product {
    ambianceImage: ProductImage
    assemblyRequired: Scalars['Boolean']['output']
    assortment: Scalars['String']['output']
    brand?: Maybe<Brand>
    categories: Array<Category>
    codeLocal: Scalars['String']['output']
    colors: Array<Color>
    country: Scalars['String']['output']
    /** @deprecated Field no longer supported */
    deliveryTimeInWeeks: Scalars['Int']['output']
    deliveryTimeInfo: DeliveryTimeInfoTranslationMap
    dimensions: Array<Dimension>
    downloads: Array<ProductDownload>
    ean: Scalars['String']['output']
    flatDimensions: Scalars['String']['output']
    fsc: Scalars['Boolean']['output']
    group: Scalars['String']['output']
    images: Array<ProductImage>
    intraStatCode: Scalars['String']['output']
    isNew: Scalars['Boolean']['output']
    katanaId: Scalars['Int']['output']
    materials: Array<MaterialTranslationMap>
    orderProperties?: Maybe<OrderProperties>
    packages: Array<Package>
    parentCategory?: Maybe<Category>
    price?: Maybe<ProductPrice>
    primaryImage: ProductImage
    /** @deprecated use totalPacked */
    qtyPackages: Scalars['Int']['output']
    range: Scalars['String']['output']
    saleUnit: Scalars['String']['output']
    shippingMethod: Scalars['String']['output']
    status: Scalars['String']['output']
    statusTranslations: StatusTranslationMap
    stock: Stock
    subColors: Array<Color>
    subMaterials: Array<MaterialTranslationMap>
    suggestedRetailPrice: Scalars['Int']['output']
    suggestedRetailPriceForDebtor: Scalars['Int']['output']
    suggestedRetailPriceIse: Scalars['Int']['output']
    suggestedStandardRetailPrice: Scalars['Int']['output']
    suggestedStandardRetailPriceForDebtor: Scalars['Int']['output']
    suggestedStandardRetailPriceIse: Scalars['Int']['output']
    themes: Array<Scalars['String']['output']>
    totalPackages: Scalars['Int']['output']
    totalPacked: Scalars['Int']['output']
    translations: ProductTranslationMap
    uniqueHash: Scalars['String']['output']
    variants?: Maybe<Variants>
    videos: Array<Scalars['String']['output']>
    wideImage: ProductImage
}

export interface ProductDownload {
    title: Scalars['String']['output']
    type?: Maybe<DownloadEnum>
    url: Scalars['String']['output']
}

export interface ProductImage {
    alt?: Maybe<Scalars['String']['output']>
    index: Scalars['Int']['output']
    subType: Scalars['String']['output']
    type: ProductImageTypeEnum
    url: Scalars['String']['output']
}

/** Give the type of product image. */
export enum ProductImageTypeEnum {
    Angled = 'ANGLED',
    Back = 'BACK',
    CloseUp = 'CLOSE_UP',
    Default = 'DEFAULT',
    Drawing = 'DRAWING',
    Fabric = 'FABRIC',
    Front = 'FRONT',
    Group = 'GROUP',
    Left = 'LEFT',
    Right = 'RIGHT',
}

/** A paginated list of Product items. */
export interface ProductPaginator {
    /** A list of Product items. */
    data: Array<Product>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo
}

export interface ProductPrice {
    ean: Scalars['String']['output']
    price: Scalars['Int']['output']
    quantity: Scalars['Int']['output']
    standardPrice: Scalars['Int']['output']
}

export interface ProductPriceInput {
    ean: Scalars['String']['input']
    quantity?: InputMaybe<Scalars['Int']['input']>
}

export interface ProductSitemapEntry {
    ean: Scalars['String']['output']
    hash: Scalars['String']['output']
    slugs: SitemapSlugs
    updatedAt: Scalars['DateTime']['output']
}

export interface ProductSlug {
    de: Scalars['String']['output']
    ean: Scalars['String']['output']
    en: Scalars['String']['output']
    fr: Scalars['String']['output']
    hash: Scalars['String']['output']
    nl: Scalars['String']['output']
}

export interface ProductTranslation {
    fullDescription: Scalars['String']['output']
    locale: Scalars['String']['output']
    name: Scalars['String']['output']
    shortDescription: Scalars['String']['output']
    slug: Scalars['String']['output']
}

export interface ProductTranslationMap {
    de: ProductTranslation
    en: ProductTranslation
    fr: ProductTranslation
    nl: ProductTranslation
}

export interface ProjectShowCase {
    description?: Maybe<Scalars['String']['output']>
    link?: Maybe<Link>
    slides: Array<Slide>
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

/** Fetch items by publish type. */
export enum PublishedEnum {
    All = 'ALL',
    IsPublished = 'IS_PUBLISHED',
    NotPublished = 'NOT_PUBLISHED',
}

export interface PurchasingAssociation {
    name?: InputMaybe<Scalars['String']['input']>
    number?: InputMaybe<Scalars['String']['input']>
}

export interface Query {
    ProductPaginationForDebtor: ProductPaginator
    assortments: AssortmentPaginator
    brand?: Maybe<Brand>
    brands: Array<Brand>
    categories: Array<Category>
    category?: Maybe<Category>
    debtor: Debtor
    downloadStatus?: Maybe<DownloadProcess>
    event?: Maybe<Event>
    events: EventPaginator
    health: Scalars['Boolean']['output']
    healthCheck: Scalars['Boolean']['output']
    me: User
    newsItem?: Maybe<News>
    newsItems: NewsPaginator
    order?: Maybe<Order>
    orderCheckIfReferenceIsAvailable: Scalars['Boolean']['output']
    orders: OrderPaginator
    ordersForDebtor: OrderPaginator
    ordersSearch: OrderPaginator
    page?: Maybe<Page>
    positionedPages: Array<PositionedPages>
    pricesForDebtor: PriceList
    product?: Maybe<Product>
    productBySlug?: Maybe<Product>
    productPricesForDebtor: PriceList
    /** @deprecated Use sitemap with more info */
    productSlugs: Array<ProductSlug>
    productsByEan: Array<Product>
    productsForDebtor: Array<Product>
    recommendedProducts: ProductPaginator
    registrationPopup?: Maybe<RegistrationPopup>
    saleStatistics?: Maybe<SaleStatistics>
    shoppingCart: ShoppingCart
    sidebar?: Maybe<Sidebar>
    sitemap: Array<SitemapEntry>
    stock?: Maybe<Stock>
    stocks: StockPaginator
    theme?: Maybe<Theme>
    themes: Array<Theme>
    user: BackofficeUser
    users: BackofficeUserPaginator
}


export interface QueryProductPaginationForDebtorArgs {
    debtorCode: Scalars['String']['input']
    eans: Array<Scalars['String']['input']>
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryAssortmentsArgs {
    filterBy?: InputMaybe<AssortmentFilterByInput>
    first: Scalars['Int']['input']
    orderBy?: InputMaybe<AssortmentOrderByInput>
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryBrandArgs {
    slug: Scalars['String']['input']
}


export interface QueryCategoryArgs {
    locale: LocalesEnum
    slug: Scalars['String']['input']
}


export interface QueryEventArgs {
    locale: LocalesEnum
    slug: Scalars['String']['input']
}


export interface QueryEventsArgs {
    category: EventsCategoryEnum
    first: Scalars['Int']['input']
    locale: LocalesEnum
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryNewsItemArgs {
    locale: LocalesEnum
    slug: Scalars['String']['input']
}


export interface QueryNewsItemsArgs {
    category: NewsCategoryEnum
    first: Scalars['Int']['input']
    locale: LocalesEnum
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryOrderArgs {
    orderId: Scalars['String']['input']
}


export interface QueryOrderCheckIfReferenceIsAvailableArgs {
    reference: Scalars['String']['input']
}


export interface QueryOrdersArgs {
    dateOrder: SortEnum
    first: Scalars['Int']['input']
    nextDelivery: SortEnum
    orderState: OrderStateEnum
    orderType: OrderTypeEnum
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryOrdersForDebtorArgs {
    dateOrder: SortEnum
    debtorCode: Scalars['String']['input']
    first: Scalars['Int']['input']
    orderState: OrderStateEnum
    orderType: OrderTypeEnum
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryOrdersSearchArgs {
    dateOrder: SortEnum
    first: Scalars['Int']['input']
    nextDelivery: SortEnum
    orderState: OrderStateEnum
    orderType: OrderTypeEnum
    page?: InputMaybe<Scalars['Int']['input']>
    search: Scalars['String']['input']
}


export interface QueryPageArgs {
    locale: LocalesEnum
    slug: Scalars['String']['input']
}


export interface QueryPositionedPagesArgs {
    positionedIdentifier: Array<PositionedPageEnum>
}


export interface QueryPricesForDebtorArgs {
    debtorCode: Scalars['String']['input']
    products: Array<ProductPriceInput>
}


export interface QueryProductArgs {
    ean: Scalars['String']['input']
}


export interface QueryProductBySlugArgs {
    slug: Scalars['String']['input']
}


export interface QueryProductPricesForDebtorArgs {
    products: Array<ProductPriceInput>
}


export interface QueryProductsByEanArgs {
    eans: Array<Scalars['String']['input']>
}


export interface QueryProductsForDebtorArgs {
    debtorCode: Scalars['String']['input']
    eans: Array<Scalars['String']['input']>
}


export interface QueryRecommendedProductsArgs {
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryRegistrationPopupArgs {
    locale: Scalars['String']['input']
}


export interface QuerySaleStatisticsArgs {
    timeSpan: TimePeriodEnum
    type: StatisticTypeEnum
}


export interface QueryShoppingCartArgs {
    name: Scalars['String']['input']
}


export interface QuerySidebarArgs {
    locale: Scalars['String']['input']
}


export interface QuerySitemapArgs {
    filterBy?: InputMaybe<SitemapFilterByInput>
}


export interface QueryStockArgs {
    ean: Scalars['String']['input']
}


export interface QueryStocksArgs {
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
}


export interface QueryThemeArgs {
    locale: LocalesEnum
    slug: Scalars['String']['input']
}


export interface QueryThemesArgs {
    locale: LocalesEnum
    published: PublishedEnum
}


export interface QueryUserArgs {
    id: Scalars['Int']['input']
}


export interface QueryUsersArgs {
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
}

export interface Quote {
    name?: Maybe<Scalars['String']['output']>
    photo?: Maybe<Image>
    text: Scalars['String']['output']
    title?: Maybe<Scalars['String']['output']>
}

export interface Quotes {
    quotes: Array<Quote>
}

export interface RecoveryCode {
    code: Scalars['String']['output']
}

export interface RefreshTokenInput {
    clientId?: InputMaybe<Scalars['String']['input']>
    clientSecret?: InputMaybe<Scalars['String']['input']>
    refreshToken?: InputMaybe<Scalars['String']['input']>
}

export interface RefreshTokenPayload {
    accessToken: Scalars['String']['output']
    expiresIn: Scalars['Int']['output']
    refreshToken: Scalars['String']['output']
    tokenType: Scalars['String']['output']
}

export interface RegisterNewDebtorInput {
    city: Scalars['String']['input']
    companyName: Scalars['String']['input']
    consumerCompanyName?: InputMaybe<Scalars['String']['input']>
    country: Scalars['String']['input']
    description?: InputMaybe<Scalars['String']['input']>
    email: Scalars['String']['input']
    interested: Array<InputMaybe<Scalars['String']['input']>>
    isNotARobot: Scalars['String']['input']
    knowingFrom: Array<Scalars['String']['input']>
    name: Scalars['String']['input']
    otherBusinessFair?: InputMaybe<Scalars['String']['input']>
    otherKnowingFrom?: InputMaybe<Scalars['String']['input']>
    otherType?: InputMaybe<Scalars['String']['input']>
    phone: Scalars['String']['input']
    preferredLanguage?: InputMaybe<Scalars['String']['input']>
    type: Array<Scalars['String']['input']>
    website?: InputMaybe<Scalars['String']['input']>
}

export interface RegisterNewsLetterSubscriptionInput {
    email: Scalars['String']['input']
    isNotARobot: Scalars['String']['input']
    locale: LocalesEnum
    type: NewsLetterTypeEnum
}

export interface RegistrationPopup {
    tabs: Array<Tab>
    title: Scalars['String']['output']
}

export interface RejectDebtorInput {
    email: Scalars['String']['input']
    reason: Scalars['String']['input']
}

/**
 * The url used to reset the password.
 * Use the `__EMAIL__` and `__TOKEN__` placeholders to inject the reset password email and token.
 *
 * e.g; `https://my-front-end.com?reset-password?email=__EMAIL__&token=__TOKEN__`
 */
export interface ResetPasswordUrlInput {
    url: Scalars['String']['input']
}

export enum ResponseStatuses {
    Failed = 'FAILED',
    Success = 'SUCCESS',
}

export interface SaleStatistic {
    product?: Maybe<Product>
    totalAmount?: Maybe<Scalars['Int']['output']>
    totalItems?: Maybe<Scalars['Int']['output']>
}

export interface SaleStatistics {
    date?: Maybe<Scalars['DateTime']['output']>
    debtorId: Scalars['String']['output']
    period?: Maybe<TimePeriodEnum>
    statistics: Array<SaleStatistic>
    type?: Maybe<StatisticTypeEnum>
}

export interface Seo {
    canonicalUrl?: Maybe<Scalars['String']['output']>
    description: Scalars['String']['output']
    image?: Maybe<Image>
    keywords?: Maybe<Scalars['String']['output']>
    robots?: Maybe<Scalars['String']['output']>
    socials: Array<Social>
    title: Scalars['String']['output']
    viewport?: Maybe<Scalars['String']['output']>
}

export interface ShoppingCart {
    cartLine: Array<ShoppingCartLine>
    total: Scalars['BigInt']['output']
    totalItems: Scalars['Int']['output']
}

export interface ShoppingCartInput {
    name: Scalars['String']['input']
    shoppingCartLine: Array<ShoppingCartLineInput>
}

export interface ShoppingCartLine {
    id: Scalars['String']['output']
    price?: Maybe<Scalars['Int']['output']>
    product?: Maybe<Product>
    quantity?: Maybe<Scalars['Int']['output']>
    subTotal?: Maybe<Scalars['Int']['output']>
}

export interface ShoppingCartLineInput {
    ean: Scalars['String']['input']
    quantity: Scalars['Int']['input']
}

export interface Sidebar {
    image?: Maybe<Image>
    uniqueSellingPoints: Array<SidebarUniqueSellingPoint>
}

export interface SidebarUniqueSellingPoint {
    icon: IconEnum
    necessaryRight?: Maybe<NecessaryRightsEnum>
    title: Scalars['String']['output']
}

export type SitemapEntry = CategorySitemapEntry | EventSitemapEntry | NewsSitemapEntry | PageSitemapEntry | ProductSitemapEntry | ThemeSitemapEntry

export interface SitemapFilterByInput {
    from?: InputMaybe<Scalars['DateTime']['input']>
}

export interface SitemapSlugs {
    de?: Maybe<Scalars['String']['output']>
    en?: Maybe<Scalars['String']['output']>
    fr?: Maybe<Scalars['String']['output']>
    nl?: Maybe<Scalars['String']['output']>
}

export interface Slide {
    image?: Maybe<ImageComponent>
    link?: Maybe<Link>
    subtitle: Scalars['String']['output']
    title: Scalars['String']['output']
}

export interface Social {
    description?: Maybe<Scalars['String']['output']>
    image?: Maybe<Image>
    socialNetwork: SocialNetworkEnum
    title: Scalars['String']['output']
}

/** All available social network svg icons. */
export enum SocialNetworkEnum {
    Facebook = 'FACEBOOK',
    Twitter = 'TWITTER',
}

/** we need to convert this to the SortEnum */
export enum Sort {
    Asc = 'asc',
    Desc = 'desc',
}

/** Give the storing for the items we need to get */
export enum SortEnum {
    Ascending = 'ASCENDING',
    Descending = 'DESCENDING',
    None = 'NONE',
}

/** Directions for ordering a list of records. */
export enum SortOrder {
    /** Sort records in ascending order. */
    Asc = 'ASC',
    /** Sort records in descending order. */
    Desc = 'DESC',
}

export interface Statistic {
    label: Scalars['String']['output']
    value: Scalars['String']['output']
}

/** Layout types you can use for the statistics block. */
export enum StatisticLayoutEnum {
    DescriptionBig = 'DESCRIPTION_BIG',
    TitleBig = 'TITLE_BIG',
}

/** Give the type of statistic only 1 of 2 is filed */
export enum StatisticTypeEnum {
    TotalItems = 'TOTAL_ITEMS',
    TotalSales = 'TOTAL_SALES',
}

export interface Statistics {
    statistics: Array<Statistic>
    statisticsLayout?: Maybe<StatisticLayoutEnum>
}

export interface StatusTranslationMap {
    de: Scalars['String']['output']
    en: Scalars['String']['output']
    fr: Scalars['String']['output']
    nl: Scalars['String']['output']
}

export interface Stock {
    articleCode: Scalars['String']['output']
    ean: Scalars['String']['output']
    quantity: Scalars['Int']['output']
}

/** A paginated list of Stock items. */
export interface StockPaginator {
    /** A list of Stock items. */
    data: Array<Stock>
    /** Pagination information about the list of items. */
    paginatorInfo: PaginatorInfo
}

export interface SubFooterLink {
    links: Array<Slide>
}

export interface Subscription {
    downloadProcessUpdated?: Maybe<DownloadProcess>
    downloadProgressed: DownloadProgress
}


export interface SubscriptionDownloadProgressedArgs {
    input: DownloadProgressInput
}

export interface Tab {
    paragraphs: Array<IconParagraphs>
    text?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
}

export interface TextHeader {
    firstLine: Scalars['String']['output']
    secondLine: Scalars['String']['output']
}

export interface Theme {
    components: Array<Component>
    highlightedProducts: Array<Maybe<Product>>
    id: Scalars['String']['output']
    image: Scalars['String']['output']
    katanaId: Scalars['Int']['output']
    name: Scalars['String']['output']
    seo?: Maybe<Seo>
    translations: TranslationsMap
}

export interface ThemeSitemapEntry {
    slugs: SitemapSlugs
    updatedAt: Scalars['DateTime']['output']
}

/** Give the period for the statistic */
export enum TimePeriodEnum {
    Month = 'MONTH',
    Quarter = 'QUARTER',
    Year = 'YEAR',
}

export interface TimeWindow {
    friday?: InputMaybe<BusinessHours>
    monday?: InputMaybe<BusinessHours>
    saturday?: InputMaybe<BusinessHours>
    sunday?: InputMaybe<BusinessHours>
    thursday?: InputMaybe<BusinessHours>
    tuesday?: InputMaybe<BusinessHours>
    wednesday?: InputMaybe<BusinessHours>
}

export interface TranslationsMap {
    de?: Maybe<Localization>
    en?: Maybe<Localization>
    fr?: Maybe<Localization>
    nl?: Maybe<Localization>
}

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
    /** Only return trashed results. */
    Only = 'ONLY',
    /** Return both trashed and non-trashed results. */
    With = 'WITH',
    /** Only return non-trashed results. */
    Without = 'WITHOUT',
}

export interface TwoFactorSecret {
    decrypted: Scalars['String']['output']
    qr: Scalars['String']['output']
}

export interface UniqueSellingPoint {
    icon: IconEnum
    subtitle: Scalars['String']['output']
    title: Scalars['String']['output']
}

export interface UniqueSellingPoints {
    uniqueSellingPoints: Array<UniqueSellingPoint>
}

export interface UpdatePassword {
    oldPassword: Scalars['String']['input']
    password: Scalars['String']['input']
    password_confirmation: Scalars['String']['input']
}

export interface UpdatePasswordResponse {
    message: Scalars['String']['output']
    status: Scalars['String']['output']
}

export interface User {
    countryCode?: Maybe<Scalars['String']['output']>
    createdAt: Scalars['DateTime']['output']
    debtorCode?: Maybe<Scalars['String']['output']>
    email: Scalars['String']['output']
    feeds: Feeds
    hidePurchasePrice: Scalars['Boolean']['output']
    id: Scalars['String']['output']
    isActive: Scalars['Boolean']['output']
    isEnhanced: Scalars['Boolean']['output']
    lastLoggedIn?: Maybe<Scalars['DateTime']['output']>
    name: Scalars['String']['output']
    preferredLanguage: Scalars['String']['output']
    splitOrderByDefault: Scalars['Boolean']['output']
    updatedAt: Scalars['DateTime']['output']
    wasMigrated: Scalars['Boolean']['output']
    wishListItems: Array<WishlistItem>
}

export interface UserEditInput {
    debtorId?: InputMaybe<Scalars['String']['input']>
    id: Scalars['Int']['input']
    isActive?: InputMaybe<Scalars['Boolean']['input']>
    name?: InputMaybe<Scalars['String']['input']>
    password?: InputMaybe<Scalars['String']['input']>
    preferredLanguage?: InputMaybe<Scalars['String']['input']>
}

export interface UserInput {
    debtorId: Scalars['String']['input']
    email: Scalars['String']['input']
    isActive: Scalars['Boolean']['input']
    name: Scalars['String']['input']
    password: Scalars['String']['input']
    preferredLanguage: Scalars['String']['input']
}

export interface Variant {
    current: Scalars['Boolean']['output']
    presentation: Scalars['String']['output']
    translations: VariantTranslationMap
}

export interface VariantTranslation {
    label?: Maybe<Scalars['String']['output']>
    slug?: Maybe<Scalars['String']['output']>
}

export interface VariantTranslationMap {
    de: VariantTranslation
    en: VariantTranslation
    fr: VariantTranslation
    nl: VariantTranslation
}

export interface Variants {
    colors: Array<Variant>
    materials: Array<Variant>
    types: Array<Variant>
    /** @deprecated Use types from now on. */
    variants: Array<Variant>
}

export interface Video {
    alt?: Maybe<Scalars['String']['output']>
    caption?: Maybe<Scalars['String']['output']>
    name: Scalars['String']['output']
    previewUrl: Scalars['String']['output']
    provider: Scalars['String']['output']
    url: Scalars['String']['output']
}

export interface VideoHeader {
    headerLayout: HeaderLayoutEnum
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
    video: VideoText
}

export interface VideoText {
    title: Scalars['String']['output']
    video: Video
}

export interface VideoWithText {
    content: Scalars['String']['output']
    subtitle?: Maybe<Scalars['String']['output']>
    title: Scalars['String']['output']
    videoText: Video
}

export interface WishlistItem {
    createdAt: Scalars['DateTime']['output']
    /** @deprecated Field no longer supported */
    ean: Scalars['String']['output']
    id: Scalars['ID']['output']
    product?: Maybe<Product>
    updatedAt: Scalars['DateTime']['output']
}

export interface DropShipmentShipmentScale {
    cost: Scalars['Int']['output']
    from: Scalars['Int']['output']
    to: Scalars['Int']['output']
}

export interface DropShipmentShipmentScales {
    locale?: Maybe<Scalars['String']['output']>
    scales: Array<DropShipmentShipmentScale>
}

export type AccountManagerQueryVariables = Exact<{ [key: string]: never }>


export type AccountManagerQuery = { debtor: { debtorCode: string; accountManager: AccountManagerDataFragment } }

export type AccountManagerDataFragment = { name: string; email: string; photoUrl: string; phone: string; jobTitle: string }

export type AssortmentsQueryVariables = Exact<{
    filterBy: AssortmentFilterByInput
    orderBy: AssortmentOrderByInput
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
}>


export type AssortmentsQuery = { assortments: { paginatorInfo: PaginatorInfoFragment; data: Array<AssortmentFragment> } }

export type MinimalAssortmentsQueryVariables = Exact<{
    filterBy: AssortmentFilterByInput
    orderBy: AssortmentOrderByInput
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
}>


export type MinimalAssortmentsQuery = { assortments: { paginatorInfo: PaginatorInfoFragment; data: Array<MinimalAssortmentsFragment> } }

export type AssortmentEansQueryVariables = Exact<{
    filterBy: AssortmentFilterByInput
}>


export type AssortmentEansQuery = { assortments: { data: Array<{ ean: string }> } }

export type AddToAssortmentMutationVariables = Exact<{
    eanCodes: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type AddToAssortmentMutation = { addToAssortment: { message: string } }

export type RemoveFromAssortmentMutationVariables = Exact<{
    eanCodes: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type RemoveFromAssortmentMutation = { removeFromAssortment: DefaultResponseFragment }

export type MinimalAssortmentsFragment = { ean: string; id: string }

export type AssortmentFragment = { id: string; ean: string; createdAt: string; product?: SimpleAssortmentProductFragment | null }

export type SimpleAssortmentProductFragment = { ean: string; codeLocal: string; suggestedRetailPriceForDebtor: number; status: string; dimensions: Array<DimensionFragment>; statusTranslations: StatusTranslationMapFragment; brand?: { name: string } | null; price?: AssortmentPricesFragment | null; stock: ProductStockFragment; images: Array<ProductImageFragment>; translations: { en: ProductTranslationFragment; fr: ProductTranslationFragment; nl: ProductTranslationFragment; de: ProductTranslationFragment } }

export type AssortmentPricesFragment = { price: number; standardPrice: number }

export type BrandsQueryVariables = Exact<{ [key: string]: never }>


export type BrandsQuery = { brands: Array<BrandFragment> }

export type BrandQueryVariables = Exact<{
    slug: Scalars['String']['input']
}>


export type BrandQuery = { brand?: CompleteBrandFragment | null }

export type BrandFragment = { id: number; name: string; slug: string; highlightedProducts: Array<HighlightedProductFragment>; translations: BrandTranslationsMapFragment }

export type CompleteBrandFragment = { id: number; name: string; slug: string; highlightedProducts: Array<HighlightedProductFragment>; translations: CompleteBrandTranslationsMapFragment }

export type CompleteBrandTranslationsMapFragment = { en: CompleteBrandTranslationFragment; nl: CompleteBrandTranslationFragment; de: CompleteBrandTranslationFragment; fr: CompleteBrandTranslationFragment }

export type BrandTranslationsMapFragment = { en: BrandTranslationFragment; nl: BrandTranslationFragment; de: BrandTranslationFragment; fr: BrandTranslationFragment }

export type BrandTranslationFragment = { description: string }

export type CompleteBrandTranslationFragment = { description: string; seo?: SeoFragment | null; components: Array<Components_Accordion_Fragment | Components_AccountManager_Fragment | Components_Assets_Fragment | Components_Carousel_Fragment | Components_Categories_Fragment | Components_CtaLink_Fragment | Components_Form_Fragment | Components_ImageContentLink_Fragment | Components_ImageHeader_Fragment | Components_ImageMosaic_Fragment | Components_ImageText_Fragment | Components_ImageWithText_Fragment | Components_ImageWithTextHeader_Fragment | Components_Map_Fragment | Components_NewsLetter_Fragment | Components_PageLinks_Fragment | Components_Paragraphs_Fragment | Components_ParagraphsCarousel_Fragment | Components_ProjectShowCase_Fragment | Components_Quotes_Fragment | Components_Statistics_Fragment | Components_SubFooterLink_Fragment | Components_TextHeader_Fragment | Components_UniqueSellingPoints_Fragment | Components_VideoHeader_Fragment | Components_VideoText_Fragment | Components_VideoWithText_Fragment> }

export type CategoriesQueryVariables = Exact<{ [key: string]: never }>


export type CategoriesQuery = { categories: Array<CategoryFragment> }

export type CategoryQueryVariables = Exact<{
    slug: Scalars['String']['input']
    locale: LocalesEnum
}>


export type CategoryQuery = { category?: IndividualCategoryFragment | null }

export type CategoryFragment = { id: string; published: boolean; translations: CategoryTranslationsMapFragment; highlightedProducts: Array<HighlightedProductFragment | null>; children: Array<ChildCategoryFragment | null>; productImage?: ImageFragment | null }

export type IndividualCategoryFragment = { id: string; published: boolean; translations: IndividualCategoryTranslationsMapFragment }

export type ChildCategoryFragment = { id: string; published: boolean; parentId?: string | null; translations: CategoryTranslationsMapFragment }

export type CategoryTranslationsMapFragment = { en: CategoryTranslationFragment; fr: CategoryTranslationFragment; de: CategoryTranslationFragment; nl: CategoryTranslationFragment }

export type IndividualCategoryTranslationsMapFragment = { en: IndividualCategoryTranslationFragment; fr: IndividualCategoryTranslationFragment; de: IndividualCategoryTranslationFragment; nl: IndividualCategoryTranslationFragment }

export type CategoryTranslationFragment = { slug: string; name: string; description?: string | null }

export type IndividualCategoryTranslationFragment = { slug: string; name: string; description?: string | null; seo?: SeoFragment | null; components: Array<Components_Accordion_Fragment | Components_AccountManager_Fragment | Components_Assets_Fragment | Components_Carousel_Fragment | Components_Categories_Fragment | Components_CtaLink_Fragment | Components_Form_Fragment | Components_ImageContentLink_Fragment | Components_ImageHeader_Fragment | Components_ImageMosaic_Fragment | Components_ImageText_Fragment | Components_ImageWithText_Fragment | Components_ImageWithTextHeader_Fragment | Components_Map_Fragment | Components_NewsLetter_Fragment | Components_PageLinks_Fragment | Components_Paragraphs_Fragment | Components_ParagraphsCarousel_Fragment | Components_ProjectShowCase_Fragment | Components_Quotes_Fragment | Components_Statistics_Fragment | Components_SubFooterLink_Fragment | Components_TextHeader_Fragment | Components_UniqueSellingPoints_Fragment | Components_VideoHeader_Fragment | Components_VideoText_Fragment | Components_VideoWithText_Fragment> }

export type CreateOrderMutationVariables = Exact<{
    input: OrderInput
}>


export type CreateOrderMutation = { createOrder: { __typename: 'DefaultResponse'; message: string; reason?: string | null; status: ResponseStatuses } }

export type CreateDropshipmentOrderMutationVariables = Exact<{
    input: OrderDropshipmentInput
}>


export type CreateDropshipmentOrderMutation = { CreateDropshipmentOrder: DefaultResponseFragment }

export type SendContactFormMutationVariables = Exact<{
    input: ContactFormInput
}>


export type SendContactFormMutation = { sendContactForm: DefaultResponseFragment }

export type SendDataRequestFormMutationVariables = Exact<{
    input: DataRequestInput
}>


export type SendDataRequestFormMutation = { sendDataRequestForm: DefaultResponseFragment }

export type UpdateFeedOptionsMutationVariables = Exact<{
    products: FeedOptions
    stocks: FeedOptions
}>


export type UpdateFeedOptionsMutation = { updateFeedOptions: { status: ResponseStatuses; reason?: string | null; message: string } }

export type DataFeedsQueryVariables = Exact<{ [key: string]: never }>


export type DataFeedsQuery = { me: DataFeedFragment }

export type DataFeedFragment = { feeds: { stocks: { fields: Array<{ name: string; child?: string | null }>; translated: { de: Array<{ key: string; label: string }>; fr: Array<{ key: string; label: string }>; nl: Array<{ key: string; label: string }>; en: Array<{ key: string; label: string }> }; options: Array<{ key: string; alias: string; selected: boolean } | null> }; products: { fields: Array<{ name: string; child?: string | null }>; translated: { en: Array<{ key: string; label: string }>; de: Array<{ key: string; label: string }>; fr: Array<{ key: string; label: string }>; nl: Array<{ key: string; label: string }> }; options: Array<{ key: string; alias: string; selected: boolean } | null> } } }

export type DebtorQueryVariables = Exact<{ [key: string]: never }>


export type DebtorQuery = { debtor: DebtorFragment }

export type CompleteDebtorQueryVariables = Exact<{ [key: string]: never }>


export type CompleteDebtorQuery = { debtor: CompleteDebtorFragment }

export type CompleteDebtorFragment = { debtorCode: string; name: string; website: string; paymentCondition: PaymentConditionFragment; debtorAddresses: Array<DebtorAddressFragment>; accountManager: DebtorAccountManagerFragment; debtorRights: DebtorRightsFragment }

export type DebtorFragment = { name: string; debtorCode: string; debtorAddresses: Array<DebtorAddressFragment>; debtorRights: DebtorRightsFragment; paymentCondition: PaymentConditionFragment }

export type DebtorRightsFragment = { allowedCountry: string; allowedModules: { dropShipment: boolean; datafeed: boolean; dontShowNewProducts: boolean; showNewFromDate?: string | null }; allowedBrands: Array<{ code: string; description: string }>; allowedProductGroups: Array<{ code: string; description: string }>; allowedExclusives: Array<{ code: string; description: string }> }

export type DebtorAccountManagerFragment = { name: string; email: string; phone: string; jobTitle: string; photoUrl: string }

export type DebtorAddressFragment = { name: string; city: string; country: string; number: string; street: string; zipcode: string; phone?: string | null }

export type PaymentConditionFragment = { paymentCondition: string; paymentConditionDescription: string; francoLimit: string; orderSurcharge: string; minimumOrderLimit: string; orderSupplement: string; overrideLimits: string; btwNumber: string; invoiceDebtorCode: string; dropShipmentShipmentPreferredCountryCode: string; dropShipmentShipmentScales: Array<DropshipmentScalesFragment> }

export type DropshipmentScalesFragment = { locale?: string | null; scales: Array<DropshipmentScaleFragment> }

export type DropshipmentScaleFragment = { from: number; to: number; cost: number }

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>


export type DeleteAccountMutation = { deleteAccount: DefaultResponseFragment }

export type DownloadRequestedMutationVariables = Exact<{
    input?: InputMaybe<DownloadInput>
}>


export type DownloadRequestedMutation = { downloadRequested: string }

export type DownloadProgressSubscriptionVariables = Exact<{
    debtorId: Scalars['String']['input']
    downloadId: Scalars['String']['input']
}>


export type DownloadProgressSubscription = { downloadProgressed: DownloadProgressFragment }

export type DownloadProgressFragment = { url?: string | null; percentage: number }

export type EnhanceDebtorMutationVariables = Exact<{
    input: EnhanceDebtorInput
}>


export type EnhanceDebtorMutation = { enhanceDebtor: DefaultResponseFragment }

export type EventsQueryVariables = Exact<{
    first: Scalars['Int']['input']
    page: Scalars['Int']['input']
    locale: LocalesEnum
    eventsCategoryEnum: EventsCategoryEnum
}>


export type EventsQuery = { events: { paginatorInfo: PaginatorInfoFragment; data: Array<SimpleEventFragment> } }

export type EventQueryVariables = Exact<{
    slug: Scalars['String']['input']
    locale: LocalesEnum
}>


export type EventQuery = { event?: EventFragment | null }

export type SimpleEventFragment = { __typename: 'Event'; id: number; locale: string; slug: string; title: string; description?: string | null; category?: EventsCategoryEnum | null; image?: ImageFragment | null }

export type EventFragment = { id: number; locale: string; slug: string; title: string; description?: string | null; category?: EventsCategoryEnum | null; image?: ImageFragment | null; seo: SeoFragment; components: Array<Components_Accordion_Fragment | Components_AccountManager_Fragment | Components_Assets_Fragment | Components_Carousel_Fragment | Components_Categories_Fragment | Components_CtaLink_Fragment | Components_Form_Fragment | Components_ImageContentLink_Fragment | Components_ImageHeader_Fragment | Components_ImageMosaic_Fragment | Components_ImageText_Fragment | Components_ImageWithText_Fragment | Components_ImageWithTextHeader_Fragment | Components_Map_Fragment | Components_NewsLetter_Fragment | Components_PageLinks_Fragment | Components_Paragraphs_Fragment | Components_ParagraphsCarousel_Fragment | Components_ProjectShowCase_Fragment | Components_Quotes_Fragment | Components_Statistics_Fragment | Components_SubFooterLink_Fragment | Components_TextHeader_Fragment | Components_UniqueSellingPoints_Fragment | Components_VideoHeader_Fragment | Components_VideoText_Fragment | Components_VideoWithText_Fragment> }

export type ForgotPasswordMutationVariables = Exact<{
    input: ForgotPasswordInput
}>


export type ForgotPasswordMutation = { forgotPassword: { status: string; message: string } }

export type RegisterNewsLetterSubscriptionMutationVariables = Exact<{
    input: RegisterNewsLetterSubscriptionInput
}>


export type RegisterNewsLetterSubscriptionMutation = { registerNewsLetterSubscription: DefaultResponseFragment }

export type HealthCheckQueryVariables = Exact<{ [key: string]: never }>


export type HealthCheckQuery = { healthCheck: boolean }

export type UserFragment = { id: string; name: string; email: string; updatedAt: string; createdAt: string; debtorCode?: string | null; hidePurchasePrice: boolean; splitOrderByDefault: boolean; preferredLanguage: string; lastLoggedIn?: string | null; wasMigrated: boolean; isEnhanced: boolean; isActive: boolean }

export type MeQueryVariables = Exact<{ [key: string]: never }>


export type MeQuery = { me: UserFragment }

export type NewsItemsQueryVariables = Exact<{
    first: Scalars['Int']['input']
    page: Scalars['Int']['input']
    locale: LocalesEnum
    newsCategoryEnum: NewsCategoryEnum
}>


export type NewsItemsQuery = { newsItems: { paginatorInfo: PaginatorInfoFragment; data: Array<SimpleNewsItemFragment> } }

export type NewsItemQueryVariables = Exact<{
    slug: Scalars['String']['input']
    locale: LocalesEnum
}>


export type NewsItemQuery = { newsItem?: NewsFragment | null }

export type SimpleNewsItemFragment = { __typename: 'News'; id: number; locale: string; slug: string; title: string; description?: string | null; category?: NewsCategoryEnum | null; image?: ImageFragment | null }

export type NewsFragment = { id: number; locale: string; slug: string; title: string; description?: string | null; category?: NewsCategoryEnum | null; image?: ImageFragment | null; seo: SeoFragment; components: Array<Components_Accordion_Fragment | Components_AccountManager_Fragment | Components_Assets_Fragment | Components_Carousel_Fragment | Components_Categories_Fragment | Components_CtaLink_Fragment | Components_Form_Fragment | Components_ImageContentLink_Fragment | Components_ImageHeader_Fragment | Components_ImageMosaic_Fragment | Components_ImageText_Fragment | Components_ImageWithText_Fragment | Components_ImageWithTextHeader_Fragment | Components_Map_Fragment | Components_NewsLetter_Fragment | Components_PageLinks_Fragment | Components_Paragraphs_Fragment | Components_ParagraphsCarousel_Fragment | Components_ProjectShowCase_Fragment | Components_Quotes_Fragment | Components_Statistics_Fragment | Components_SubFooterLink_Fragment | Components_TextHeader_Fragment | Components_UniqueSellingPoints_Fragment | Components_VideoHeader_Fragment | Components_VideoText_Fragment | Components_VideoWithText_Fragment> }

export type SimpleOrdersQueryVariables = Exact<{
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
    dateOrder: SortEnum
    nextDelivery: SortEnum
    orderState: OrderStateEnum
    orderType: OrderTypeEnum
}>


export type SimpleOrdersQuery = { orders: { paginatorInfo: PaginatorInfoFragment; data: Array<SimpleOrderFragment> } }

export type OrderNumbersAndStatusQueryVariables = Exact<{
    orderState: OrderStateEnum
    orderType: OrderTypeEnum
}>


export type OrderNumbersAndStatusQuery = { orders: { data: Array<OrderNumbersAndStatusFragment> } }

export type OrderQueryVariables = Exact<{
    input: Scalars['String']['input']
}>


export type OrderQuery = { order?: OrderFragment | null }

export type OrdersByReferenceAndIdQueryVariables = Exact<{
    search: Scalars['String']['input']
    first: Scalars['Int']['input']
    page?: InputMaybe<Scalars['Int']['input']>
    dateOrder: SortEnum
    nextDelivery: SortEnum
    orderType: OrderTypeEnum
    orderState: OrderStateEnum
}>


export type OrdersByReferenceAndIdQuery = { ordersSearch: { paginatorInfo: PaginatorInfoFragment; data: Array<OrderFragment> } }

export type IsOrderReferenceAvailableQueryVariables = Exact<{
    input: Scalars['String']['input']
}>


export type IsOrderReferenceAvailableQuery = { orderCheckIfReferenceIsAvailable: boolean }

export type SimpleOrderFragment = { orderDate: string; orderId: string; status: OrderStateEnum; reference: string; orderNumber?: string | null; nextDeliveryDate?: string | null; orderLines: Array<{ quantity: number; deliveryDate: string }> }

export type OrderFragment = { orderId: string; orderNumber?: string | null; debtorId: string; dropShipment: boolean; service: boolean; status: OrderStateEnum; totalExclVat: number; totalIncVat: number; orderDate: string; reference: string; address?: DebtorAddressFragment | null; orderLines: Array<OrderLineFragment> }

export type OrderAdditionFragment = { __typename: 'OrderAddition'; name: string; img?: string | null; codeLocal: string; description?: string | null }

export type ErrorTypeFragment = { __typename: 'ErrorType'; error: string }

export type OrderLineFragment = { quantity: number, priceExclVat: number, priceIncVat: number, delivered: boolean, deliveryDate: string, item?: (
    { __typename: 'ErrorType' }
    & ErrorTypeFragment
) | (
    { __typename: 'OrderAddition' }
    & OrderAdditionFragment
) | (
    { __typename: 'Product' }
    & ProductFragment
) | null }

export type OrderNumbersAndStatusFragment = { orderNumber?: string | null; status: OrderStateEnum }

export type PageFragment = { title: string; id: number; slug: string; locale: string; localizations: Array<LocalizationFragment>; seo?: SeoFragment | null; components: Array<Components_Accordion_Fragment | Components_AccountManager_Fragment | Components_Assets_Fragment | Components_Carousel_Fragment | Components_Categories_Fragment | Components_CtaLink_Fragment | Components_Form_Fragment | Components_ImageContentLink_Fragment | Components_ImageHeader_Fragment | Components_ImageMosaic_Fragment | Components_ImageText_Fragment | Components_ImageWithText_Fragment | Components_ImageWithTextHeader_Fragment | Components_Map_Fragment | Components_NewsLetter_Fragment | Components_PageLinks_Fragment | Components_Paragraphs_Fragment | Components_ParagraphsCarousel_Fragment | Components_ProjectShowCase_Fragment | Components_Quotes_Fragment | Components_Statistics_Fragment | Components_SubFooterLink_Fragment | Components_TextHeader_Fragment | Components_UniqueSellingPoints_Fragment | Components_VideoHeader_Fragment | Components_VideoText_Fragment | Components_VideoWithText_Fragment> }

export type PageQueryVariables = Exact<{
    slug: Scalars['String']['input']
    locale: LocalesEnum
}>


export type PageQuery = { page?: PageFragment | null }

export type PositionedPagesQueryVariables = Exact<{
    positionedIdentifier: Array<PositionedPageEnum> | PositionedPageEnum
}>


export type PositionedPagesQuery = { positionedPages: Array<PositionedPageFragment> }

type Components_Accordion_Fragment = AccordionFragment

type Components_AccountManager_Fragment = AccountManagerFragment

type Components_Assets_Fragment = AssetsFragment

type Components_Carousel_Fragment = CarouselFragment

type Components_Categories_Fragment = ContentCategoriesFragment

type Components_CtaLink_Fragment = CtaLinkFragment

type Components_Form_Fragment = FormFragment

type Components_ImageContentLink_Fragment = ImageContentLinkFragment

type Components_ImageHeader_Fragment = ImageHeaderFragment

type Components_ImageMosaic_Fragment = ImageMosaicFragment

type Components_ImageText_Fragment = ImageTextFragment

type Components_ImageWithText_Fragment = ImageWithTextFragment

type Components_ImageWithTextHeader_Fragment = ImageWithTextHeaderFragment

type Components_Map_Fragment = MapFragment

type Components_NewsLetter_Fragment = NewsLetterFragment

type Components_PageLinks_Fragment = PageLinksFragment

type Components_Paragraphs_Fragment = ParagraphsFragment

type Components_ParagraphsCarousel_Fragment = ParagraphsCarouselFragment

type Components_ProjectShowCase_Fragment = ProjectShowCaseFragment

type Components_Quotes_Fragment = QuotesFragment

type Components_Statistics_Fragment = StatisticsFragment

type Components_SubFooterLink_Fragment = SubFooterLinkFragment

type Components_TextHeader_Fragment = TextHeaderFragment

type Components_UniqueSellingPoints_Fragment = UniqueSellingPointsFragment

type Components_VideoHeader_Fragment = VideoHeaderFragment

type Components_VideoText_Fragment = VideoTextFragment

type Components_VideoWithText_Fragment = VideoWithTextFragment

export type ComponentsFragment = Components_Accordion_Fragment | Components_AccountManager_Fragment | Components_Assets_Fragment | Components_Carousel_Fragment | Components_Categories_Fragment | Components_CtaLink_Fragment | Components_Form_Fragment | Components_ImageContentLink_Fragment | Components_ImageHeader_Fragment | Components_ImageMosaic_Fragment | Components_ImageText_Fragment | Components_ImageWithText_Fragment | Components_ImageWithTextHeader_Fragment | Components_Map_Fragment | Components_NewsLetter_Fragment | Components_PageLinks_Fragment | Components_Paragraphs_Fragment | Components_ParagraphsCarousel_Fragment | Components_ProjectShowCase_Fragment | Components_Quotes_Fragment | Components_Statistics_Fragment | Components_SubFooterLink_Fragment | Components_TextHeader_Fragment | Components_UniqueSellingPoints_Fragment | Components_VideoHeader_Fragment | Components_VideoText_Fragment | Components_VideoWithText_Fragment

export type PositionedPageFragment = { identifier: PositionedPageEnum; localizations: Array<{ title: string; locale: string; slug: string }> }

export type SeoFragment = { title: string; keywords?: string | null; description: string; robots?: string | null; viewport?: string | null; canonicalUrl?: string | null; image?: ImageFragment | null; socials: Array<SocialFragment> }

export type SocialFragment = { socialNetwork: SocialNetworkEnum; title: string; description?: string | null; image?: ImageFragment | null }

export type LocalizationFragment = { title: string; slug: string; locale: string }

export type AccountManagerFragment = { __typename: 'AccountManager'; show: boolean }

export type CarouselFragment = { __typename: 'Carousel'; theme: CarouselThemeEnum; title: string; subtitle?: string | null; showHeader: boolean; showScrollBar: boolean; slides: Array<CarouselSlideFragment> }

export type CarouselSlideFragment = { title: string; subtitle: string; text?: string | null; backgroundImage?: ImageComponentFragment | null; mainImage?: ImageComponentFragment | null; link?: LinkFragment | null }

export type FormFragment = { __typename: 'Form'; type: FormTypeEnum }

export type CtaLinkFragment = { __typename: 'CtaLink'; textCta: string; subtitleCta: string; title: string; button?: ButtonFragment | null; image: ImageComponentFragment }

export type ImageMosaicFragment = { __typename: 'ImageMosaic'; portrait: ImageComponentFragment; landscape1: ImageComponentFragment; landscape2: ImageComponentFragment }

export type SubFooterLinkFragment = { __typename: 'SubFooterLink'; links: Array<{ title: string; subtitle: string; image?: ImageComponentFragment | null; link?: LinkFragment | null }> }

export type TextHeaderFragment = { __typename: 'TextHeader'; firstLine: string; secondLine: string }

export type VideoHeaderFragment = { __typename: 'VideoHeader'; headerLayout: HeaderLayoutEnum; video: VideoTextFragment }

export type ImageHeaderFragment = { __typename: 'ImageHeader'; title: string; subtitle?: string | null; headerLayout: HeaderLayoutEnum; images: Array<ImageComponentFragment> }

export type ImageWithTextHeaderFragment = { __typename: 'ImageWithTextHeader'; title: string; subtitle?: string | null; description?: string | null; mainImage?: ImageComponentFragment | null; smallImage?: ImageFragment | null }

export type ImageComponentFragment = { __typename: 'ImageComponent'; desktop: PositionedImageFragment; laptop: PositionedImageFragment; mobile: PositionedImageFragment; tablet: PositionedImageFragment }

export type PositionedImageFragment = { __typename: 'PositionedImage'; imagePosition: ImagePositionEnum; image: ImageFragment }

export type QuotesFragment = { __typename: 'Quotes'; quotes: Array<QuoteFragment> }

export type StatisticsFragment = { __typename: 'Statistics'; statisticsLayout?: StatisticLayoutEnum | null; statistics: Array<StatisticFragment> }

export type ParagraphsCarouselFragment = { __typename: 'ParagraphsCarousel'; paragraphs: Array<ParagraphSlideFragment> }

export type ProjectShowCaseFragment = { __typename: 'ProjectShowCase'; title: string; subtitle?: string | null; description?: string | null; link?: LinkFragment | null; slides: Array<ProjectSlideFragment> }

export type ProjectSlideFragment = { title: string; subtitle: string; image?: ImageComponentFragment | null; link?: LinkFragment | null }

export type UniqueSellingPointsFragment = { __typename: 'UniqueSellingPoints'; uniqueSellingPoints: Array<UniqueSellingPointFragment> }

export type VideoWithTextFragment = { __typename: 'VideoWithText'; title: string; subtitle?: string | null; content: string; videoText: VideoFragment }

export type ImageWithTextFragment = { __typename: 'ImageWithText'; title: string; subtitle?: string | null; content: string; showGrayBack: boolean; imageLayout: ImagePositionEnum; buttons: Array<ButtonFragment>; image: ImageComponentFragment }

export type ParagraphFragment = { title: string; subtitle?: string | null; paragraph: string }

export type StatisticFragment = { label: string; value: string }

export type QuoteFragment = { text: string; name?: string | null; title?: string | null; photo?: ImageFragment | null }

export type UniqueSellingPointFragment = { icon: IconEnum; title: string; subtitle: string }

export type ImageFragment = { __typename: 'Image'; name: string; caption?: string | null; width: number; height: number; provider: string; previewUrl: string; url: string; alt?: string | null }

export type VideoFragment = { __typename: 'Video'; name: string; caption?: string | null; provider: string; previewUrl: string; url: string; alt?: string | null }

export type FileFragment = { __typename: 'File'; alt?: string | null; caption?: string | null; name: string; previewUrl: string; provider: string; type?: string | null; url: string }

export type ImageTextFragment = { __typename: 'ImageText'; title: string; image: ImageComponentFragment }

export type VideoTextFragment = { __typename: 'VideoText'; title: string; video: VideoFragment }

export type ParagraphsFragment = { __typename: 'Paragraphs', paragraphsLayout: ParagraphsLayoutEnum, showGrayBack: boolean, paragraphs: Array<(
    { buttons: Array<ButtonFragment> }
    & ParagraphFragment
)> }

export type ParagraphSlideFragment = { title: string; paragraph: string }

export type MapFragment = { __typename: 'Map'; markers: Array<StrapiMarkerFragment> }

export type NewsLetterFragment = { __typename: 'NewsLetter'; category: NewsLetterTypeEnum; title: string; subtitle?: string | null }

export type StrapiMarkerFragment = { lat?: number | null; lng?: number | null; website?: string | null; text?: string | null; address?: MarkerAddressFragment | null }

export type MarkerAddressFragment = { name: string; street: string; number: string; zipcode: string; city: string; country: string; phone?: string | null }

export type ContentCategoriesFragment = { __typename: 'Categories'; title: string; subtitle?: string | null; sale: ImageComponentFragment; new: ImageComponentFragment; categories: Array<ContentCategoryFragment> }

export type ContentCategoryFragment = { katanaId?: number | null; productImage?: ImageFragment | null; translations: { en: ContentCategoryTranslationFragment; fr: ContentCategoryTranslationFragment; de: ContentCategoryTranslationFragment; nl: ContentCategoryTranslationFragment } }

export type ContentCategoryTranslationMapFragment = { en: ContentCategoryTranslationFragment; fr: ContentCategoryTranslationFragment; de: ContentCategoryTranslationFragment; nl: ContentCategoryTranslationFragment }

export type ContentCategoryTranslationFragment = { slug: string; name: string }

export type ImageContentLinkFragment = { __typename: 'ImageContentLink'; title: string; text?: string | null; subtitle?: string | null; showGrayBack: boolean; mainImage?: ImageComponentFragment | null; contentImage?: Array<ImageComponentFragment> | null; pageLinks: Array<LinkFragment> }

export type PageLinksFragment = { __typename: 'PageLinks'; title: string; pagesLayout: PageLayoutEnum; pages: Array<PageLinkFragment> }

export type PageLinkFragment = { icon?: IconEnum | null; localizations: Array<LocalizationFragment> }

export type AccordionFragment = { __typename: 'Accordion'; title: string; icon?: IconEnum | null; accordionItems: Array<AccordionItemFragment>; button?: ButtonFragment | null }

export type AccordionItemFragment = { title: string; description: string }

export type AssetsFragment = { __typename: 'Assets'; assets: Array<AssetFragment> }

export type AssetFragment = { title: string, media?: (
    { __typename: 'File' }
    & FileFragment
) | (
    { __typename: 'Image' }
    & ImageFragment
) | (
    { __typename: 'Video' }
    & VideoFragment
) | null, image?: ImageFragment | null }

export type ButtonFragment = { icon?: IconEnum | null; type: ButtonThemeEnum; link: LinkFragment }

export type LinkFragment = { label: string; target: LinkTargetEnum; link: string }

export type PaginatorInfoFragment = { count: number; currentPage: number; firstItem?: number | null; hasMorePages: boolean; lastItem?: number | null; lastPage: number; perPage: number; total: number }

export type ProductQueryVariables = Exact<{
    ean: Scalars['String']['input']
}>


export type ProductQuery = { product?: ProductFragment | null }

export type ProductSlugsQueryVariables = Exact<{ [key: string]: never }>


export type ProductSlugsQuery = { productSlugs: Array<ProductSlugsFragment> }

export type ProductBySlugQueryVariables = Exact<{
    slug: Scalars['String']['input']
}>


export type ProductBySlugQuery = { productBySlug?: ProductFragment | null }

export type ProductsByEansQueryVariables = Exact<{
    eans: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type ProductsByEansQuery = { productsByEan: Array<ProductFragment> }

export type SimpleProductsByEanQueryVariables = Exact<{
    eans: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type SimpleProductsByEanQuery = { productsByEan: Array<SimpleProductFragment> }

export type HighlightedProductsQueryVariables = Exact<{ [key: string]: never }>


export type HighlightedProductsQuery = { recommendedProducts: { data: Array<SimpleProductFragment>; paginatorInfo: PaginatorInfoFragment } }

export type ProductsOrderPropertiesQueryVariables = Exact<{
    eans: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type ProductsOrderPropertiesQuery = { productsByEan: Array<ProductOrderPropertiesFragment> }

export type ProductSlugsFragment = { en: string; nl: string; de: string; fr: string; hash: string; ean: string }

export type ProductFragment = { __typename: 'Product'; ean: string; status: string; codeLocal: string; suggestedRetailPrice: number; suggestedRetailPriceIse: number; suggestedStandardRetailPrice: number; suggestedStandardRetailPriceIse: number; totalPackages: number; country: string; group: string; range: string; videos: Array<string>; fsc: boolean; brand?: { name: string } | null; orderProperties?: { productOrderStepDropshipment: number; productOrderSteps: number; deliveryInfo: { deliverableInWeeks?: number | null } } | null; packages: Array<PackagesFragment>; statusTranslations: StatusTranslationMapFragment; downloads: Array<ProductDownloadFragment>; dimensions: Array<DimensionFragment>; categories: Array<ProductCategoryFragment>; images: Array<ProductImageFragment>; variants?: VariantsFragment | null; subColors: Array<ColorFragment>; subMaterials: Array<{ nl: string; de: string; fr: string; en: string }>; colors: Array<ColorFragment>; materials: Array<{ nl: string; de: string; fr: string; en: string }>; translations: ProductTranslationsMapFragment }

export type SimpleProductFragment = { katanaId: number; ean: string; codeLocal: string; range: string; status: string; suggestedRetailPrice: number; suggestedRetailPriceIse: number; suggestedStandardRetailPrice: number; suggestedStandardRetailPriceIse: number; fsc: boolean; images: Array<ProductImageFragment>; orderProperties?: { productOrderStepDropshipment: number; productOrderSteps: number; deliveryInfo: { deliverableInWeeks?: number | null } } | null; statusTranslations: StatusTranslationMapFragment; dimensions: Array<DimensionFragment>; categories: Array<ProductCategoryFragment>; stock: { quantity: number; ean: string; articleCode: string }; brand?: { name: string } | null; translations: { en: SimpleProductTranslationFragment; de: SimpleProductTranslationFragment; fr: SimpleProductTranslationFragment; nl: SimpleProductTranslationFragment }; subColors: Array<ColorFragment>; subMaterials: Array<{ nl: string; de: string; fr: string; en: string }>; colors: Array<ColorFragment>; materials: Array<{ nl: string; de: string; fr: string; en: string }> }

export type ProductImageFragment = { __typename: 'ProductImage'; url: string; type: ProductImageTypeEnum; alt?: string | null }

export type ColorFragment = { __typename: 'Color'; presentation: string; translations: { en: string; nl: string; de: string; fr: string } }

export type SimpleProductTranslationFragment = { slug: string; name: string; shortDescription: string; fullDescription: string }

export type StatusTranslationMapFragment = { en: string; nl: string; de: string; fr: string }

export type ProductDownloadFragment = { title: string; url: string; type?: DownloadEnum | null }

export type PackagesFragment = { ean: string; productCode: string; depthInMm: number; lengthMm: number; widthMm: number; weightInKg: number; volM3: number; quantity: number }

export type VariantsFragment = { colors: Array<VariantFragment>; types: Array<VariantFragment>; materials: Array<VariantFragment> }

export type VariantFragment = { current: boolean; presentation: string; translations: VariantTranslationMapFragment }

export type VariantTranslationMapFragment = { en: VariantTranslationFragment; nl: VariantTranslationFragment; fr: VariantTranslationFragment; de: VariantTranslationFragment }

export type VariantTranslationFragment = { slug?: string | null; label?: string | null }

export type DimensionFragment = { dimension: number; label: string }

export type HighlightedProductFragment = { ean: string; images: Array<ProductImageFragment>; translations: { en: HighlightedProductTranslationFragment; fr: HighlightedProductTranslationFragment; nl: HighlightedProductTranslationFragment; de: HighlightedProductTranslationFragment } }

export type HighlightedProductTranslationFragment = { name: string; slug: string }

export type ProductOrderPropertiesFragment = { ean: string; stock: ProductStockFragment; price?: ProductPriceFragment | null }

export type ProductPriceFragment = { ean: string; price: number; standardPrice: number }

export type ProductStockFragment = { ean: string; quantity: number }

export type ProductCategoryFragment = { id: string; translations: { en: ProductCategoryTranslationFragment; nl: ProductCategoryTranslationFragment; fr: ProductCategoryTranslationFragment; de: ProductCategoryTranslationFragment } }

export type ProductCategoryTranslationFragment = { name: string }

export type RegisterNewDebtorMutationVariables = Exact<{
    input: RegisterNewDebtorInput
}>


export type RegisterNewDebtorMutation = { registerNewDebtor: RegisterResponseFragment }

export type RegisterResponseFragment = { message: string; status: ResponseStatuses; reason?: string | null }

export type DefaultResponseFragment = { message: string; status: ResponseStatuses; reason?: string | null }

export type SaleStatisticsQueryVariables = Exact<{
    type: StatisticTypeEnum
    timeSpan: TimePeriodEnum
}>


export type SaleStatisticsQuery = { saleStatistics?: SaleStatisticsFragment | null }

export type SaleStatisticsFragment = { debtorId: string; date?: string | null; type?: StatisticTypeEnum | null; period?: TimePeriodEnum | null; statistics: Array<SaleStatisticFragment> }

export type SaleStatisticFragment = { totalItems?: number | null; totalAmount?: number | null; product?: SimpleProductFragment | null }

export type ServerPreloadQueryVariables = Exact<{
    positionedIdentifier: Array<PositionedPageEnum> | PositionedPageEnum
}>


export type ServerPreloadQuery = { categories: Array<CategoryFragment>; brands: Array<BrandFragment>; themesEN: Array<ThemeFragment>; themesNL: Array<ThemeFragment>; positionedPages: Array<PositionedPageFragment> }

export type ShoppingCartQueryVariables = Exact<{
    name: Scalars['String']['input']
}>


export type ShoppingCartQuery = { shoppingCart: ShoppingCartFragment }

export type ShoppingCartTotalItemsQueryVariables = Exact<{
    name: Scalars['String']['input']
}>


export type ShoppingCartTotalItemsQuery = { shoppingCart: { totalItems: number } }

export type AddToShoppingCartMutationVariables = Exact<{
    input: ShoppingCartInput
}>


export type AddToShoppingCartMutation = { addToShoppingCart: ShoppingCartFragment }

export type UpdateShoppingCartMutationVariables = Exact<{
    input: ShoppingCartInput
}>


export type UpdateShoppingCartMutation = { updateShoppingCart: ShoppingCartFragment }

export type DeleteShoppingCartMutationVariables = Exact<{
    name: Scalars['String']['input']
}>


export type DeleteShoppingCartMutation = { deleteShoppingCart: { status: ResponseStatuses; reason?: string | null; message: string } }

export type DeleteFromShoppingCartMutationVariables = Exact<{
    name: Scalars['String']['input']
    eans: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type DeleteFromShoppingCartMutation = { deleteFromShoppingCart: ShoppingCartFragment }

export type ShoppingCartFragment = { totalItems: number; total: number; cartLine: Array<ShoppingCartLineFragment> }

export type ShoppingCartProductFragment = { ean: string; codeLocal: string; katanaId: number; status: string; stock: { quantity: number }; brand?: { name: string } | null; parentCategory?: { translations: { en: { name: string }; de: { name: string }; fr: { name: string }; nl: { name: string } } } | null; categories: Array<{ translations: { en: { name: string }; de: { name: string }; fr: { name: string }; nl: { name: string } } }>; images: Array<ProductImageFragment>; translations: ShoppingCartProductTranslationsMapFragment; price?: ProductPriceFragment | null; orderProperties?: { salesUnit: string; productOrderSteps: number; productOrderStepDropshipment: number; deliveryInfo: { deliverableInWeeks?: number | null; weekNumber: number; weekYear: string } } | null }

export type ProductTranslationsMapFragment = { en: ProductTranslationFragment; fr: ProductTranslationFragment; de: ProductTranslationFragment; nl: ProductTranslationFragment }

export type ShoppingCartProductTranslationFragment = { slug: string; name: string; fullDescription: string }

export type ShoppingCartProductTranslationsMapFragment = { en: ShoppingCartProductTranslationFragment; fr: ShoppingCartProductTranslationFragment; de: ShoppingCartProductTranslationFragment; nl: ShoppingCartProductTranslationFragment }

export type ProductTranslationFragment = { slug: string; name: string; shortDescription: string; fullDescription: string }

export type ShoppingCartLineFragment = { id: string; quantity?: number | null; subTotal?: number | null; price?: number | null; product?: ShoppingCartProductFragment | null }

export type SidebarQueryVariables = Exact<{
    locale: Scalars['String']['input']
}>


export type SidebarQuery = { sidebar?: { uniqueSellingPoints: Array<SidebarUniqueSellingPointFragment>; image?: ImageFragment | null } | null }

export type SidebarUniqueSellingPointFragment = { icon: IconEnum; title: string }

export type ThemesQueryVariables = Exact<{
    locale: LocalesEnum
    published: PublishedEnum
}>


export type ThemesQuery = { themes: Array<SimpleThemeFragment> }

export type ThemeQueryVariables = Exact<{
    locale: LocalesEnum
    slug: Scalars['String']['input']
}>


export type ThemeQuery = { theme?: ThemeFragment | null }

export type ThemeFragment = { id: string; name: string; translations: TranslationsMapsFragment; seo?: SeoFragment | null; components: Array<Components_Accordion_Fragment | Components_AccountManager_Fragment | Components_Assets_Fragment | Components_Carousel_Fragment | Components_Categories_Fragment | Components_CtaLink_Fragment | Components_Form_Fragment | Components_ImageContentLink_Fragment | Components_ImageHeader_Fragment | Components_ImageMosaic_Fragment | Components_ImageText_Fragment | Components_ImageWithText_Fragment | Components_ImageWithTextHeader_Fragment | Components_Map_Fragment | Components_NewsLetter_Fragment | Components_PageLinks_Fragment | Components_Paragraphs_Fragment | Components_ParagraphsCarousel_Fragment | Components_ProjectShowCase_Fragment | Components_Quotes_Fragment | Components_Statistics_Fragment | Components_SubFooterLink_Fragment | Components_TextHeader_Fragment | Components_UniqueSellingPoints_Fragment | Components_VideoHeader_Fragment | Components_VideoText_Fragment | Components_VideoWithText_Fragment> }

export type SimpleThemeFragment = { id: string; name: string; translations: TranslationsMapsFragment; highlightedProducts: Array<HighlightedProductFragment | null> }

export type TranslationsMapsFragment = { en?: LocalizationFragment | null; nl?: LocalizationFragment | null; de?: LocalizationFragment | null; fr?: LocalizationFragment | null }

export type UpdatePasswordMutationVariables = Exact<{
    input: UpdatePassword
}>


export type UpdatePasswordMutation = { updatePassword: { status: string; message: string } }

export type UpdateForgottenPasswordMutationVariables = Exact<{
    input?: InputMaybe<NewPasswordWithCodeInput>
}>


export type UpdateForgottenPasswordMutation = { updateForgottenPassword: { status: string; message: string } }

export type UpdateProfileMutationVariables = Exact<{
    preferredLanguage: Scalars['String']['input']
    splitOrderByDefault: Scalars['Boolean']['input']
    hidePurchasePrice: Scalars['Boolean']['input']
}>


export type UpdateProfileMutation = { updateProfile: { message: string; status: ResponseStatuses; reason?: string | null } }

export type AddToWishlistMutationVariables = Exact<{
    eanCodes: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type AddToWishlistMutation = { addToWishlist: DefaultResponseFragment }

export type RemoveFromWishlistMutationVariables = Exact<{
    eanCodes: Array<Scalars['String']['input']> | Scalars['String']['input']
}>


export type RemoveFromWishlistMutation = { removeFromWishlist: DefaultResponseFragment }

export type GetWishlistQueryVariables = Exact<{ [key: string]: never }>


export type GetWishlistQuery = { me: { wishListItems: Array<{ product?: SimpleProductFragment | null }> } }

export type GetWishlistTotalQueryVariables = Exact<{ [key: string]: never }>


export type GetWishlistTotalQuery = { me: { wishListItems: Array<MinimalWishlistItemFragment> } }

export type MinimalWishlistItemFragment = { product?: { ean: string } | null }

export const AccountManagerDataFragmentDoc = gql`
    fragment AccountManagerData on DebtorAccountManager {
  name
  email
  photoUrl
  phone
  jobTitle
}
    `
export const MinimalAssortmentsFragmentDoc = gql`
    fragment MinimalAssortments on Assortment {
  ean
  id
}
    `
export const DimensionFragmentDoc = gql`
    fragment Dimension on Dimension {
  dimension
  label
}
    `
export const StatusTranslationMapFragmentDoc = gql`
    fragment StatusTranslationMap on StatusTranslationMap {
  en
  nl
  de
  fr
}
    `
export const AssortmentPricesFragmentDoc = gql`
    fragment AssortmentPrices on ProductPrice {
  price
  standardPrice
}
    `
export const ProductStockFragmentDoc = gql`
    fragment ProductStock on Stock {
  ean
  quantity
}
    `
export const ProductImageFragmentDoc = gql`
    fragment ProductImage on ProductImage {
  __typename
  url
  type
  alt
}
    `
export const ProductTranslationFragmentDoc = gql`
    fragment ProductTranslation on ProductTranslation {
  slug
  name
  shortDescription
  fullDescription
}
    `
export const SimpleAssortmentProductFragmentDoc = gql`
    fragment SimpleAssortmentProduct on Product {
  ean
  codeLocal
  suggestedRetailPriceForDebtor
  status
  dimensions {
    ...Dimension
  }
  statusTranslations {
    ...StatusTranslationMap
  }
  brand {
    name
  }
  price {
    ...AssortmentPrices
  }
  stock {
    ...ProductStock
  }
  images {
    ...ProductImage
  }
  translations {
    en {
      ...ProductTranslation
    }
    fr {
      ...ProductTranslation
    }
    nl {
      ...ProductTranslation
    }
    de {
      ...ProductTranslation
    }
  }
}
    ${DimensionFragmentDoc}
${StatusTranslationMapFragmentDoc}
${AssortmentPricesFragmentDoc}
${ProductStockFragmentDoc}
${ProductImageFragmentDoc}
${ProductTranslationFragmentDoc}`
export const AssortmentFragmentDoc = gql`
    fragment Assortment on Assortment {
  id
  ean
  createdAt
  product {
    ...SimpleAssortmentProduct
  }
}
    ${SimpleAssortmentProductFragmentDoc}`
export const HighlightedProductTranslationFragmentDoc = gql`
    fragment HighlightedProductTranslation on ProductTranslation {
  name
  slug
}
    `
export const HighlightedProductFragmentDoc = gql`
    fragment HighlightedProduct on Product {
  ean
  images {
    ...ProductImage
  }
  translations {
    en {
      ...HighlightedProductTranslation
    }
    fr {
      ...HighlightedProductTranslation
    }
    nl {
      ...HighlightedProductTranslation
    }
    de {
      ...HighlightedProductTranslation
    }
  }
}
    ${ProductImageFragmentDoc}
${HighlightedProductTranslationFragmentDoc}`
export const BrandTranslationFragmentDoc = gql`
    fragment BrandTranslation on BrandTranslation {
  description
}
    `
export const BrandTranslationsMapFragmentDoc = gql`
    fragment BrandTranslationsMap on BrandTranslationsMap {
  en {
    ...BrandTranslation
  }
  nl {
    ...BrandTranslation
  }
  de {
    ...BrandTranslation
  }
  fr {
    ...BrandTranslation
  }
}
    ${BrandTranslationFragmentDoc}`
export const BrandFragmentDoc = gql`
    fragment Brand on Brand {
  id
  name
  slug
  highlightedProducts {
    ...HighlightedProduct
  }
  translations {
    ...BrandTranslationsMap
  }
}
    ${HighlightedProductFragmentDoc}
${BrandTranslationsMapFragmentDoc}`
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  __typename
  name
  caption
  width
  height
  provider
  previewUrl
  url
  alt
}
    `
export const SocialFragmentDoc = gql`
    fragment Social on Social {
  socialNetwork
  title
  description
  image {
    ...Image
  }
}
    ${ImageFragmentDoc}`
export const SeoFragmentDoc = gql`
    fragment Seo on Seo {
  title
  keywords
  description
  robots
  viewport
  canonicalUrl
  image {
    ...Image
  }
  socials {
    ...Social
  }
}
    ${ImageFragmentDoc}
${SocialFragmentDoc}`
export const AccountManagerFragmentDoc = gql`
    fragment AccountManager on AccountManager {
  __typename
  show
}
    `
export const AccordionItemFragmentDoc = gql`
    fragment AccordionItem on AccordionItem {
  title
  description
}
    `
export const LinkFragmentDoc = gql`
    fragment Link on Link {
  label
  target
  link
}
    `
export const ButtonFragmentDoc = gql`
    fragment Button on Button {
  icon
  type
  link {
    ...Link
  }
}
    ${LinkFragmentDoc}`
export const AccordionFragmentDoc = gql`
    fragment Accordion on Accordion {
  title
  icon
  accordionItems {
    ...AccordionItem
  }
  button {
    ...Button
  }
  __typename
}
    ${AccordionItemFragmentDoc}
${ButtonFragmentDoc}`
export const FileFragmentDoc = gql`
    fragment File on File {
  __typename
  alt
  caption
  name
  previewUrl
  provider
  type
  url
}
    `
export const VideoFragmentDoc = gql`
    fragment Video on Video {
  __typename
  name
  caption
  provider
  previewUrl
  url
  alt
}
    `
export const AssetFragmentDoc = gql`
    fragment Asset on Asset {
  title
  media {
    __typename
    ...File
    ...Image
    ...Video
  }
  image {
    ...Image
  }
}
    ${FileFragmentDoc}
${ImageFragmentDoc}
${VideoFragmentDoc}`
export const AssetsFragmentDoc = gql`
    fragment Assets on Assets {
  __typename
  assets {
    ...Asset
  }
}
    ${AssetFragmentDoc}`
export const PositionedImageFragmentDoc = gql`
    fragment PositionedImage on PositionedImage {
  __typename
  image {
    ...Image
  }
  imagePosition
}
    ${ImageFragmentDoc}`
export const ImageComponentFragmentDoc = gql`
    fragment ImageComponent on ImageComponent {
  __typename
  desktop {
    ...PositionedImage
  }
  laptop {
    ...PositionedImage
  }
  mobile {
    ...PositionedImage
  }
  tablet {
    ...PositionedImage
  }
}
    ${PositionedImageFragmentDoc}`
export const CarouselSlideFragmentDoc = gql`
    fragment CarouselSlide on CarouselSlide {
  title
  backgroundImage {
    ...ImageComponent
  }
  mainImage {
    ...ImageComponent
  }
  link {
    ...Link
  }
  subtitle
  text
}
    ${ImageComponentFragmentDoc}
${LinkFragmentDoc}`
export const CarouselFragmentDoc = gql`
    fragment Carousel on Carousel {
  __typename
  theme
  title
  subtitle
  showHeader
  showScrollBar
  slides {
    ...CarouselSlide
  }
}
    ${CarouselSlideFragmentDoc}`
export const ContentCategoryTranslationFragmentDoc = gql`
    fragment ContentCategoryTranslation on CategoryTranslation {
  slug
  name
}
    `
export const ContentCategoryFragmentDoc = gql`
    fragment ContentCategory on Category {
  katanaId
  productImage {
    ...Image
  }
  translations {
    en {
      ...ContentCategoryTranslation
    }
    fr {
      ...ContentCategoryTranslation
    }
    de {
      ...ContentCategoryTranslation
    }
    nl {
      ...ContentCategoryTranslation
    }
  }
}
    ${ImageFragmentDoc}
${ContentCategoryTranslationFragmentDoc}`
export const ContentCategoriesFragmentDoc = gql`
    fragment ContentCategories on Categories {
  __typename
  title
  subtitle
  sale {
    ...ImageComponent
  }
  new {
    ...ImageComponent
  }
  categories {
    ...ContentCategory
  }
}
    ${ImageComponentFragmentDoc}
${ContentCategoryFragmentDoc}`
export const CtaLinkFragmentDoc = gql`
    fragment CtaLink on CtaLink {
  __typename
  textCta
  subtitleCta
  title
  button {
    ...Button
  }
  image {
    ...ImageComponent
  }
}
    ${ButtonFragmentDoc}
${ImageComponentFragmentDoc}`
export const ImageContentLinkFragmentDoc = gql`
    fragment ImageContentLink on ImageContentLink {
  __typename
  title
  text
  subtitle
  mainImage {
    ...ImageComponent
  }
  contentImage: images {
    ...ImageComponent
  }
  pageLinks {
    ...Link
  }
  showGrayBack
}
    ${ImageComponentFragmentDoc}
${LinkFragmentDoc}`
export const ImageHeaderFragmentDoc = gql`
    fragment ImageHeader on ImageHeader {
  __typename
  images {
    ...ImageComponent
  }
  title
  subtitle
  headerLayout
}
    ${ImageComponentFragmentDoc}`
export const ImageWithTextHeaderFragmentDoc = gql`
    fragment ImageWithTextHeader on ImageWithTextHeader {
  __typename
  title
  subtitle
  description
  mainImage {
    ...ImageComponent
  }
  smallImage {
    ...Image
  }
}
    ${ImageComponentFragmentDoc}
${ImageFragmentDoc}`
export const ImageMosaicFragmentDoc = gql`
    fragment ImageMosaic on ImageMosaic {
  __typename
  portrait {
    ...ImageComponent
  }
  landscape1 {
    ...ImageComponent
  }
  landscape2 {
    ...ImageComponent
  }
}
    ${ImageComponentFragmentDoc}`
export const ImageTextFragmentDoc = gql`
    fragment ImageText on ImageText {
  __typename
  title
  image {
    ...ImageComponent
  }
}
    ${ImageComponentFragmentDoc}`
export const ImageWithTextFragmentDoc = gql`
    fragment ImageWithText on ImageWithText {
  __typename
  title
  subtitle
  content
  buttons {
    ...Button
  }
  image {
    ...ImageComponent
  }
  showGrayBack
  imageLayout
}
    ${ButtonFragmentDoc}
${ImageComponentFragmentDoc}`
export const FormFragmentDoc = gql`
    fragment Form on Form {
  __typename
  type
}
    `
export const MarkerAddressFragmentDoc = gql`
    fragment MarkerAddress on Address {
  name
  street
  number
  zipcode
  city
  country
  phone
}
    `
export const StrapiMarkerFragmentDoc = gql`
    fragment StrapiMarker on Marker {
  lat
  lng
  website
  text
  address {
    ...MarkerAddress
  }
}
    ${MarkerAddressFragmentDoc}`
export const MapFragmentDoc = gql`
    fragment Map on Map {
  __typename
  markers {
    ...StrapiMarker
  }
}
    ${StrapiMarkerFragmentDoc}`
export const NewsLetterFragmentDoc = gql`
    fragment NewsLetter on NewsLetter {
  __typename
  category
  title
  subtitle
}
    `
export const LocalizationFragmentDoc = gql`
    fragment Localization on Localization {
  title
  slug
  locale
}
    `
export const PageLinkFragmentDoc = gql`
    fragment PageLink on PageLink {
  icon
  localizations {
    ...Localization
  }
}
    ${LocalizationFragmentDoc}`
export const PageLinksFragmentDoc = gql`
    fragment PageLinks on PageLinks {
  title
  pages {
    ...PageLink
  }
  pagesLayout
  __typename
}
    ${PageLinkFragmentDoc}`
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on Paragraph {
  title
  subtitle
  paragraph
}
    `
export const ParagraphsFragmentDoc = gql`
    fragment Paragraphs on Paragraphs {
  __typename
  paragraphsLayout
  showGrayBack
  paragraphs {
    ...Paragraph
    buttons {
      ...Button
    }
  }
}
    ${ParagraphFragmentDoc}
${ButtonFragmentDoc}`
export const ParagraphSlideFragmentDoc = gql`
    fragment ParagraphSlide on ParagraphSlide {
  title
  paragraph
}
    `
export const ParagraphsCarouselFragmentDoc = gql`
    fragment ParagraphsCarousel on ParagraphsCarousel {
  __typename
  paragraphs {
    ...ParagraphSlide
  }
}
    ${ParagraphSlideFragmentDoc}`
export const ProjectSlideFragmentDoc = gql`
    fragment ProjectSlide on Slide {
  title
  subtitle
  image {
    ...ImageComponent
  }
  link {
    ...Link
  }
}
    ${ImageComponentFragmentDoc}
${LinkFragmentDoc}`
export const ProjectShowCaseFragmentDoc = gql`
    fragment ProjectShowCase on ProjectShowCase {
  __typename
  title
  subtitle
  description
  link {
    ...Link
  }
  slides {
    ...ProjectSlide
  }
}
    ${LinkFragmentDoc}
${ProjectSlideFragmentDoc}`
export const QuoteFragmentDoc = gql`
    fragment Quote on Quote {
  text
  name
  title
  photo {
    ...Image
  }
}
    ${ImageFragmentDoc}`
export const QuotesFragmentDoc = gql`
    fragment Quotes on Quotes {
  __typename
  quotes {
    ...Quote
  }
}
    ${QuoteFragmentDoc}`
export const StatisticFragmentDoc = gql`
    fragment Statistic on Statistic {
  label
  value
}
    `
export const StatisticsFragmentDoc = gql`
    fragment Statistics on Statistics {
  __typename
  statisticsLayout
  statistics {
    ...Statistic
  }
}
    ${StatisticFragmentDoc}`
export const SubFooterLinkFragmentDoc = gql`
    fragment SubFooterLink on SubFooterLink {
  __typename
  links {
    image {
      ...ImageComponent
    }
    link {
      ...Link
    }
    title
    subtitle
  }
}
    ${ImageComponentFragmentDoc}
${LinkFragmentDoc}`
export const TextHeaderFragmentDoc = gql`
    fragment TextHeader on TextHeader {
  __typename
  firstLine
  secondLine
}
    `
export const UniqueSellingPointFragmentDoc = gql`
    fragment UniqueSellingPoint on UniqueSellingPoint {
  icon
  title
  subtitle
}
    `
export const UniqueSellingPointsFragmentDoc = gql`
    fragment UniqueSellingPoints on UniqueSellingPoints {
  __typename
  uniqueSellingPoints {
    ...UniqueSellingPoint
  }
}
    ${UniqueSellingPointFragmentDoc}`
export const VideoTextFragmentDoc = gql`
    fragment VideoText on VideoText {
  __typename
  title
  video {
    ...Video
  }
}
    ${VideoFragmentDoc}`
export const VideoHeaderFragmentDoc = gql`
    fragment VideoHeader on VideoHeader {
  __typename
  video {
    ...VideoText
  }
  headerLayout
}
    ${VideoTextFragmentDoc}`
export const VideoWithTextFragmentDoc = gql`
    fragment VideoWithText on VideoWithText {
  __typename
  title
  subtitle
  content
  videoText {
    ...Video
  }
}
    ${VideoFragmentDoc}`
export const ComponentsFragmentDoc = gql`
    fragment Components on Component {
  ... on AccountManager {
    ...AccountManager
  }
  ... on Accordion {
    ...Accordion
  }
  ... on Assets {
    ...Assets
  }
  ... on Carousel {
    ...Carousel
  }
  ... on Categories {
    ...ContentCategories
  }
  ... on CtaLink {
    ...CtaLink
  }
  ... on ImageContentLink {
    ...ImageContentLink
  }
  ... on ImageHeader {
    ...ImageHeader
  }
  ... on ImageWithTextHeader {
    ...ImageWithTextHeader
  }
  ... on ImageMosaic {
    ...ImageMosaic
  }
  ... on ImageText {
    ...ImageText
  }
  ... on ImageWithText {
    ...ImageWithText
  }
  ... on Form {
    ...Form
  }
  ... on Map {
    ...Map
  }
  ... on NewsLetter {
    ...NewsLetter
  }
  ... on PageLinks {
    ...PageLinks
  }
  ... on Paragraphs {
    ...Paragraphs
  }
  ... on ParagraphsCarousel {
    ...ParagraphsCarousel
  }
  ... on ProjectShowCase {
    ...ProjectShowCase
  }
  ... on Quotes {
    ...Quotes
  }
  ... on Statistics {
    ...Statistics
  }
  ... on SubFooterLink {
    ...SubFooterLink
  }
  ... on TextHeader {
    ...TextHeader
  }
  ... on UniqueSellingPoints {
    ...UniqueSellingPoints
  }
  ... on VideoHeader {
    ...VideoHeader
  }
  ... on VideoText {
    ...VideoText
  }
  ... on VideoWithText {
    ...VideoWithText
  }
}
    ${AccountManagerFragmentDoc}
${AccordionFragmentDoc}
${AssetsFragmentDoc}
${CarouselFragmentDoc}
${ContentCategoriesFragmentDoc}
${CtaLinkFragmentDoc}
${ImageContentLinkFragmentDoc}
${ImageHeaderFragmentDoc}
${ImageWithTextHeaderFragmentDoc}
${ImageMosaicFragmentDoc}
${ImageTextFragmentDoc}
${ImageWithTextFragmentDoc}
${FormFragmentDoc}
${MapFragmentDoc}
${NewsLetterFragmentDoc}
${PageLinksFragmentDoc}
${ParagraphsFragmentDoc}
${ParagraphsCarouselFragmentDoc}
${ProjectShowCaseFragmentDoc}
${QuotesFragmentDoc}
${StatisticsFragmentDoc}
${SubFooterLinkFragmentDoc}
${TextHeaderFragmentDoc}
${UniqueSellingPointsFragmentDoc}
${VideoHeaderFragmentDoc}
${VideoTextFragmentDoc}
${VideoWithTextFragmentDoc}`
export const CompleteBrandTranslationFragmentDoc = gql`
    fragment CompleteBrandTranslation on BrandTranslation {
  description
  seo {
    ...Seo
  }
  components {
    ...Components
  }
}
    ${SeoFragmentDoc}
${ComponentsFragmentDoc}`
export const CompleteBrandTranslationsMapFragmentDoc = gql`
    fragment CompleteBrandTranslationsMap on BrandTranslationsMap {
  en {
    ...CompleteBrandTranslation
  }
  nl {
    ...CompleteBrandTranslation
  }
  de {
    ...CompleteBrandTranslation
  }
  fr {
    ...CompleteBrandTranslation
  }
}
    ${CompleteBrandTranslationFragmentDoc}`
export const CompleteBrandFragmentDoc = gql`
    fragment CompleteBrand on Brand {
  id
  name
  slug
  highlightedProducts {
    ...HighlightedProduct
  }
  translations {
    ...CompleteBrandTranslationsMap
  }
}
    ${HighlightedProductFragmentDoc}
${CompleteBrandTranslationsMapFragmentDoc}`
export const CategoryTranslationFragmentDoc = gql`
    fragment CategoryTranslation on CategoryTranslation {
  slug
  name
  description
}
    `
export const CategoryTranslationsMapFragmentDoc = gql`
    fragment CategoryTranslationsMap on CategoryTranslationsMap {
  en {
    ...CategoryTranslation
  }
  fr {
    ...CategoryTranslation
  }
  de {
    ...CategoryTranslation
  }
  nl {
    ...CategoryTranslation
  }
}
    ${CategoryTranslationFragmentDoc}`
export const ChildCategoryFragmentDoc = gql`
    fragment ChildCategory on Category {
  id
  published
  parentId
  translations {
    ...CategoryTranslationsMap
  }
}
    ${CategoryTranslationsMapFragmentDoc}`
export const CategoryFragmentDoc = gql`
    fragment Category on Category {
  id
  published
  translations {
    ...CategoryTranslationsMap
  }
  highlightedProducts {
    ...HighlightedProduct
  }
  children {
    ...ChildCategory
  }
  productImage {
    ...Image
  }
}
    ${CategoryTranslationsMapFragmentDoc}
${HighlightedProductFragmentDoc}
${ChildCategoryFragmentDoc}
${ImageFragmentDoc}`
export const IndividualCategoryTranslationFragmentDoc = gql`
    fragment IndividualCategoryTranslation on CategoryTranslation {
  slug
  name
  description
  seo {
    ...Seo
  }
  components {
    ...Components
  }
}
    ${SeoFragmentDoc}
${ComponentsFragmentDoc}`
export const IndividualCategoryTranslationsMapFragmentDoc = gql`
    fragment IndividualCategoryTranslationsMap on CategoryTranslationsMap {
  en {
    ...IndividualCategoryTranslation
  }
  fr {
    ...IndividualCategoryTranslation
  }
  de {
    ...IndividualCategoryTranslation
  }
  nl {
    ...IndividualCategoryTranslation
  }
}
    ${IndividualCategoryTranslationFragmentDoc}`
export const IndividualCategoryFragmentDoc = gql`
    fragment IndividualCategory on Category {
  id
  published
  translations {
    ...IndividualCategoryTranslationsMap
  }
}
    ${IndividualCategoryTranslationsMapFragmentDoc}`
export const DataFeedFragmentDoc = gql`
    fragment DataFeed on User {
  feeds {
    stocks {
      fields {
        name
        child
      }
      translated {
        de {
          key
          label
        }
        fr {
          key
          label
        }
        nl {
          key
          label
        }
        en {
          key
          label
        }
      }
      options {
        key
        alias
        selected
      }
    }
    products {
      fields {
        name
        child
      }
      translated {
        en {
          key
          label
        }
        de {
          key
          label
        }
        fr {
          key
          label
        }
        nl {
          key
          label
        }
      }
      options {
        key
        alias
        selected
      }
    }
  }
}
    `
export const DropshipmentScaleFragmentDoc = gql`
    fragment DropshipmentScale on dropShipmentShipmentScale {
  from
  to
  cost
}
    `
export const DropshipmentScalesFragmentDoc = gql`
    fragment DropshipmentScales on dropShipmentShipmentScales {
  locale
  scales {
    ...DropshipmentScale
  }
}
    ${DropshipmentScaleFragmentDoc}`
export const PaymentConditionFragmentDoc = gql`
    fragment PaymentCondition on PaymentCondition {
  paymentCondition
  paymentConditionDescription
  francoLimit
  orderSurcharge
  minimumOrderLimit
  orderSupplement
  overrideLimits
  btwNumber
  invoiceDebtorCode
  dropShipmentShipmentPreferredCountryCode
  dropShipmentShipmentScales {
    ...DropshipmentScales
  }
}
    ${DropshipmentScalesFragmentDoc}`
export const DebtorAddressFragmentDoc = gql`
    fragment DebtorAddress on Address {
  name
  city
  country
  number
  street
  zipcode
  phone
}
    `
export const DebtorAccountManagerFragmentDoc = gql`
    fragment DebtorAccountManager on DebtorAccountManager {
  name
  email
  phone
  jobTitle
  photoUrl
}
    `
export const DebtorRightsFragmentDoc = gql`
    fragment DebtorRights on DebtorRights {
  allowedModules {
    dropShipment
    datafeed
    dontShowNewProducts
    showNewFromDate
  }
  allowedBrands {
    code
    description
  }
  allowedProductGroups {
    code
    description
  }
  allowedExclusives {
    code
    description
  }
  allowedCountry
}
    `
export const CompleteDebtorFragmentDoc = gql`
    fragment CompleteDebtor on Debtor {
  debtorCode
  name
  website
  paymentCondition {
    ...PaymentCondition
  }
  debtorAddresses {
    ...DebtorAddress
  }
  accountManager {
    ...DebtorAccountManager
  }
  debtorRights {
    ...DebtorRights
  }
}
    ${PaymentConditionFragmentDoc}
${DebtorAddressFragmentDoc}
${DebtorAccountManagerFragmentDoc}
${DebtorRightsFragmentDoc}`
export const DebtorFragmentDoc = gql`
    fragment Debtor on Debtor {
  name
  debtorCode
  debtorAddresses {
    ...DebtorAddress
  }
  debtorRights {
    ...DebtorRights
  }
  paymentCondition {
    ...PaymentCondition
  }
}
    ${DebtorAddressFragmentDoc}
${DebtorRightsFragmentDoc}
${PaymentConditionFragmentDoc}`
export const DownloadProgressFragmentDoc = gql`
    fragment DownloadProgress on DownloadProgress {
  url
  percentage
}
    `
export const SimpleEventFragmentDoc = gql`
    fragment SimpleEvent on Event {
  id
  locale
  slug
  title
  description
  image {
    ...Image
  }
  category
  __typename
}
    ${ImageFragmentDoc}`
export const EventFragmentDoc = gql`
    fragment Event on Event {
  id
  locale
  slug
  title
  description
  image {
    ...Image
  }
  seo {
    ...Seo
  }
  category
  components {
    ...Components
  }
}
    ${ImageFragmentDoc}
${SeoFragmentDoc}
${ComponentsFragmentDoc}`
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  updatedAt
  createdAt
  debtorCode
  hidePurchasePrice
  splitOrderByDefault
  preferredLanguage
  lastLoggedIn
  wasMigrated
  isEnhanced
  isActive
}
    `
export const SimpleNewsItemFragmentDoc = gql`
    fragment SimpleNewsItem on News {
  id
  locale
  slug
  title
  description
  category
  image {
    ...Image
  }
  __typename
}
    ${ImageFragmentDoc}`
export const NewsFragmentDoc = gql`
    fragment News on News {
  id
  locale
  slug
  title
  description
  category
  image {
    ...Image
  }
  seo {
    ...Seo
  }
  components {
    ...Components
  }
}
    ${ImageFragmentDoc}
${SeoFragmentDoc}
${ComponentsFragmentDoc}`
export const SimpleOrderFragmentDoc = gql`
    fragment SimpleOrder on Order {
  orderDate
  orderId
  status
  reference
  orderNumber
  orderLines {
    quantity
    deliveryDate
  }
  nextDeliveryDate
}
    `
export const PackagesFragmentDoc = gql`
    fragment Packages on Package {
  ean
  productCode
  depthInMm
  lengthMm
  widthMm
  weightInKg
  volM3
  quantity
}
    `
export const ProductDownloadFragmentDoc = gql`
    fragment ProductDownload on ProductDownload {
  title
  url
  type
}
    `
export const ProductCategoryTranslationFragmentDoc = gql`
    fragment ProductCategoryTranslation on CategoryTranslation {
  name
}
    `
export const ProductCategoryFragmentDoc = gql`
    fragment ProductCategory on Category {
  id
  translations {
    en {
      ...ProductCategoryTranslation
    }
    nl {
      ...ProductCategoryTranslation
    }
    fr {
      ...ProductCategoryTranslation
    }
    de {
      ...ProductCategoryTranslation
    }
  }
}
    ${ProductCategoryTranslationFragmentDoc}`
export const VariantTranslationFragmentDoc = gql`
    fragment VariantTranslation on VariantTranslation {
  slug
  label
}
    `
export const VariantTranslationMapFragmentDoc = gql`
    fragment VariantTranslationMap on VariantTranslationMap {
  en {
    ...VariantTranslation
  }
  nl {
    ...VariantTranslation
  }
  fr {
    ...VariantTranslation
  }
  de {
    ...VariantTranslation
  }
}
    ${VariantTranslationFragmentDoc}`
export const VariantFragmentDoc = gql`
    fragment Variant on Variant {
  current
  presentation
  translations {
    ...VariantTranslationMap
  }
}
    ${VariantTranslationMapFragmentDoc}`
export const VariantsFragmentDoc = gql`
    fragment Variants on Variants {
  colors {
    ...Variant
  }
  types {
    ...Variant
  }
  materials {
    ...Variant
  }
}
    ${VariantFragmentDoc}`
export const ColorFragmentDoc = gql`
    fragment Color on Color {
  __typename
  presentation
  translations {
    en
    nl
    de
    fr
  }
}
    `
export const ProductTranslationsMapFragmentDoc = gql`
    fragment ProductTranslationsMap on ProductTranslationMap {
  en {
    ...ProductTranslation
  }
  fr {
    ...ProductTranslation
  }
  de {
    ...ProductTranslation
  }
  nl {
    ...ProductTranslation
  }
}
    ${ProductTranslationFragmentDoc}`
export const ProductFragmentDoc = gql`
    fragment Product on Product {
  ean
  status
  codeLocal
  brand {
    name
  }
  suggestedRetailPrice
  suggestedRetailPriceIse
  suggestedStandardRetailPrice
  suggestedStandardRetailPriceIse
  orderProperties {
    deliveryInfo {
      deliverableInWeeks
    }
    productOrderStepDropshipment
    productOrderSteps
  }
  totalPackages
  packages {
    ...Packages
  }
  statusTranslations {
    ...StatusTranslationMap
  }
  country
  group
  range
  downloads {
    ...ProductDownload
  }
  dimensions {
    ...Dimension
  }
  categories {
    ...ProductCategory
  }
  images {
    ...ProductImage
  }
  variants {
    ...Variants
  }
  subColors {
    ...Color
  }
  subMaterials {
    nl
    de
    fr
    en
  }
  colors {
    ...Color
  }
  materials {
    nl
    de
    fr
    en
  }
  videos
  translations {
    ...ProductTranslationsMap
  }
  orderProperties {
    productOrderSteps
    productOrderStepDropshipment
  }
  fsc
  __typename
}
    ${PackagesFragmentDoc}
${StatusTranslationMapFragmentDoc}
${ProductDownloadFragmentDoc}
${DimensionFragmentDoc}
${ProductCategoryFragmentDoc}
${ProductImageFragmentDoc}
${VariantsFragmentDoc}
${ColorFragmentDoc}
${ProductTranslationsMapFragmentDoc}`
export const OrderAdditionFragmentDoc = gql`
    fragment OrderAddition on OrderAddition {
  name
  img
  codeLocal
  description
  __typename
}
    `
export const ErrorTypeFragmentDoc = gql`
    fragment ErrorType on ErrorType {
  error
  __typename
}
    `
export const OrderLineFragmentDoc = gql`
    fragment OrderLine on OrderLine {
  item {
    ... on Product {
      ...Product
    }
    ... on OrderAddition {
      ...OrderAddition
    }
    ... on ErrorType {
      ...ErrorType
    }
    __typename
  }
  quantity
  priceExclVat
  priceIncVat
  delivered
  deliveryDate
}
    ${ProductFragmentDoc}
${OrderAdditionFragmentDoc}
${ErrorTypeFragmentDoc}`
export const OrderFragmentDoc = gql`
    fragment Order on Order {
  address {
    ...DebtorAddress
  }
  orderId
  orderNumber
  debtorId
  dropShipment
  service
  status
  totalExclVat
  totalIncVat
  orderDate
  reference
  orderLines {
    ...OrderLine
  }
}
    ${DebtorAddressFragmentDoc}
${OrderLineFragmentDoc}`
export const OrderNumbersAndStatusFragmentDoc = gql`
    fragment OrderNumbersAndStatus on Order {
  orderNumber
  status
}
    `
export const PageFragmentDoc = gql`
    fragment Page on Page {
  title
  id
  slug
  locale
  localizations {
    ...Localization
  }
  seo {
    ...Seo
  }
  components {
    ...Components
  }
}
    ${LocalizationFragmentDoc}
${SeoFragmentDoc}
${ComponentsFragmentDoc}`
export const PositionedPageFragmentDoc = gql`
    fragment PositionedPage on PositionedPages {
  identifier
  localizations {
    title
    locale
    slug
  }
}
    `
export const ContentCategoryTranslationMapFragmentDoc = gql`
    fragment ContentCategoryTranslationMap on CategoryTranslationsMap {
  en {
    ...ContentCategoryTranslation
  }
  fr {
    ...ContentCategoryTranslation
  }
  de {
    ...ContentCategoryTranslation
  }
  nl {
    ...ContentCategoryTranslation
  }
}
    ${ContentCategoryTranslationFragmentDoc}`
export const PaginatorInfoFragmentDoc = gql`
    fragment PaginatorInfo on PaginatorInfo {
  count
  currentPage
  firstItem
  hasMorePages
  lastItem
  lastPage
  perPage
  total
}
    `
export const ProductSlugsFragmentDoc = gql`
    fragment ProductSlugs on ProductSlug {
  en
  nl
  de
  fr
  hash
  ean
}
    `
export const ProductPriceFragmentDoc = gql`
    fragment ProductPrice on ProductPrice {
  ean
  price
  standardPrice
}
    `
export const ProductOrderPropertiesFragmentDoc = gql`
    fragment ProductOrderProperties on Product {
  ean
  stock {
    ...ProductStock
  }
  price {
    ...ProductPrice
  }
}
    ${ProductStockFragmentDoc}
${ProductPriceFragmentDoc}`
export const RegisterResponseFragmentDoc = gql`
    fragment RegisterResponse on DefaultResponse {
  message
  status
  reason
}
    `
export const DefaultResponseFragmentDoc = gql`
    fragment DefaultResponse on DefaultResponse {
  message
  status
  reason
}
    `
export const SimpleProductTranslationFragmentDoc = gql`
    fragment SimpleProductTranslation on ProductTranslation {
  slug
  name
  shortDescription
  fullDescription
}
    `
export const SimpleProductFragmentDoc = gql`
    fragment SimpleProduct on Product {
  katanaId
  ean
  codeLocal
  images {
    ...ProductImage
  }
  range
  orderProperties {
    deliveryInfo {
      deliverableInWeeks
    }
    productOrderStepDropshipment
    productOrderSteps
  }
  statusTranslations {
    ...StatusTranslationMap
  }
  status
  dimensions {
    ...Dimension
  }
  categories {
    ...ProductCategory
  }
  stock {
    quantity
    ean
    articleCode
  }
  brand {
    name
  }
  suggestedRetailPrice
  suggestedRetailPriceIse
  suggestedStandardRetailPrice
  suggestedStandardRetailPriceIse
  translations {
    en {
      ...SimpleProductTranslation
    }
    de {
      ...SimpleProductTranslation
    }
    fr {
      ...SimpleProductTranslation
    }
    nl {
      ...SimpleProductTranslation
    }
  }
  fsc
  subColors {
    ...Color
  }
  subMaterials {
    nl
    de
    fr
    en
  }
  colors {
    ...Color
  }
  materials {
    nl
    de
    fr
    en
  }
}
    ${ProductImageFragmentDoc}
${StatusTranslationMapFragmentDoc}
${DimensionFragmentDoc}
${ProductCategoryFragmentDoc}
${SimpleProductTranslationFragmentDoc}
${ColorFragmentDoc}`
export const SaleStatisticFragmentDoc = gql`
    fragment SaleStatistic on SaleStatistic {
  product {
    ...SimpleProduct
  }
  totalItems
  totalAmount
}
    ${SimpleProductFragmentDoc}`
export const SaleStatisticsFragmentDoc = gql`
    fragment SaleStatistics on SaleStatistics {
  debtorId
  date
  type
  period
  statistics {
    ...SaleStatistic
  }
}
    ${SaleStatisticFragmentDoc}`
export const ShoppingCartProductTranslationFragmentDoc = gql`
    fragment ShoppingCartProductTranslation on ProductTranslation {
  slug
  name
  fullDescription
}
    `
export const ShoppingCartProductTranslationsMapFragmentDoc = gql`
    fragment ShoppingCartProductTranslationsMap on ProductTranslationMap {
  en {
    ...ShoppingCartProductTranslation
  }
  fr {
    ...ShoppingCartProductTranslation
  }
  de {
    ...ShoppingCartProductTranslation
  }
  nl {
    ...ShoppingCartProductTranslation
  }
}
    ${ShoppingCartProductTranslationFragmentDoc}`
export const ShoppingCartProductFragmentDoc = gql`
    fragment ShoppingCartProduct on Product {
  ean
  codeLocal
  katanaId
  stock {
    quantity
  }
  brand {
    name
  }
  status
  parentCategory {
    translations {
      en {
        name
      }
      de {
        name
      }
      fr {
        name
      }
      nl {
        name
      }
    }
  }
  categories {
    translations {
      en {
        name
      }
      de {
        name
      }
      fr {
        name
      }
      nl {
        name
      }
    }
  }
  images {
    ...ProductImage
  }
  translations {
    ...ShoppingCartProductTranslationsMap
  }
  price {
    ...ProductPrice
  }
  orderProperties {
    salesUnit
    productOrderSteps
    productOrderStepDropshipment
    deliveryInfo {
      deliverableInWeeks
      weekNumber
      weekYear
    }
  }
}
    ${ProductImageFragmentDoc}
${ShoppingCartProductTranslationsMapFragmentDoc}
${ProductPriceFragmentDoc}`
export const ShoppingCartLineFragmentDoc = gql`
    fragment ShoppingCartLine on ShoppingCartLine {
  id
  quantity
  subTotal
  product {
    ...ShoppingCartProduct
  }
  price
}
    ${ShoppingCartProductFragmentDoc}`
export const ShoppingCartFragmentDoc = gql`
    fragment shoppingCart on ShoppingCart {
  totalItems
  total
  cartLine {
    ...ShoppingCartLine
  }
}
    ${ShoppingCartLineFragmentDoc}`
export const SidebarUniqueSellingPointFragmentDoc = gql`
    fragment SidebarUniqueSellingPoint on SidebarUniqueSellingPoint {
  icon
  title
}
    `
export const TranslationsMapsFragmentDoc = gql`
    fragment TranslationsMaps on TranslationsMap {
  en {
    ...Localization
  }
  nl {
    ...Localization
  }
  de {
    ...Localization
  }
  fr {
    ...Localization
  }
}
    ${LocalizationFragmentDoc}`
export const ThemeFragmentDoc = gql`
    fragment Theme on Theme {
  id
  name
  translations {
    ...TranslationsMaps
  }
  seo {
    ...Seo
  }
  components {
    ...Components
  }
}
    ${TranslationsMapsFragmentDoc}
${SeoFragmentDoc}
${ComponentsFragmentDoc}`
export const SimpleThemeFragmentDoc = gql`
    fragment SimpleTheme on Theme {
  id
  name
  translations {
    ...TranslationsMaps
  }
  highlightedProducts {
    ...HighlightedProduct
  }
}
    ${TranslationsMapsFragmentDoc}
${HighlightedProductFragmentDoc}`
export const MinimalWishlistItemFragmentDoc = gql`
    fragment MinimalWishlistItem on WishlistItem {
  product {
    ean
  }
}
    `
export const AccountManagerDocument = gql`
    query accountManager {
  debtor {
    debtorCode
    accountManager {
      ...AccountManagerData
    }
  }
}
    ${AccountManagerDataFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class AccountManagerQueryService extends Apollo.Query<AccountManagerQuery, AccountManagerQueryVariables> {
    override document = AccountManagerDocument as Apollo.TypedDocumentNode<AccountManagerQuery, AccountManagerQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AssortmentsDocument = gql`
    query Assortments($filterBy: AssortmentFilterByInput!, $orderBy: AssortmentOrderByInput!, $first: Int!, $page: Int) {
  assortments(filterBy: $filterBy, orderBy: $orderBy, first: $first, page: $page) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...Assortment
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${AssortmentFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class AssortmentsQueryService extends Apollo.Query<AssortmentsQuery, AssortmentsQueryVariables> {
    override document = AssortmentsDocument as Apollo.TypedDocumentNode<AssortmentsQuery, AssortmentsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MinimalAssortmentsDocument = gql`
    query MinimalAssortments($filterBy: AssortmentFilterByInput!, $orderBy: AssortmentOrderByInput!, $first: Int!, $page: Int) {
  assortments(filterBy: $filterBy, orderBy: $orderBy, first: $first, page: $page) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...MinimalAssortments
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${MinimalAssortmentsFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class MinimalAssortmentsQueryService extends Apollo.Query<MinimalAssortmentsQuery, MinimalAssortmentsQueryVariables> {
    override document = MinimalAssortmentsDocument as Apollo.TypedDocumentNode<MinimalAssortmentsQuery, MinimalAssortmentsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AssortmentEansDocument = gql`
    query assortmentEans($filterBy: AssortmentFilterByInput!) {
  assortments(
    filterBy: $filterBy
    orderBy: {productName: desc}
    page: 1
    first: 1000
  ) {
    data {
      ean
    }
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class AssortmentEansQueryService extends Apollo.Query<AssortmentEansQuery, AssortmentEansQueryVariables> {
    override document = AssortmentEansDocument as Apollo.TypedDocumentNode<AssortmentEansQuery, AssortmentEansQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AddToAssortmentDocument = gql`
    mutation addToAssortment($eanCodes: [String!]!) {
  addToAssortment(eanCodes: $eanCodes) {
    message
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class AddToAssortmentMutationService extends Apollo.Mutation<AddToAssortmentMutation, AddToAssortmentMutationVariables> {
    override document = AddToAssortmentDocument as Apollo.TypedDocumentNode<AddToAssortmentMutation, AddToAssortmentMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveFromAssortmentDocument = gql`
    mutation removeFromAssortment($eanCodes: [String!]!) {
  removeFromAssortment(eanCodes: $eanCodes) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RemoveFromAssortmentMutationService extends Apollo.Mutation<RemoveFromAssortmentMutation, RemoveFromAssortmentMutationVariables> {
    override document = RemoveFromAssortmentDocument as Apollo.TypedDocumentNode<RemoveFromAssortmentMutation, RemoveFromAssortmentMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const BrandsDocument = gql`
    query brands {
  brands {
    ...Brand
  }
}
    ${BrandFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class BrandsQueryService extends Apollo.Query<BrandsQuery, BrandsQueryVariables> {
    override document = BrandsDocument as Apollo.TypedDocumentNode<BrandsQuery, BrandsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const BrandDocument = gql`
    query brand($slug: String!) {
  brand(slug: $slug) {
    ...CompleteBrand
  }
}
    ${CompleteBrandFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class BrandQueryService extends Apollo.Query<BrandQuery, BrandQueryVariables> {
    override document = BrandDocument as Apollo.TypedDocumentNode<BrandQuery, BrandQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CategoriesDocument = gql`
    query categories {
  categories {
    ...Category
  }
}
    ${CategoryFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CategoriesQueryService extends Apollo.Query<CategoriesQuery, CategoriesQueryVariables> {
    override document = CategoriesDocument as Apollo.TypedDocumentNode<CategoriesQuery, CategoriesQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CategoryDocument = gql`
    query category($slug: String!, $locale: LocalesEnum!) {
  category(slug: $slug, locale: $locale) {
    ...IndividualCategory
  }
}
    ${IndividualCategoryFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CategoryQueryService extends Apollo.Query<CategoryQuery, CategoryQueryVariables> {
    override document = CategoryDocument as Apollo.TypedDocumentNode<CategoryQuery, CategoryQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateOrderDocument = gql`
    mutation createOrder($input: OrderInput!) {
  createOrder(input: $input) {
    message
    reason
    status
    __typename
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class CreateOrderMutationService extends Apollo.Mutation<CreateOrderMutation, CreateOrderMutationVariables> {
    override document = CreateOrderDocument as Apollo.TypedDocumentNode<CreateOrderMutation, CreateOrderMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CreateDropshipmentOrderDocument = gql`
    mutation createDropshipmentOrder($input: OrderDropshipmentInput!) {
  CreateDropshipmentOrder(input: $input) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CreateDropshipmentOrderMutationService extends Apollo.Mutation<CreateDropshipmentOrderMutation, CreateDropshipmentOrderMutationVariables> {
    override document = CreateDropshipmentOrderDocument as Apollo.TypedDocumentNode<CreateDropshipmentOrderMutation, CreateDropshipmentOrderMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SendContactFormDocument = gql`
    mutation sendContactForm($input: ContactFormInput!) {
  sendContactForm(input: $input) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class SendContactFormMutationService extends Apollo.Mutation<SendContactFormMutation, SendContactFormMutationVariables> {
    override document = SendContactFormDocument as Apollo.TypedDocumentNode<SendContactFormMutation, SendContactFormMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SendDataRequestFormDocument = gql`
    mutation sendDataRequestForm($input: DataRequestInput!) {
  sendDataRequestForm(input: $input) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class SendDataRequestFormMutationService extends Apollo.Mutation<SendDataRequestFormMutation, SendDataRequestFormMutationVariables> {
    override document = SendDataRequestFormDocument as Apollo.TypedDocumentNode<SendDataRequestFormMutation, SendDataRequestFormMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateFeedOptionsDocument = gql`
    mutation updateFeedOptions($products: FeedOptions!, $stocks: FeedOptions!) {
  updateFeedOptions(products: $products, stocks: $stocks) {
    status
    reason
    message
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdateFeedOptionsMutationService extends Apollo.Mutation<UpdateFeedOptionsMutation, UpdateFeedOptionsMutationVariables> {
    override document = UpdateFeedOptionsDocument as Apollo.TypedDocumentNode<UpdateFeedOptionsMutation, UpdateFeedOptionsMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DataFeedsDocument = gql`
    query DataFeeds {
  me {
    ...DataFeed
  }
}
    ${DataFeedFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class DataFeedsQueryService extends Apollo.Query<DataFeedsQuery, DataFeedsQueryVariables> {
    override document = DataFeedsDocument as Apollo.TypedDocumentNode<DataFeedsQuery, DataFeedsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DebtorDocument = gql`
    query debtor {
  debtor {
    ...Debtor
  }
}
    ${DebtorFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class DebtorQueryService extends Apollo.Query<DebtorQuery, DebtorQueryVariables> {
    override document = DebtorDocument as Apollo.TypedDocumentNode<DebtorQuery, DebtorQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const CompleteDebtorDocument = gql`
    query completeDebtor {
  debtor {
    ...CompleteDebtor
  }
}
    ${CompleteDebtorFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class CompleteDebtorQueryService extends Apollo.Query<CompleteDebtorQuery, CompleteDebtorQueryVariables> {
    override document = CompleteDebtorDocument as Apollo.TypedDocumentNode<CompleteDebtorQuery, CompleteDebtorQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DeleteAccountDocument = gql`
    mutation deleteAccount {
  deleteAccount {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class DeleteAccountMutationService extends Apollo.Mutation<DeleteAccountMutation, DeleteAccountMutationVariables> {
    override document = DeleteAccountDocument as Apollo.TypedDocumentNode<DeleteAccountMutation, DeleteAccountMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DownloadRequestedDocument = gql`
    mutation downloadRequested($input: DownloadInput) {
  downloadRequested(input: $input)
}
    `

@Injectable({
    providedIn: 'root',
})
export class DownloadRequestedMutationService extends Apollo.Mutation<DownloadRequestedMutation, DownloadRequestedMutationVariables> {
    override document = DownloadRequestedDocument as Apollo.TypedDocumentNode<DownloadRequestedMutation, DownloadRequestedMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DownloadProgressDocument = gql`
    subscription downloadProgress($debtorId: String!, $downloadId: String!) {
  downloadProgressed(input: {debtorId: $debtorId, downloadId: $downloadId}) {
    ...DownloadProgress
  }
}
    ${DownloadProgressFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class DownloadProgressSubscriptionService extends Apollo.Subscription<DownloadProgressSubscription, DownloadProgressSubscriptionVariables> {
    override document = DownloadProgressDocument as Apollo.TypedDocumentNode<DownloadProgressSubscription, DownloadProgressSubscriptionVariables>
    override client = 'subscription'
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EnhanceDebtorDocument = gql`
    mutation EnhanceDebtor($input: EnhanceDebtorInput!) {
  enhanceDebtor(input: $input) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class EnhanceDebtorMutationService extends Apollo.Mutation<EnhanceDebtorMutation, EnhanceDebtorMutationVariables> {
    override document = EnhanceDebtorDocument as Apollo.TypedDocumentNode<EnhanceDebtorMutation, EnhanceDebtorMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EventsDocument = gql`
    query Events($first: Int!, $page: Int!, $locale: LocalesEnum!, $eventsCategoryEnum: EventsCategoryEnum!) {
  events(
    first: $first
    page: $page
    locale: $locale
    category: $eventsCategoryEnum
  ) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...SimpleEvent
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${SimpleEventFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class EventsQueryService extends Apollo.Query<EventsQuery, EventsQueryVariables> {
    override document = EventsDocument as Apollo.TypedDocumentNode<EventsQuery, EventsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const EventDocument = gql`
    query Event($slug: String!, $locale: LocalesEnum!) {
  event(slug: $slug, locale: $locale) {
    ...Event
  }
}
    ${EventFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class EventQueryService extends Apollo.Query<EventQuery, EventQueryVariables> {
    override document = EventDocument as Apollo.TypedDocumentNode<EventQuery, EventQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($input: ForgotPasswordInput!) {
  forgotPassword(input: $input) {
    status
    message
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class ForgotPasswordMutationService extends Apollo.Mutation<ForgotPasswordMutation, ForgotPasswordMutationVariables> {
    override document = ForgotPasswordDocument as Apollo.TypedDocumentNode<ForgotPasswordMutation, ForgotPasswordMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RegisterNewsLetterSubscriptionDocument = gql`
    mutation registerNewsLetterSubscription($input: RegisterNewsLetterSubscriptionInput!) {
  registerNewsLetterSubscription(input: $input) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RegisterNewsLetterSubscriptionMutationService extends Apollo.Mutation<RegisterNewsLetterSubscriptionMutation, RegisterNewsLetterSubscriptionMutationVariables> {
    override document = RegisterNewsLetterSubscriptionDocument as Apollo.TypedDocumentNode<RegisterNewsLetterSubscriptionMutation, RegisterNewsLetterSubscriptionMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const HealthCheckDocument = gql`
    query healthCheck {
  healthCheck
}
    `

@Injectable({
    providedIn: 'root',
})
export class HealthCheckQueryService extends Apollo.Query<HealthCheckQuery, HealthCheckQueryVariables> {
    override document = HealthCheckDocument as Apollo.TypedDocumentNode<HealthCheckQuery, HealthCheckQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class MeQueryService extends Apollo.Query<MeQuery, MeQueryVariables> {
    override document = MeDocument as Apollo.TypedDocumentNode<MeQuery, MeQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const NewsItemsDocument = gql`
    query NewsItems($first: Int!, $page: Int!, $locale: LocalesEnum!, $newsCategoryEnum: NewsCategoryEnum!) {
  newsItems(
    first: $first
    page: $page
    locale: $locale
    category: $newsCategoryEnum
  ) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...SimpleNewsItem
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${SimpleNewsItemFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class NewsItemsQueryService extends Apollo.Query<NewsItemsQuery, NewsItemsQueryVariables> {
    override document = NewsItemsDocument as Apollo.TypedDocumentNode<NewsItemsQuery, NewsItemsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const NewsItemDocument = gql`
    query NewsItem($slug: String!, $locale: LocalesEnum!) {
  newsItem(slug: $slug, locale: $locale) {
    ...News
  }
}
    ${NewsFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class NewsItemQueryService extends Apollo.Query<NewsItemQuery, NewsItemQueryVariables> {
    override document = NewsItemDocument as Apollo.TypedDocumentNode<NewsItemQuery, NewsItemQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SimpleOrdersDocument = gql`
    query simpleOrders($first: Int!, $page: Int, $dateOrder: SortEnum!, $nextDelivery: SortEnum!, $orderState: OrderStateEnum!, $orderType: OrderTypeEnum!) {
  orders(
    first: $first
    page: $page
    dateOrder: $dateOrder
    nextDelivery: $nextDelivery
    orderState: $orderState
    orderType: $orderType
  ) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...SimpleOrder
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${SimpleOrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class SimpleOrdersQueryService extends Apollo.Query<SimpleOrdersQuery, SimpleOrdersQueryVariables> {
    override document = SimpleOrdersDocument as Apollo.TypedDocumentNode<SimpleOrdersQuery, SimpleOrdersQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OrderNumbersAndStatusDocument = gql`
    query orderNumbersAndStatus($orderState: OrderStateEnum!, $orderType: OrderTypeEnum!) {
  orders(
    first: 1000
    page: 1
    dateOrder: ASCENDING
    nextDelivery: ASCENDING
    orderType: $orderType
    orderState: $orderState
  ) {
    data {
      ...OrderNumbersAndStatus
    }
  }
}
    ${OrderNumbersAndStatusFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class OrderNumbersAndStatusQueryService extends Apollo.Query<OrderNumbersAndStatusQuery, OrderNumbersAndStatusQueryVariables> {
    override document = OrderNumbersAndStatusDocument as Apollo.TypedDocumentNode<OrderNumbersAndStatusQuery, OrderNumbersAndStatusQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OrderDocument = gql`
    query order($input: String!) {
  order(orderId: $input) {
    ...Order
  }
}
    ${OrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class OrderQueryService extends Apollo.Query<OrderQuery, OrderQueryVariables> {
    override document = OrderDocument as Apollo.TypedDocumentNode<OrderQuery, OrderQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const OrdersByReferenceAndIdDocument = gql`
    query ordersByReferenceAndId($search: String!, $first: Int!, $page: Int, $dateOrder: SortEnum!, $nextDelivery: SortEnum!, $orderType: OrderTypeEnum!, $orderState: OrderStateEnum!) {
  ordersSearch(
    search: $search
    first: $first
    page: $page
    dateOrder: $dateOrder
    nextDelivery: $nextDelivery
    orderType: $orderType
    orderState: $orderState
  ) {
    paginatorInfo {
      ...PaginatorInfo
    }
    data {
      ...Order
    }
  }
}
    ${PaginatorInfoFragmentDoc}
${OrderFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class OrdersByReferenceAndIdQueryService extends Apollo.Query<OrdersByReferenceAndIdQuery, OrdersByReferenceAndIdQueryVariables> {
    override document = OrdersByReferenceAndIdDocument as Apollo.TypedDocumentNode<OrdersByReferenceAndIdQuery, OrdersByReferenceAndIdQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const IsOrderReferenceAvailableDocument = gql`
    query isOrderReferenceAvailable($input: String!) {
  orderCheckIfReferenceIsAvailable(reference: $input)
}
    `

@Injectable({
    providedIn: 'root',
})
export class IsOrderReferenceAvailableQueryService extends Apollo.Query<IsOrderReferenceAvailableQuery, IsOrderReferenceAvailableQueryVariables> {
    override document = IsOrderReferenceAvailableDocument as Apollo.TypedDocumentNode<IsOrderReferenceAvailableQuery, IsOrderReferenceAvailableQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PageDocument = gql`
    query page($slug: String!, $locale: LocalesEnum!) {
  page(slug: $slug, locale: $locale) {
    ...Page
  }
}
    ${PageFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class PageQueryService extends Apollo.Query<PageQuery, PageQueryVariables> {
    override document = PageDocument as Apollo.TypedDocumentNode<PageQuery, PageQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const PositionedPagesDocument = gql`
    query positionedPages($positionedIdentifier: [PositionedPageEnum!]!) {
  positionedPages(positionedIdentifier: $positionedIdentifier) {
    ...PositionedPage
  }
}
    ${PositionedPageFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class PositionedPagesQueryService extends Apollo.Query<PositionedPagesQuery, PositionedPagesQueryVariables> {
    override document = PositionedPagesDocument as Apollo.TypedDocumentNode<PositionedPagesQuery, PositionedPagesQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ProductDocument = gql`
    query product($ean: String!) {
  product(ean: $ean) {
    ...Product
  }
}
    ${ProductFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ProductQueryService extends Apollo.Query<ProductQuery, ProductQueryVariables> {
    override document = ProductDocument as Apollo.TypedDocumentNode<ProductQuery, ProductQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ProductSlugsDocument = gql`
    query productSlugs {
  productSlugs {
    ...ProductSlugs
  }
}
    ${ProductSlugsFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ProductSlugsQueryService extends Apollo.Query<ProductSlugsQuery, ProductSlugsQueryVariables> {
    override document = ProductSlugsDocument as Apollo.TypedDocumentNode<ProductSlugsQuery, ProductSlugsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ProductBySlugDocument = gql`
    query productBySlug($slug: String!) {
  productBySlug(slug: $slug) {
    ...Product
  }
}
    ${ProductFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ProductBySlugQueryService extends Apollo.Query<ProductBySlugQuery, ProductBySlugQueryVariables> {
    override document = ProductBySlugDocument as Apollo.TypedDocumentNode<ProductBySlugQuery, ProductBySlugQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ProductsByEansDocument = gql`
    query ProductsByEans($eans: [String!]!) {
  productsByEan(eans: $eans) {
    ...Product
  }
}
    ${ProductFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ProductsByEansQueryService extends Apollo.Query<ProductsByEansQuery, ProductsByEansQueryVariables> {
    override document = ProductsByEansDocument as Apollo.TypedDocumentNode<ProductsByEansQuery, ProductsByEansQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SimpleProductsByEanDocument = gql`
    query simpleProductsByEan($eans: [String!]!) {
  productsByEan(eans: $eans) {
    ...SimpleProduct
  }
}
    ${SimpleProductFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class SimpleProductsByEanQueryService extends Apollo.Query<SimpleProductsByEanQuery, SimpleProductsByEanQueryVariables> {
    override document = SimpleProductsByEanDocument as Apollo.TypedDocumentNode<SimpleProductsByEanQuery, SimpleProductsByEanQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const HighlightedProductsDocument = gql`
    query highlightedProducts {
  recommendedProducts(first: 12, page: 1) {
    data {
      ...SimpleProduct
    }
    paginatorInfo {
      ...PaginatorInfo
    }
  }
}
    ${SimpleProductFragmentDoc}
${PaginatorInfoFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class HighlightedProductsQueryService extends Apollo.Query<HighlightedProductsQuery, HighlightedProductsQueryVariables> {
    override document = HighlightedProductsDocument as Apollo.TypedDocumentNode<HighlightedProductsQuery, HighlightedProductsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ProductsOrderPropertiesDocument = gql`
    query productsOrderProperties($eans: [String!]!) {
  productsByEan(eans: $eans) {
    ...ProductOrderProperties
  }
}
    ${ProductOrderPropertiesFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ProductsOrderPropertiesQueryService extends Apollo.Query<ProductsOrderPropertiesQuery, ProductsOrderPropertiesQueryVariables> {
    override document = ProductsOrderPropertiesDocument as Apollo.TypedDocumentNode<ProductsOrderPropertiesQuery, ProductsOrderPropertiesQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RegisterNewDebtorDocument = gql`
    mutation registerNewDebtor($input: RegisterNewDebtorInput!) {
  registerNewDebtor(input: $input) {
    ...RegisterResponse
  }
}
    ${RegisterResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RegisterNewDebtorMutationService extends Apollo.Mutation<RegisterNewDebtorMutation, RegisterNewDebtorMutationVariables> {
    override document = RegisterNewDebtorDocument as Apollo.TypedDocumentNode<RegisterNewDebtorMutation, RegisterNewDebtorMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SaleStatisticsDocument = gql`
    query saleStatistics($type: StatisticTypeEnum!, $timeSpan: TimePeriodEnum!) {
  saleStatistics(type: $type, timeSpan: $timeSpan) {
    ...SaleStatistics
  }
}
    ${SaleStatisticsFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class SaleStatisticsQueryService extends Apollo.Query<SaleStatisticsQuery, SaleStatisticsQueryVariables> {
    override document = SaleStatisticsDocument as Apollo.TypedDocumentNode<SaleStatisticsQuery, SaleStatisticsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ServerPreloadDocument = gql`
    query serverPreload($positionedIdentifier: [PositionedPageEnum!]!) {
  categories {
    ...Category
  }
  brands {
    ...Brand
  }
  themesEN: themes(locale: en, published: IS_PUBLISHED) {
    ...Theme
  }
  themesNL: themes(locale: nl, published: IS_PUBLISHED) {
    ...Theme
  }
  positionedPages(positionedIdentifier: $positionedIdentifier) {
    ...PositionedPage
  }
}
    ${CategoryFragmentDoc}
${BrandFragmentDoc}
${ThemeFragmentDoc}
${PositionedPageFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ServerPreloadQueryService extends Apollo.Query<ServerPreloadQuery, ServerPreloadQueryVariables> {
    override document = ServerPreloadDocument as Apollo.TypedDocumentNode<ServerPreloadQuery, ServerPreloadQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ShoppingCartDocument = gql`
    query shoppingCart($name: String!) {
  shoppingCart(name: $name) {
    ...shoppingCart
  }
}
    ${ShoppingCartFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartQueryService extends Apollo.Query<ShoppingCartQuery, ShoppingCartQueryVariables> {
    override document = ShoppingCartDocument as Apollo.TypedDocumentNode<ShoppingCartQuery, ShoppingCartQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ShoppingCartTotalItemsDocument = gql`
    query shoppingCartTotalItems($name: String!) {
  shoppingCart(name: $name) {
    totalItems
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartTotalItemsQueryService extends Apollo.Query<ShoppingCartTotalItemsQuery, ShoppingCartTotalItemsQueryVariables> {
    override document = ShoppingCartTotalItemsDocument as Apollo.TypedDocumentNode<ShoppingCartTotalItemsQuery, ShoppingCartTotalItemsQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AddToShoppingCartDocument = gql`
    mutation addToShoppingCart($input: ShoppingCartInput!) {
  addToShoppingCart(input: $input) {
    ...shoppingCart
  }
}
    ${ShoppingCartFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class AddToShoppingCartMutationService extends Apollo.Mutation<AddToShoppingCartMutation, AddToShoppingCartMutationVariables> {
    override document = AddToShoppingCartDocument as Apollo.TypedDocumentNode<AddToShoppingCartMutation, AddToShoppingCartMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateShoppingCartDocument = gql`
    mutation updateShoppingCart($input: ShoppingCartInput!) {
  updateShoppingCart(input: $input) {
    ...shoppingCart
  }
}
    ${ShoppingCartFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class UpdateShoppingCartMutationService extends Apollo.Mutation<UpdateShoppingCartMutation, UpdateShoppingCartMutationVariables> {
    override document = UpdateShoppingCartDocument as Apollo.TypedDocumentNode<UpdateShoppingCartMutation, UpdateShoppingCartMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DeleteShoppingCartDocument = gql`
    mutation deleteShoppingCart($name: String!) {
  deleteShoppingCart(name: $name) {
    status
    reason
    message
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class DeleteShoppingCartMutationService extends Apollo.Mutation<DeleteShoppingCartMutation, DeleteShoppingCartMutationVariables> {
    override document = DeleteShoppingCartDocument as Apollo.TypedDocumentNode<DeleteShoppingCartMutation, DeleteShoppingCartMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const DeleteFromShoppingCartDocument = gql`
    mutation deleteFromShoppingCart($name: String!, $eans: [String!]!) {
  deleteFromShoppingCart(name: $name, eans: $eans) {
    ...shoppingCart
  }
}
    ${ShoppingCartFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class DeleteFromShoppingCartMutationService extends Apollo.Mutation<DeleteFromShoppingCartMutation, DeleteFromShoppingCartMutationVariables> {
    override document = DeleteFromShoppingCartDocument as Apollo.TypedDocumentNode<DeleteFromShoppingCartMutation, DeleteFromShoppingCartMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const SidebarDocument = gql`
    query sidebar($locale: String!) {
  sidebar(locale: $locale) {
    uniqueSellingPoints {
      ...SidebarUniqueSellingPoint
    }
    image {
      ...Image
    }
  }
}
    ${SidebarUniqueSellingPointFragmentDoc}
${ImageFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class SidebarQueryService extends Apollo.Query<SidebarQuery, SidebarQueryVariables> {
    override document = SidebarDocument as Apollo.TypedDocumentNode<SidebarQuery, SidebarQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ThemesDocument = gql`
    query themes($locale: LocalesEnum!, $published: PublishedEnum!) {
  themes(locale: $locale, published: $published) {
    ...SimpleTheme
  }
}
    ${SimpleThemeFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ThemesQueryService extends Apollo.Query<ThemesQuery, ThemesQueryVariables> {
    override document = ThemesDocument as Apollo.TypedDocumentNode<ThemesQuery, ThemesQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const ThemeDocument = gql`
    query theme($locale: LocalesEnum!, $slug: String!) {
  theme(slug: $slug, locale: $locale) {
    ...Theme
  }
}
    ${ThemeFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class ThemeQueryService extends Apollo.Query<ThemeQuery, ThemeQueryVariables> {
    override document = ThemeDocument as Apollo.TypedDocumentNode<ThemeQuery, ThemeQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdatePasswordDocument = gql`
    mutation updatePassword($input: UpdatePassword!) {
  updatePassword(input: $input) {
    status
    message
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdatePasswordMutationService extends Apollo.Mutation<UpdatePasswordMutation, UpdatePasswordMutationVariables> {
    override document = UpdatePasswordDocument as Apollo.TypedDocumentNode<UpdatePasswordMutation, UpdatePasswordMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateForgottenPasswordDocument = gql`
    mutation updateForgottenPassword($input: NewPasswordWithCodeInput) {
  updateForgottenPassword(input: $input) {
    status
    message
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdateForgottenPasswordMutationService extends Apollo.Mutation<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables> {
    override document = UpdateForgottenPasswordDocument as Apollo.TypedDocumentNode<UpdateForgottenPasswordMutation, UpdateForgottenPasswordMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const UpdateProfileDocument = gql`
    mutation updateProfile($preferredLanguage: String!, $splitOrderByDefault: Boolean!, $hidePurchasePrice: Boolean!) {
  updateProfile(
    preferredLanguage: $preferredLanguage
    splitOrderByDefault: $splitOrderByDefault
    hidePurchasePrice: $hidePurchasePrice
  ) {
    message
    status
    reason
  }
}
    `

@Injectable({
    providedIn: 'root',
})
export class UpdateProfileMutationService extends Apollo.Mutation<UpdateProfileMutation, UpdateProfileMutationVariables> {
    override document = UpdateProfileDocument as Apollo.TypedDocumentNode<UpdateProfileMutation, UpdateProfileMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const AddToWishlistDocument = gql`
    mutation addToWishlist($eanCodes: [String!]!) {
  addToWishlist(eanCodes: $eanCodes) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class AddToWishlistMutationService extends Apollo.Mutation<AddToWishlistMutation, AddToWishlistMutationVariables> {
    override document = AddToWishlistDocument as Apollo.TypedDocumentNode<AddToWishlistMutation, AddToWishlistMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const RemoveFromWishlistDocument = gql`
    mutation removeFromWishlist($eanCodes: [String!]!) {
  removeFromWishlist(eanCodes: $eanCodes) {
    ...DefaultResponse
  }
}
    ${DefaultResponseFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class RemoveFromWishlistMutationService extends Apollo.Mutation<RemoveFromWishlistMutation, RemoveFromWishlistMutationVariables> {
    override document = RemoveFromWishlistDocument as Apollo.TypedDocumentNode<RemoveFromWishlistMutation, RemoveFromWishlistMutationVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GetWishlistDocument = gql`
    query getWishlist {
  me {
    wishListItems {
      product {
        ...SimpleProduct
      }
    }
  }
}
    ${SimpleProductFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class GetWishlistQueryService extends Apollo.Query<GetWishlistQuery, GetWishlistQueryVariables> {
    override document = GetWishlistDocument as Apollo.TypedDocumentNode<GetWishlistQuery, GetWishlistQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}
export const GetWishlistTotalDocument = gql`
    query getWishlistTotal {
  me {
    wishListItems {
      ...MinimalWishlistItem
    }
  }
}
    ${MinimalWishlistItemFragmentDoc}`

@Injectable({
    providedIn: 'root',
})
export class GetWishlistTotalQueryService extends Apollo.Query<GetWishlistTotalQuery, GetWishlistTotalQueryVariables> {
    override document = GetWishlistTotalDocument as Apollo.TypedDocumentNode<GetWishlistTotalQuery, GetWishlistTotalQueryVariables>
    
    constructor(apollo: Apollo.Apollo) {
        super(apollo)
    }
}