import { trigger } from '@angular/animations'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { fadeIn } from '@app-lib/animations.lib'
import { delay } from '@app-lib/common.lib'
import { AuthService } from '@app-services'
import { AnalyticsService } from '@app-domains/analytics/analytics.service'
import { firstValueFrom } from 'rxjs'

@Component({
    selector: 'app-auth-callback',
    templateUrl: './auth-callback.component.html',
    styleUrls: [ './auth-callback.component.scss' ],
    animations: [
        trigger('fadeIn', [ fadeIn(300) ]),
    ],
})
export class AuthCallbackComponent implements OnInit {

    public exchangeState: 'pending' | 'failure' | 'success' = 'pending'

    constructor(
        private readonly authService: AuthService,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private analytics: AnalyticsService,
    ) {
    }

    public ngOnInit(): void {
        void this.attemptLogin()
    }

    private async attemptLogin(): Promise<void> {
        const code = this.route.snapshot.queryParams.code

        if (typeof code !== 'string') {
            return this.abortAndAlertError()
        }

        try {
            await this.authService.exchangeAuthCode(code)
            this.exchangeState = 'success'
            const user = await firstValueFrom(this.authService.user$)
            if (user) {
                this.analytics.logLogin(user.id)
            }
            await delay(1000)

            await this.redirectToIntendedUrl()
        } catch (error: unknown) {
            this.exchangeState = 'failure'
        }
    }

    protected async abortAndAlertError(): Promise<void> {
        await this.router.navigate([ '/' ])
        window.alert('Something went wrong during authentication...')
    }

    protected async redirectToIntendedUrl(): Promise<void> {
        const redirectUrl = this.authService.originUrl
        this.authService.deleteOriginUrl()
        await this.router.navigateByUrl(redirectUrl, {
            replaceUrl: true,
        })
    }
}
