import { TranslateModule } from '@ngx-translate/core'

import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { DirectivesModule } from '@app-directives/directives.module'
import { AlgoliaModule } from '@app-domains/algolia/algolia.module'
import { UIModule } from '@app-domains/ui/ui.module'
import { SortByComponent } from './components/sort-by/sort-by.component'
import { PaginationComponent } from './components/pagination/pagination.component'
import { RefinementListComponent } from './components/refinement-list/refinement-list.component'
import { RefinementListContentsComponent } from './components/refinement-list/refinement-list-contents/refinement-list-contents.component'
import { ClearRefinementsComponent } from './components/clear-refinements/clear-refinements.component'
import { PriceRangeComponent } from './components/price-range/price-range.component'
import { AppFormsModule } from '@app-domains/forms/app-forms.module'
import { ProductOverviewHitsComponent } from './components/product-overview-hits/product-overview-hits.component'
import { ComponentsModule } from '@app-domains/components/components.module'
import { ContentBlocksModule } from '@app-domains/content-blocks/content-blocks.module'
import { EmptyStateModule } from '@app-domains/empty-state/empty-state.module'
import { ProductGridDomainModule } from '@app-domains/product-grid/product-grid-domain.module'
import { AlgoliaPaginationConnectorComponent } from './components/algolia-pagination-connector/algolia-pagination-connector.component'
import { CategoriesRefinementListContentsComponent } from './components/categories-refinement-list/categories-refinement-list-contents/categories-refinement-list-contents.component'
import { CategoriesRefinementListComponent } from './components/categories-refinement-list/categories-refinement-list.component'
import { ProductListComponent } from './components/product-list/product-list.component'
import { LoadingModule } from '@app-domains/loading/loading.module'
import { RefinementListBaseComponent } from '@app-domains/product-list/components/refinement-list/refinement-list-base/refinement-list-base.component'
import { PipesModule } from '@app-pipes/pipes.module'
import { ArrayChunkingPipe } from '@app-pipes/product-overview-chunking/array-chunking.pipe'
import { FilterCategoryCountsPipe } from './pipes/filter-category-counts/filter-category-counts.pipe'

@NgModule({
    declarations: [
        SortByComponent,
        PaginationComponent,
        RefinementListComponent,
        RefinementListBaseComponent,
        RefinementListContentsComponent, // Internal partial, don't export
        ClearRefinementsComponent,
        PriceRangeComponent,
        ProductOverviewHitsComponent,
        AlgoliaPaginationConnectorComponent,
        ArrayChunkingPipe,
        ProductListComponent,
        CategoriesRefinementListComponent,
        CategoriesRefinementListContentsComponent,
        FilterCategoryCountsPipe,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AlgoliaModule,
        UIModule,
        AppFormsModule,
        ContentBlocksModule,
        PipesModule,
        TranslateModule,
        EmptyStateModule,
        ProductGridDomainModule,
        DirectivesModule,
        ComponentsModule,
        LoadingModule,
    ],
    exports: [
        SortByComponent,
        PaginationComponent,
        AlgoliaModule,
        RefinementListComponent,
        RefinementListBaseComponent,
        ClearRefinementsComponent,
        PriceRangeComponent,
        ProductOverviewHitsComponent,
        AlgoliaPaginationConnectorComponent,
        ProductListComponent,
        ArrayChunkingPipe,
    ],
})
export class ProductListDomainModule { }
