<app-grid>
    <app-cell mobile="0:12">
        <section class="container" [class.grid-14]="gridSize === 14">
            <div class="head-text">
                <h3 class="subheading serif">
                    {{ subtitle }}
                </h3>
                <h1>
                    {{ title }}
                </h1>
            </div>
            <ng-container *ngIf="gridSize < 14; else gridSize14">
                <ng-container *ngIf="localizationService.currentLocale$ | async as locale">
                    <div class="grid"
                         [class.grid-12]="gridSize === 12"
                    >
                        <div class="highlight"
                             [routerLink]="'products/new' | translateRoute"
                        >
                            <app-responsive-image [image]="new"
                                                  [lazyLoad]="lazyLoad"
                            ></app-responsive-image>

                            <h2 class="highlight-name">
                                {{ 'navigation.new' | translate }}
                            </h2>
                        </div>

                        <div class="inner-grid">
                            <ng-container *ngFor="let category of categories | slice:0:gridSize / 2; let topIndex = index">
                                <ng-template *ngTemplateOutlet="categoryItem; context: { $implicit: {
                                    name: category.translations[locale].name,
                                    link: category.translations[locale].slug,
                                    src: category.productImage?.url,
                                    alt: category.productImage?.alt ?? category.translations[locale].name
                                    } }"
                                ></ng-template>
                            </ng-container>

                            <ng-container *ngFor="let item of lengthOfMissingCategoryItems">
                                <ng-template *ngTemplateOutlet="categoryItemEmpty"></ng-template>
                            </ng-container>
                        </div>
                    </div>

                    <div class="grid"
                         [class.grid-12]="gridSize === 12"
                         *ngIf="categories.length > (gridSize / 2)"
                    >
                        <div class="inner-grid">
                            <ng-container *ngFor="let category of categories | slice:gridSize / 2:gridSize; let topIndex = index">
                                <ng-template *ngTemplateOutlet="categoryItem; context: { $implicit: {
                                    name: category.translations[locale].name,
                                    link: category.translations[locale].slug,
                                    src: category.productImage?.url,
                                    alt: category.productImage?.alt ?? category.translations[locale].name
                                    } }"
                                ></ng-template>
                            </ng-container>

                            <ng-container *ngFor="let item of lengthOfMissingCategoryItems">
                                <ng-template *ngTemplateOutlet="categoryItemEmpty"></ng-template>
                            </ng-container>
                        </div>

                        <div class="highlight"
                             [routerLink]="'products/sale' | translateRoute"
                    >
                            <app-responsive-image [image]="sale"
                                                  [lazyLoad]="lazyLoad"
                            ></app-responsive-image>

                            <h2 class="highlight-name">
                            {{ 'navigation.sale' | translate }}
                        </h2>
                    </div>
                    </div>
                </ng-container>
            </ng-container>
        </section>
    </app-cell>
</app-grid>

<ng-template #gridSize14>
    <ng-container *ngIf="localizationService.currentLocale$ | async as locale">
        <div class="grid grid-14">
            <div class="inner-grid">
                <div class="highlight"
                     [routerLink]="'products/new' | translateRoute"
            >
                    <app-responsive-image [image]="new"
                                          [lazyLoad]="lazyLoad"
                    ></app-responsive-image>

                    <h2 class="highlight-name">
                    {{ 'navigation.new' | translate }}
                </h2>
            </div>

                <ng-container *ngFor="let category of categories | slice:0:(gridSize / 2); let topIndex = index">
                    <ng-template *ngTemplateOutlet="categoryItem; context: { $implicit: {
                        name: category.translations[locale].name,
                        link: category.translations[locale].slug,
                        src: category.productImage?.url,
                        alt: category.productImage?.alt ?? category.translations[locale].name
                        } }"
                    ></ng-template>
                </ng-container>
            </div>
        </div>

        <div class="grid grid-14">
            <div class="inner-grid">
                <ng-container *ngFor="let category of categories | slice:(gridSize / 2):gridSize; let topIndex = index">
                    <ng-template *ngTemplateOutlet="categoryItem; context: { $implicit: {
                        name: category.translations[locale].name,
                        link: category.translations[locale].slug,
                        src: category.productImage?.url,
                        alt: category.productImage?.alt ?? category.translations[locale].name
                        } }"
                    ></ng-template>
                </ng-container>

                <ng-container *ngFor="let item of lengthOfMissingCategoryItems">
                    <ng-template *ngTemplateOutlet="categoryItemEmpty"></ng-template>
                </ng-container>

                <div class="highlight"
                    [routerLink]="'products/sale' | translateRoute"
                >
                    <app-responsive-image [image]="sale"
                                          [lazyLoad]="lazyLoad"
                    ></app-responsive-image>

                    <h2 class="highlight-name">
                        {{ 'navigation.sale' | translate }}
                    </h2>
                </div>
            </div>
        </div>
    </ng-container>
</ng-template>

<ng-template #categoryItem let-categoryItemData>
    <div class="item"
        [routerLink]="categoryItemData.link ? ('/category/' + categoryItemData.link | translateRoute) : '' "
    >
        <img [src]="categoryItemData.src ? categoryItemData.src : 'assets/images/missing-image-icon.svg'"
             [alt]="categoryItemData.src ? ('common.image-of' | translate: { name: categoryItemData.alt }) : 'Missing image of ' + categoryItemData.name"
             [attr.loading]="lazyLoad ? 'lazy' : 'eager'"
             fetchpriority="auto"
        >

        <ng-container *ngIf="categoryItemData.name">
            <h3 class="item-name">{{ categoryItemData.name }}</h3>
        </ng-container>
   </div>
 </ng-template>

<ng-template #categoryItemEmpty>
    <div class="item item-empty"></div>
</ng-template>
