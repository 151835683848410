import {
    ChangeDetectorRef,
    Component,
    ElementRef, HostListener,
    Input, OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { SwiperService } from '@app-services'
import Swiper, { SwiperOptions } from 'swiper'
import { BreakpointsService } from '@app-domains/ui/services/breakpoints/breakpoints.service'
import { Subscription } from 'rxjs'
import { SwiperLib } from '@app-lib/swiper.lib'
import { ExposeEnums } from '../../../../helpers/expose-enums.component'

@Component({
    selector: 'app-highlighted-products',
    templateUrl: './highlighted-products.component.html',
    styleUrls: ['./highlighted-products.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom,
})
export class HighlightedProductsComponent extends ExposeEnums implements OnInit, OnDestroy {

    @ViewChild('pagination', { static: true })
    public pagination: ElementRef<HTMLDivElement>

    @Input()
    public highlightedProducts: Product[] = []

    public swiper: SwiperLib
    public swiperOptions: SwiperOptions
    private currentBreakpointSubscription: Subscription
    public currentBreakpoint: string

    constructor(
        private cdr: ChangeDetectorRef,
        public breakpointsService: BreakpointsService,
        private swiperService: SwiperService,
        public localization: LocalizationService,
    ) {
        super()
    }

    public ngOnInit(): void {
        this.swiperOptions = {
            slidesPerView: 'auto',
            spaceBetween: 16,
            loop: false,
            direction: 'horizontal',
            pagination: {
                type: 'progressbar',
                el: this.pagination.nativeElement,
            },
            on: {
                progress: () => {
                    if (this.swiper) {
                        this.swiper.onProgress()
                    }
                },
            },
        }

        this.currentBreakpointSubscription = this.breakpointsService.currentBreakpoint$.subscribe((result) => {
            this.currentBreakpoint = result
        })
    }

    public onSwiper(swiper: Swiper): void {
        this.swiper = this.swiperService.create(swiper)
        this.swiper.updateTotalIndex()
    }

    public onSlideChange(): void {
        if (this.swiper) {
            this.swiper.onSlideChange()
            this.cdr.detectChanges()
        }
    }

    public ngOnDestroy(): void {
        this.currentBreakpointSubscription.unsubscribe()
    }

    @HostListener('window:resize')
    onResize() {
        this.swiper.updateTotalIndex()
    }

}
