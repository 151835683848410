import { BrowserConfig as BugsnagBrowserConfig } from '@bugsnag/browser'
import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha'
import { TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler'
import { LocalizationModule } from '@aa-app-localization'

import { DialogModule } from '@angular/cdk/dialog'
import { provideHttpClient, withFetch } from '@angular/common/http'
import { APP_ID, APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule, Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { AuthModule } from './pages/auth/auth.module'
import { environment } from '@app-environments/environment'
import { Environment } from '@app-types/environment.types'

import { noop } from '@app-lib/common.lib'
import { BootService } from '@app-services'
import { GraphQLModule } from '@app-modules/graphql/graphql.module'
import { UIModule } from '@app-domains/ui/ui.module'
import { AppFormsModule } from '@app-domains/forms/app-forms.module'
import { LoadingModule } from '@app-domains/loading/loading.module'
import { WebpackTranslateLoader } from '@app-domains/localization/webpack-translate-loader/webpack-translate-loader'
import { NavigationModule } from '@app-domains/navigation/navigation.module'
import { AnalyticsModule } from '@app-domains/analytics/analytics.module'
import { AlgoliaModule } from '@app-domains/algolia/algolia.module'
import { DirectivesModule } from '@app-directives/directives.module'
import { PipesModule } from '@app-pipes/pipes.module'

{
    const bugsnagOptions: BugsnagBrowserConfig = {
        apiKey: '930e85ee47a5a63b0a2de52aee78b09d',
        releaseStage: environment.name,
        enabledReleaseStages: [
            Environment.Staging,
            Environment.Production,
        ],
    }

    if (environment.name === Environment.Development) {
        bugsnagOptions.logger = {
            debug: noop,
            info: noop,
            warn: noop,
            error: (...args: any[]) => console.log(...args),
        }
    }

    Bugsnag.start(bugsnagOptions)
}

export function errorHandlerFactory() {
    return new BugsnagErrorHandler()
}

export function appInitializerFactory(bootService: BootService): () => void {
    return () => bootService.bootApplication()
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AnalyticsModule.forRoot({
            trackingId: environment.google.gtm,
        }),
        LocalizationModule,
        AlgoliaModule,
        BrowserModule,
        GraphQLModule,
        TranslateModule.forRoot({
            extend: true,
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader,
            },
            compiler: {
                provide: TranslateCompiler,
                useClass: TranslateMessageFormatCompiler,
            },
        }),
        AppRoutingModule,
        BrowserAnimationsModule,
        UIModule,
        DirectivesModule,
        PipesModule,
        AppFormsModule,
        AuthModule,
        DialogModule,
        NavigationModule,
        RecaptchaV3Module,
        LoadingModule,
    ],
    providers: [
        // https://angular.io/guide/set-document-title
        Title,
        {
            provide: ErrorHandler,
            useFactory: errorHandlerFactory,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [BootService],
            multi: true,
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: environment.google.recaptcha,
        },
        {
            provide: APP_ID,
            useValue: 'serverApp',
        },
        provideHttpClient(withFetch()),
    ],
    bootstrap: [ AppComponent ],
})
export class AppModule {
}
