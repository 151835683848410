import { isNil } from 'ramda'
import { firstValueFrom, ReplaySubject } from 'rxjs'
import CryptoES from 'crypto-es'
import { add as addDuration } from 'date-fns'
import { CookieService } from 'ngx-cookie-service'
import { TranslateRouterService } from '@endeavour/ngx-translate-router'
import { LOCAL_STORAGE, WINDOW } from '@ng-web-apis/common'
import { TranslateService } from '@ngx-translate/core'
import Bugsnag from '@bugsnag/js'

import { Dialog } from '@angular/cdk/dialog'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { HttpClient } from '@angular/common/http'
import { Router } from '@angular/router'
import { Inject, Injectable, PLATFORM_ID } from '@angular/core'

import { ALGOLIA_INSIGHTS_CLIENT, InsightsData } from '@app-domains/algolia/algolia.module'
import { DialogComponent, DialogData } from '@app-domains/ui/components/dialog/dialog.component'
import { environment } from '@app-environments/environment'
import { MeQueryService, UserFragment } from '@app-graphql/schema'
import {
    AuthState,
    CookieAuthStorageService,
} from '@app-services'

interface AuthPayload {
    access_token: string
    refresh_token: string
    token_type: string
    expires_in: number
}

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private readonly userSubject = new ReplaySubject<UserFragment | null>(1)
    public readonly user$ = this.userSubject.asObservable()

    constructor(
        @Inject(DOCUMENT)
        private readonly document: Document,
        @Inject(WINDOW)
        private window: Window,
        @Inject(PLATFORM_ID)
        public platformId: string,
        private translateService: TranslateService,
        private httpClient: HttpClient,
        private readonly authStorageService: CookieAuthStorageService,
        private readonly meQueryService: MeQueryService,
        @Inject(LOCAL_STORAGE)
        private localStorage: Storage,
        private dialogService: Dialog,
        private translateRouterService: TranslateRouterService,
        private cookieService: CookieService,
        private router: Router,
        @Inject(ALGOLIA_INSIGHTS_CLIENT)
        private insights: InsightsData,
    ) {
    }

    public async initialize(): Promise<void> {
        if (! isPlatformBrowser(this.platformId)) {
            this.userSubject.next(null)
            return
        }
        this.authStorageService.initialize()
        const authState = this.authStorageService.getAuthState()

        if (authState) {
            await this.refreshUser()
        } else {
            this.userSubject.next(null)
        }
    }

    /**
     * Save this url to localStorage to redirect back to it after login.
     */
    set originUrl(url: string) {
        if (isPlatformBrowser(this.platformId)) {
            this.localStorage.setItem('originAuthUrl', url)
        }
    }

    get originUrl(): string {
        return isPlatformBrowser(this.platformId) ? this.localStorage.getItem('originAuthUrl') ?? '/' : '/'
    }

    public deleteOriginUrl(): void {
        if (isPlatformBrowser(this.platformId)) {
            this.localStorage.removeItem('originAuthUrl')
        }
    }

    public getLoginUrl(): string {
        const fullUrl = `${ environment.api.schema }://${ environment.api.hostname }`
        const state = AuthService.strRandom(40)
        const codeVerifier = AuthService.strRandom(128)

        this.localStorage.setItem('codeVerifier', codeVerifier)

        const codeVerifierHash = CryptoES.SHA256(codeVerifier).toString(CryptoES.enc.Base64)

        const codeChallenge = codeVerifierHash
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_')

        const params = new URLSearchParams({
            response_type: 'code',
            state,
            client_id: environment.api.clientId,
            scope: 'read_user_data write_user_data',
            code_challenge: codeChallenge,
            code_challenge_method: 'S256',
            redirect_uri: this.buildRedirectUri(),
            origin_url: this.document.location.href,

            // In addition to the required params for OAuth authentication, we'll pass the locale
            // so the login page can be translated.
            locale: this.translateService.currentLang,
        })

        return `${ fullUrl }${ environment.api.oauthLoginUrl }?${ params.toString() }`
    }

    public goToLoginPage(origin: string) {
        this.originUrl = origin

        this.window.location.href = this.getLoginUrl()
    }

    private buildRedirectUri(): string {
        const { location } = this.document
        return `${ location.origin }${ this.translateRouterService.translateRouteLink(environment.api.oauthCallbackUrl) }`
    }

    public async exchangeAuthCode(code: string): Promise<AuthState | null> {
        const fullUrl = `${ environment.api.schema }://${ environment.api.hostname }${ environment.api.oauthTokenUrl }`

        const response = await firstValueFrom(this.httpClient.post(fullUrl, {
            grant_type: 'authorization_code',
            client_id: environment.api.clientId,
            redirect_uri: this.buildRedirectUri(),
            code_verifier: this.localStorage.getItem('codeVerifier')!,
            code_challenge_method: 'S256',
            code,
        }))

        if (! this.isValidAuthPayload(response)) {
            const errorMessage = 'Invalid auth response'
            Bugsnag.notify(errorMessage)
            throw new Error(errorMessage)
        }

        this.authStorageService.submitAuthState({
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
            tokenType: response.token_type,
            expiresAt: this.expiresInToExpiresAt(response.expires_in),
        })

        this.localStorage.removeItem('codeVerifier')

        await this.refreshUser()

        return this.authStorageService.getAuthState()
    }

    public getAccessToken(): string | null {
        return this.localStorage.getItem('accessToken')
    }

    public deleteAccessToken(): void {
        this.localStorage.removeItem('accessToken')
    }

    public async logout(): Promise<void> {
        this.cookieService.delete(
            'IMPERSONATION',
            undefined,
            `.${ environment.url }`,
        )

        this.authStorageService.clearAuthState()

        this.userSubject.next(null)

        this.router.navigate(
            [this.translateRouterService.translateRouteLink('/')],
        ).then(() => {
            this.window.location.reload()
        })
    }

    /**
     * Tests if the given value is the shape of a full authentication response payload, i.e. if
     * it includes all auth information and tokens.
     */
    private isValidAuthPayload(payload: any): payload is AuthPayload {
        return typeof payload === 'object'
            && typeof payload?.access_token === 'string'
            && typeof payload?.expires_in === 'number'
            && typeof payload?.refresh_token === 'string'
            && typeof payload?.token_type === 'string'
    }

    /**
     * Derives an 'expires at' date instance from the given 'expires in' amount in seconds.
     */
    private expiresInToExpiresAt(expiresInSeconds: number): Date {
        return addDuration(new Date(), {
            seconds: expiresInSeconds,
        })
    }

    private static strRandom(length: number): string {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const charactersLength = characters.length

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }

        return result
    }

    public async refreshUser(): Promise<void> {
        try {
            const user = await this.fetchUser()

            if (user) {
                this.insights.client('setUserToken', `user-${ user.id }`)
            }
            this.userSubject.next(user)
        } catch {
            this.userSubject.next(null)
        }
    }

    public openLoginDialog(path?: string) {
        if (! isPlatformBrowser(this.platformId)) {
            return
        }

        const dialog = this.dialogService.open(DialogComponent, {
            data: {
                title: this.translateService.instant('auth.dealer-account-needed.title'),
                message: this.translateService.instant('auth.dealer-account-needed.message'),
                buttons: [
                    {
                        label: this.translateService.instant('common.login'),
                        layout: 'label-only',
                        theme: 'dark',
                        type: 'button',
                        clicked: () => {
                            this.goToLoginPage(path ?? window.location.pathname)
                            dialog.close()
                        },
                    },
                    {
                        label: this.translateService.instant('auth.dealer-account-needed.request-a-dealer-account'),
                        layout: 'label-only',
                        theme: 'line',
                        type: 'link',
                        link: this.translateRouterService.translateRouteLink('/register'),
                        clicked: () => {
                            dialog.close()
                        },
                    },
                ],
            } as DialogData,
        })
    }

    public async openNoDebtorDialog() {
        const user = await firstValueFrom(this.user$)

        if (isNil(user)) {
            return
        }

        const isEnhanced = user.isEnhanced

        let dialogContent: object

        if (isEnhanced) {
            dialogContent = {
                data: {
                    title: this.translateService.instant('auth.profile-in-review.title'),
                    message: this.translateService.instant('auth.profile-in-review.message'),
                    buttons: [
                        {
                            label: this.translateService.instant('auth.profile-in-review.discover-collection'),
                            layout: 'label-only',
                            theme: 'dark',
                            type: 'button',
                            clicked: () => {
                                this.router.navigate(
                                    [this.translateRouterService.translateRouteLink('/products')],
                                )
                                dialog.close()
                            },
                        },
                    ],
                } as DialogData,
            }
        } else {
            dialogContent = {
                data: {
                    title: this.translateService.instant('auth.finish-profile.title'),
                    message: this.translateService.instant('auth.finish-profile.message'),
                    buttons: [
                        {
                            label: this.translateService.instant('auth.finish-profile.complete-profile'),
                            layout: 'label-only',
                            theme: 'dark',
                            type: 'button',
                            clicked: () => {
                                this.router.navigate(
                                    [this.translateRouterService.translateRouteLink('/enhance-debtor')],
                                )
                                dialog.close()
                            },
                        },
                    ],
                } as DialogData,
            }
        }

        const dialog = this.dialogService.open(DialogComponent, dialogContent)
    }

    private async fetchUser(): Promise<UserFragment | null> {
        const response = await firstValueFrom(this.meQueryService.fetch(undefined, {
            fetchPolicy: 'no-cache',
        }))
        return response.data.me ?? null
    }
}
