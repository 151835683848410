<app-grid>
    <app-cell mobile="0:12" [laptop]="paragraphs.paragraphsLayout === ParagraphsLayoutEnum.None ? '3:9' : '0:12'">

        <div class="paragraphs"
            [class.single-column]="paragraphs.paragraphsLayout === ParagraphsLayoutEnum.None"
            [class.double-column]="paragraphs.paragraphsLayout === ParagraphsLayoutEnum.DoubleColumn"
            [class.triple-column]="paragraphs.paragraphsLayout === ParagraphsLayoutEnum.TripleColumn"
            [class.gray-background]="paragraphs.showGrayBack"
        >
            <ng-container *ngFor="let paragraph of paragraphs!.paragraphs!">
                <div class="paragraph-item">
                    <h3 *ngIf="paragraph.subtitle"
                        class="subheading serif"
                    >
                        {{ paragraph.subtitle }}
                    </h3>

                    <h2 class="big">
                       {{ paragraph.title }}
                    </h2>

                    <div class="paragraph"
                        [innerHTML]="paragraph.paragraph | safe:'html'"
                    ></div>

                    <ng-container *ngIf="paragraph.buttons">
                        <div class="buttons">
                            <div *ngFor="let button of paragraph.buttons">
                                <app-button
                                    *ngIf="button.link.link"
                                    [type]="button.link.link ? 'link' : 'button' "
                                    [cmsTheme]="button.type"
                                    [cmsIcon]="button.icon ? button.icon : undefined"
                                    [layout]="
                                        (button.icon && button.link.label === ' ') ? 'icon-only'
                                        : (button.icon && button.link.label) ? 'icon-label'
                                        : 'label-only'
                                    "
                                    [cmsTarget]="button.link.target"
                                    [href]="button.link.link"
                                >
                                    {{ button.link.label }}
                                </app-button>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </app-cell>
</app-grid>
