import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
    name: 'isOnSale',
})
export class IsOnSalePipe implements PipeTransform {

    transform(retailPrice: number, discountedPrice: number): boolean {

        if (! retailPrice || ! discountedPrice) {
            return false
        }

        if (retailPrice === discountedPrice) {
            return false
        }

        return discountedPrice < retailPrice
    }

}