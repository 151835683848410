<app-grid>
    <app-cell mobile="0:12" tablet="2:10">
        <div class="container">
            <div class="overlay"></div>
            <swiper [config]="swiperConfig" (swiper)="onSwiper($event)" (slideChange)="slideChange($event)">
                <ng-template *ngFor="let quote of quotes"
                             swiperSlide
                >
                    <h5 *ngIf="quote.name"
                        class="title subheading"
                    >
                        {{ 'testimonials.testimonials' | translate }}
                    </h5>

                    <div class="quote">
                        <h1 class="quote--text serif">
                            "{{ quote.text }}"
                        </h1>

                        <div *ngIf="quote.name || quote.title"
                             class="line"
                        ></div>

                        <div class="quote--name">
                            {{ quote.name }}
                        </div>

                        <div class="quote--title">
                            {{ quote.title }}
                        </div>
                    </div>
                </ng-template>
            </swiper>

            <ng-container *ngIf="quotes">
                <div class="navigation" *ngIf="quotes!.length > 1 || (quotes!.length === 1 && quotes[0].photo!)">
                    <div *ngFor="let person of quotes; let i = index"
                         class="navigation--person"
                         [class.active]="swiperIndex === i && quotes!.length > 1"
                         [class.no-pointer]="quotes!.length === 1"
                         [class.no-photo]="! person.photo"
                         (click)="goToSlide(i)"
                    >
                        <img *ngIf="person.photo"
                             [src]="person.photo.url"
                             [alt]="person.name"
                             loading="lazy"
                        />
                    </div>
                </div>
            </ng-container>
        </div>
    </app-cell>
</app-grid>
