import { DebtorFragment, DebtorRightsFragment } from '@app-graphql/schema'
import { compose, equals, not } from 'ramda'

export function generateDebtorExclusivityFilter(debtor?: DebtorFragment | null): string {
    if (! debtor) {
        return ''
    }
    if (! debtor.debtorCode || debtor.debtorCode === '') {
        return ''
    }

    const filters = []

    if (debtor.debtorRights.allowedBrands) {
        filters.push(
            buildAllowedBrandsFilter(debtor.debtorRights.allowedBrands),
        )
    }

    if (debtor.debtorRights.allowedExclusives) {
        filters.push(
            buildAllowedExclusivesFilter(debtor.debtorRights.allowedExclusives),
        )
    }

    if (debtor.debtorRights.allowedProductGroups) {
        filters.push(
            buildAllowedProductGroupsFilter(debtor.debtorRights.allowedProductGroups),
        )
    }

    if (debtor.debtorRights.allowedModules) {
        filters.push(
            buildAllowedModulesFilter(debtor.debtorRights.allowedModules),
        )
    }

    if (debtor.debtorRights.allowedCountry) {
        filters.push(
            buildAllowedCountryFilter(debtor.debtorRights.allowedCountry),
        )
    }

    const filterString = filters.filter(compose(not, equals(''))).join(' AND ')
    return filterString.length
        ? `AND ${ filterString }`
        : ''
}

function buildAllowedBrandsFilter(allowedBrands: DebtorRightsFragment['allowedBrands']): string {
    return allowedBrands.length
        ? `(${ allowedBrands.map((allowedBrand) => `filters.brands.code:${ parseInt(allowedBrand.code) }`).join(' OR' + ' ') })`
        : ''
}

function buildAllowedExclusivesFilter(allowedExclusives: DebtorRightsFragment['allowedExclusives']): string {
    return allowedExclusives.length
        ? `(${ allowedExclusives.map(
            (allowedExclusive) => `filters.exclusivities.code:${ allowedExclusive.code }`,
        ).join(' OR ') })`
        : ''
}

function buildAllowedProductGroupsFilter(allowedProductGroups: DebtorRightsFragment['allowedProductGroups']): string {
    return allowedProductGroups.length
        ? `(${ allowedProductGroups.map(
            (allowedProductGroup) => `filters.groups.code:${ parseInt(allowedProductGroup.code) }`,
        ).join(' OR ') })`
        : ''
}

function buildAllowedModulesFilter(allowedModules: DebtorRightsFragment['allowedModules']): string {
    const filters = []

    if (allowedModules.dontShowNewProducts) {
        filters.push('filters.isNew:false')
    }

    if (allowedModules.showNewFromDate) {
        filters.push(`filters.onlineActivationDate < ${ allowedModules.showNewFromDate }`)
    }

    return filters.length
        ? `(${ filters.join(' AND ') })`
        : ''
}

function buildAllowedCountryFilter(allowedCountry: DebtorRightsFragment['allowedCountry']): string {
    return `NOT filters.notAllowedInCountries:${ allowedCountry.toLowerCase().trim() }`
}
