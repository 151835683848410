import { Component, Input, OnInit } from '@angular/core'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { ContentCategoryFragment, ImageComponentFragment } from '@app-graphql/schema'

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: [ './product-categories.component.scss' ],
})
export class ProductCategoriesComponent implements OnInit {

    @Input()
    public categories: ContentCategoryFragment[]

    @Input()
    public sale: ImageComponentFragment

    @Input()
    public new: ImageComponentFragment

    @Input()
    public title: string

    @Input()
    public subtitle: string | undefined | null

    @Input()
    public lazyLoad: boolean = true

    public gridSize: number

    private possibleCategorySizes = [ 8, 12, 14 ]

    public lengthOfMissingCategoryItems: number[]

    constructor(
        public localizationService: LocalizationService,
    ) {}

    public ngOnInit(): void {
        this.gridSize = this.getClosestGridSize()
        this.processData()
    }

    private getClosestGridSize(): number {
        return this.possibleCategorySizes.reduce((prev, curr) =>
            Math.abs(curr - this.categories.length) <
                Math.abs(prev - this.categories.length)
                ? curr : prev,
        )
    }

    private processData(): void {
        const differenceInGridItems = this.gridSize - this.categories.length

        if (differenceInGridItems < 0) {
            this.categories = this.categories.slice(0, differenceInGridItems)
            return
        }

        this.lengthOfMissingCategoryItems = Array(differenceInGridItems)
    }
}
