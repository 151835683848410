import { Inject, Injectable, OnDestroy } from '@angular/core'
import {
    CompleteDebtorFragment,
    CompleteDebtorQueryService,
    DebtorFragment,
    DebtorQueryService,
} from '@app-graphql/schema'
import { AuthService } from '@app-services'
import { LOCAL_STORAGE } from '@ng-web-apis/common'
import { BehaviorSubject, firstValueFrom, map, merge, Observable, of, Subject, Subscription, switchMap } from 'rxjs'
import { tap } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class DebtorService implements OnDestroy {
    private debtorSubject = new Subject<DebtorFragment | undefined>()

    public debtor$: Observable<DebtorFragment | undefined> = merge(
        this.debtorSubject.asObservable(),
        this.authService.user$.pipe(
            map((user) => user ?? undefined),
            switchMap((user) => {
                if (! user || ! user.debtorCode) {
                    this.setCountry(
                        Intl.DateTimeFormat().resolvedOptions().timeZone.toLowerCase()
                            .includes('amsterdam') ? 'NL' : 'ISE',
                    )
                    return of(undefined)
                }
                return this.getDebtor()
            }),
        ),
    )

    public isNL: BehaviorSubject<boolean>

    private userSub: Subscription

    constructor(
        private debtorQueryService: DebtorQueryService,
        private completeDebtorQueryService: CompleteDebtorQueryService,
        private authService: AuthService,
        @Inject(LOCAL_STORAGE)
        private storage: Storage,
    ) {
        const country = this.storage.getItem('country')
        this.isNL = new BehaviorSubject<boolean>(country === 'NL')
        this.authService.user$.subscribe(async () => await firstValueFrom(this.debtor$))
    }

    public ngOnDestroy(): void {
        if (this.userSub) {
            this.userSub.unsubscribe()
        }
    }

    public getDebtor(): Observable<DebtorFragment | undefined> {
        return this.debtorQueryService.fetch().pipe(
            map((r) => r.data?.debtor),
            tap((debtor) =>
                this.setCountry(debtor?.debtorRights.allowedCountry.toLowerCase() === 'nl' ? 'NL' : 'ISE')),
            tap((debtor) => this.debtorSubject.next(debtor)),
        )
    }

    public getCompleteDebtor(): Observable<CompleteDebtorFragment> {
        return this.completeDebtorQueryService.fetch().pipe(
            map((r) => r.data.debtor),
        )
    }

    public setCountry(country: 'NL' | 'ISE'): void {
        this.storage.setItem('country', country)
        this.isNL.next(country === 'NL')
    }
}
