<div class="card"
     [ngClass]="className"
     [routerLink]="'/products/' + product.slug | translateRoute"
     (click)="handleClick(product)"
>
    <div class="card-content">
        <div class="card-header">
            <div *ngIf="product.newProduct"
                 class="new-label"
            >
                <label class="white-bold">
                    {{ 'navigation.new' | translate }}
                </label>
            </div>
        </div>

        <img [src]="product.primaryImage ? (product.primaryImage | imageSize : 600)
                    : product.ambianceImage ? (product.ambianceImage | imageSize : 600)
                    : 'assets/images/missing-image-icon.svg'
                    "
             [alt]="product.primaryImage ? ('common.image-of' | translate: { name: product.name }) : 'Missing image of ' + product.name"
             loading="lazy"
             fetchpriority="auto"
        >

        <div class="text-container">
            <div class="ellipsis">
                <label class="bold text-margin stretch">
                    {{ product.brand }}
                </label>
                <label class="dark-gray stretch first-letter-uppercase">
                    {{ product.name | truncateText: 38 }}
                </label>
            </div>

            <app-product-availability [statusTranslation]="product.status"
                                      [deliveryTimeInWeeks]="product.deliveryTimeInWeeks"
            ></app-product-availability>
        </div>
    </div>
</div>
