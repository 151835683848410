import { Subscription } from 'rxjs'
import { tap } from 'rxjs/operators'
import { isNil } from 'ramda'
import { RendererOptions } from 'instantsearch.js'
import connectAutocomplete, {
    AutocompleteWidgetDescription as WDescription,
    AutocompleteConnectorParams as CParams,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'

import { Component, OnInit, Inject, forwardRef, Output, EventEmitter, OnDestroy } from '@angular/core'

import { AlgoliaInstantsearchComponent } from '@app-domains/algolia/components/algolia-instantsearch.component'
import { AbstractInstantsearchWidgetComponent } from '@app-domains/algolia/abstract-instantsearch-widget.component'
import { SearchService } from '@app-services'

@Component({
    selector: 'app-search-hits-other',
    templateUrl: './search-hits-other.component.html',
    styleUrls: [ '../search/search.component.scss' ],
})
export class SearchHitsOtherComponent extends AbstractInstantsearchWidgetComponent<
WDescription,
CParams
> implements OnInit, OnDestroy {
    public override readonly widgetName = 'SearchHitsProductsComponent'

    @Output()
    public resultClicked = new EventEmitter<void>()

    @Output()
    public loading = new EventEmitter<boolean>()

    public override updateState = (
        state: WDescription['renderState'] & RendererOptions<CParams>,
    ) => {
        this.state = state
        this.loading.emit(false)
    }

    private subscription: Subscription

    constructor(
        @Inject(forwardRef(() => AlgoliaInstantsearchComponent))
        public instantSearchInstance: AlgoliaInstantsearchComponent,
        private searchService: SearchService,
    ) {
        super()
    }

    public override ngOnInit(): void {
        this.createWidget(connectAutocomplete, {

        })
        super.ngOnInit()

        this.subscription = this.searchService.query$.pipe(
            tap((query) => {
                if (isNil(query)) {
                    return
                }

                if (query.length >= 3) {
                    setTimeout(() => {
                        this.state?.refine(query)
                        this.loading.emit(true)
                    }, 300)
                } else if (this.state) {
                    this.state.indices.forEach((index) => {
                        index.hits = []
                    })
                }
            }),
        ).subscribe()
    }

    public override ngOnDestroy(): void {
        super.ngOnDestroy()
        this.subscription.unsubscribe()
    }
}

