import { Component, OnDestroy, OnInit } from '@angular/core'
import { LocalesEnum, PositionedPageEnum } from '@app-graphql/schema'
import { BrandsService } from '@app-services'
import { ThemesService } from '@app-services'
import { Observable, Subscription } from 'rxjs'
import { CategoriesService } from '@app-services'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { PageStructureDisplayService } from '@app-services'
import { LocalizedRouting } from '@app-domains/navigation/types/navigation.types'
import { AuthService } from '@app-services'
import { BootService } from '@app-services'
import { ScrollService } from '@app-services'

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.scss'],
})
export class SiteFooterComponent implements OnInit, OnDestroy {
    public categories$: Observable<LocalizedRouting[]>
    public themes$: Observable<LocalizedRouting[]>
    public brands$: Observable<LocalizedRouting[]>
    public readonly PositionedPageEnum = PositionedPageEnum
    public readonly LocalesEnum = LocalesEnum
    private localeSub: Subscription

    constructor(
        private readonly categoriesService: CategoriesService,
        public readonly localization: LocalizationService,
        public readonly pageStructureDisplayService: PageStructureDisplayService,
        public readonly authService: AuthService,
        private readonly brandsService: BrandsService,
        private readonly themeService: ThemesService,
        private readonly bootService: BootService,
        public readonly scrollService: ScrollService,
    ) {
    }

    public year: number = new Date().getFullYear()

    public ngOnInit(): void {
        // Todo: this is really bad practice and could potentially break many things!
        //       the bootService.bootApplication() method should be called exactly once
        //       per application lifetime, hooked to an app initializer token.
        this.bootService.bootApplication().then(() => {
            this.localeSub = this.localization.currentLocale$.subscribe(() => {
                this.categories$ = this.categoriesService.getTranslatedCategories()
                this.themes$ = this.themeService.getTranslatedThemes()
                this.brands$ = this.brandsService.getTranslatedBrands()
            })
        })
    }

    public ngOnDestroy(): void {
        if (this.localeSub) {
            this.localeSub.unsubscribe()
        }
    }
}
