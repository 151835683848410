import { Injectable } from '@angular/core'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import {
    HighlightedProductFragment,
    LocalesEnum,
    PublishedEnum,
    SimpleThemeFragment,
    ThemeFragment,
    ThemeQueryService,
    ThemesQueryService,
} from '@app-graphql/schema'
import { firstValueFrom, from, Observable } from 'rxjs'
import { filter, map, withLatestFrom } from 'rxjs/operators'
import { locales, Localization } from '@aa-app-localization'
import { LocalizedRouting } from '@app-domains/navigation/types/navigation.types'
import { genericTranslate } from '@app-lib/category.lib'

@Injectable({
    providedIn: 'root',
})
export class ThemesService {
    private themes: readonly SimpleThemeFragment[]

    constructor(
        public readonly localization: LocalizationService,
        public readonly themesQueryService: ThemesQueryService,
        public readonly themeQueryService: ThemeQueryService,
    ) {
    }

    public all(): readonly SimpleThemeFragment[] {
        return this.themes
    }

    public async initialize(): Promise<void> {
        this.themes = await this.fetchThemes()

        type T = Record<Localization.Locale, Record<string, string>>

        const xs = this.themes.reduce<T>((acc, cat) => {
            for (const locale of this.localization.langList) {
                acc[locale][`theme-${ cat.id }`] = cat.translations[locale]!.slug
            }
            return acc
        }, {
            en: {},
            nl: {},
            de: {},
            fr: {},
        })

        for (const locale of locales) {
            this.localization.patchTranslations(locale, {
                ROUTES: xs[locale],
            })
        }
    }

    public findBySlug(slug: string): SimpleThemeFragment | null {
        const themes = this.all()
        const locale = this.localization.getCurrentLocale()
        return themes.find((x: SimpleThemeFragment) => slug === x.translations[locale]!.slug) ?? null
    }

    private async fetchThemes(): Promise<SimpleThemeFragment[]> {
        const themes = await firstValueFrom(this.themesQueryService
            .fetch({
                locale: this.localization.getCurrentLocale() as LocalesEnum,
                published: PublishedEnum.IsPublished,
            })
            .pipe(
                map(({ data }) => data?.themes ?? []),
            ))

        return themes?.length ? themes : []
    }

    public fetchTheme(slug: string, locale: LocalesEnum): Observable<ThemeFragment> {
        return this.themeQueryService.fetch({
            slug,
            locale,
        }, { fetchPolicy: 'no-cache' }).pipe(
            map(({ data }) => data.theme),
            filter((theme): theme is ThemeFragment => !! theme),
        )
    }

    public getTranslatedThemes(): Observable<LocalizedRouting[]> {
        return from(this.fetchThemes()).pipe(
            withLatestFrom(this.localization.currentLocale$),
            map(([themes, lang]) => themes.map(
                (theme: SimpleThemeFragment) => this.translateThemes(theme, lang)),
            ),
        )
    }

    private translateThemes(theme: SimpleThemeFragment, lang: Localization.Locale): LocalizedRouting {
        return {
            id: theme.id.toString(),
            name: theme.translations[lang]!.title,
            slug: theme.translations[lang]!.slug,
            highlightedProducts: theme.highlightedProducts
                .filter((p): p is HighlightedProductFragment => !! p)
                .map((product) =>
                    genericTranslate(product, lang),
                ),
        }
    }
}
