import { filter, map } from 'rxjs/operators'
import { combineLatest, combineLatestWith, mapTo, Observable, scan, startWith, Subject } from 'rxjs'
import { equals, mergeAll } from 'ramda'
import { SearchClient } from 'algoliasearch'
import { PlainSearchParameters } from 'algoliasearch-helper'
import { InstantSearchOptions as InstantSearchConfig } from 'instantsearch.js/es/types'

import { ActivatedRoute } from '@angular/router'
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core'

import { ALGOLIA_SEARCH_CLIENT } from '@app-domains/algolia/algolia.module'
import { Product } from '@app-domains/content-blocks/components/product-card/product-card.types'
import { LocalizationService } from '@app-domains/localization/service/localization.service'
import { ProductHistoryService } from '@app-domains/product/services/product-history/product-history.service'
import { getIndexName } from '@app-lib/algolia.lib'

export enum OtherProductsCategories {
    RELATED = 'related',
    RECENT = 'recent',
    SAME_SERIES = 'same_series',
    SAME_BRAND = 'same_brand',
}

@Component({
    selector: 'app-other-products',
    templateUrl: './other-products.component.html',
    styleUrls: ['./other-products.component.scss'],
})
export class OtherProductsComponent implements OnInit {
    @Output()
    public loadMore = new EventEmitter<void>()

    @Output()
    public loadCategory = new EventEmitter<OtherProductsCategories>()

    @Input()
    public otherProducts: Product[] = []

    @Input()
    public product: Product

    public Categories = OtherProductsCategories

    public instantSearchConfig$: Observable<InstantSearchConfig>

    public relatedProductsHit$: Observable<any>

    public lastViewedProductsConfig$: Observable<PlainSearchParameters>

    public loadMoreSubject = new Subject<OtherProductsCategories>()
    public configs$: Observable<Record<string, PlainSearchParameters>>
    public OtherProductsCategories = OtherProductsCategories
    public sameSeriesHit$: Observable<any>
    public sameBrandHit$: Observable<any>

    constructor(
        @Inject(ALGOLIA_SEARCH_CLIENT)
        private readonly searchClient: SearchClient,
        private route: ActivatedRoute,
        public productHistoryService: ProductHistoryService,
        private localization: LocalizationService,
    ) {
    }

    public ngOnInit() {
        this.instantSearchConfig$ = this.localization.currentLocale$.pipe(
            map((locale) => ({
                indexName: getIndexName({ locale, sorting: null }),
                searchClient: this.searchClient,
            })),
        )

        this.configs$ = combineLatest(
            Object.values(OtherProductsCategories).map((c) => this.createPagination(c).pipe(
                map((v) => ({
                    [c]: {
                        ...v,
                        enablePersonalization: true,
                        clickAnalytics: true,
                    },
                })),
            )),
        ).pipe(
            map(mergeAll),
        )

        this.relatedProductsHit$ = this.route.params.pipe(
            map(() => ({
                objectID: this.product.ean,
                range: this.product.range,
                group: this.product.group,
                brand: this.product.brand,
            })),
        )

        this.sameSeriesHit$ = this.route.params.pipe(
            map(() => ({
                objectID: this.product.ean,
                range: this.product.range,
            })),
        )

        this.sameBrandHit$ = this.route.params.pipe(
            map(() => ({
                objectID: this.product.ean,
                brand: this.product.brand,
            })),
        )

        this.lastViewedProductsConfig$ = this.productHistoryService.history$.pipe(
            combineLatestWith(this.configs$),
            map(([objectIDs, configs]) => ({
                hitsPerPage: configs[OtherProductsCategories.RECENT].hitsPerPage ?? 6,
                filters: `type:product AND (${this.buildLastViewedFilter(
                    objectIDs,
                    configs[OtherProductsCategories.RECENT].hitsPerPage ?? 6,
                )})`,
            })),
        )
    }

    public loadMoreProducts(type: OtherProductsCategories): void {
        this.loadMoreSubject.next(type)
    }

    private buildLastViewedFilter(objectIDs: Array<string>, hits: number): string {
        return objectIDs.slice(0, hits).map((ean) => `slug:"${ean}"`)
            .join(' OR ')
    }

    private createPagination(type: OtherProductsCategories): Observable<PlainSearchParameters> {
        return this.loadMoreSubject.pipe(
            startWith(type),
            filter(equals(type)),
            mapTo(6),
            scan((acc, val) => acc + val, 0),
            map((hitsPerPage) => ({
                hitsPerPage,
                filters: 'type:"product"',
            })),
        )
    }
}
