import { BehaviorSubject, lastValueFrom, of } from 'rxjs'
import { FormControl, FormGroup, Validators } from '@angular/forms'

import { ShoppingCartFragment, ShoppingCartLineFragment } from '@app-graphql/schema'
import { OrderHeaderStep, ShipmentSegment } from '@app-domains/checkout/types/checkout.types'
import { CountriesEnum } from '@app-types/common.types'
import { CheckoutService } from '@app-services'
import { ShoppingCartNullState } from '../../dummy-data'

export const CheckoutServiceMock: Partial<CheckoutService> = {
    isLoading$: new BehaviorSubject(false),

    orderHeaderSteps: new BehaviorSubject<OrderHeaderStep[]>([]),

    orderMode$: new BehaviorSubject<'normal' | 'dropshipment'>('normal'),

    selectedItemsInShoppingCart$: new BehaviorSubject<ShoppingCartLineFragment[]>([]),

    itemsMinimalQuantityNotReached$: new BehaviorSubject<ShoppingCartLineFragment[]>([]),

    shoppingCart$: new BehaviorSubject<ShoppingCartFragment>(ShoppingCartNullState),

    shipments$: new BehaviorSubject<Array<ShipmentSegment[]>>([]),

    deliveryFormGroup: new FormGroup({
        name: new FormControl('Test company', [
            Validators.required,
        ]),
        postalCode: new FormControl('1999 ZZ', [
            Validators.required,
        ]),
        houseNumber: new FormControl('8', [
            Validators.required,
        ]),
        streetName: new FormControl('Phoenixstraat', [
            Validators.required,
        ]),
        city: new FormControl('Haarlem', [
            Validators.required,
        ]),
        country: new FormControl<CountriesEnum>(CountriesEnum.nl, {
            nonNullable: true,
            validators: [
                Validators.required,
            ],
        }),
        phoneNumber: new FormControl('06 12345678', [
            Validators.required,
        ]),
        email: new FormControl('john.doe@endeavour.nl', [
            Validators.required,
            Validators.email,
        ]),
    }),

    referenceFormGroup: new FormGroup({
        reference: new FormControl('', [
            Validators.required,
        ]),
        description: new FormControl('', [
            Validators.required,
        ]),
        addToAssortment: new FormControl(false),
    }),

    overruleShoppingCart: () => {
        return true
    },

    deleteFromShoppingCart: () => {
        return of()
    },

    addToShoppingCart: () => {
        return lastValueFrom(of())
    },

    getShoppingCartTotalItems: () => {
        return lastValueFrom(of())
    },

    getShoppingCart: () => {
        return of()
    },

    updateShoppingCart: () => {
        return of()
    },

    createDropshipmentOrderLines: () => {
        return []
    },

    createShipmentsOrderLines: () => {
        return []
    },

    createShipmentsFromShoppingCart: () => {
        return true
    },

    placeDropshipmentOrder: () => {
        return lastValueFrom(of())
    },

    placeNormalOrder: () => {
        return lastValueFrom(of())
    },

    setOrderHeaderStepActiveState: () => {
        // @ts-ignore
        jest.fn()
    },

    setOrderHeaderStepDoneState: () => {
        return true
    },

    checkMinimalOrderQuantity: () => {
        return true
    },

    getLastDropshipmentDate: () => {
        return ''
    },
}
